import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClipboardList, faProjectDiagram} from '@fortawesome/free-solid-svg-icons';
import LayoutMainContentTabMenu from '../components/global/LayoutMainContentTabMenu';
import CreateProjectTypeForm from '../components/projectManagement/CreateProjectTypeForm';
import ViewButtons from "../components/global/ViewButtons";
import {
    buttonAddNewCustomerType,
    buttonAddNewElementFieldType,
    buttonAddNewElementType,
    buttonAddNewInspection, buttonAddNewInspectionTaskType,
    buttonAddNewProjectType,
    makeButtonItem
} from "../components/global/viewButtonSelection";

type Props = {
    project: any,
    setProject: (e: any) => void,
}

export const TypeManagement = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const menuItems = [
        {

            /* [Projekttypen] */
            id: 'projectTypes',
            label: t('project_types'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faProjectDiagram}/>,
            component: <CreateProjectTypeForm viewType="projectTypes" showEdit={showEditDialog}
                                              setShowEdit={setShowEditDialog}/>,
            projectRequired: false,
            buttons: (
                <ViewButtons title={''} items={[
                    makeButtonItem(buttonAddNewProjectType, t, () => {
                        setShowEditDialog(true);
                    }, true, false),
                ]}/>
            ),
            visible: props.project === null,
        },
        {

            /* [Elementtypen] */
            id: 'elementTypes',
            label: t('element_types'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faProjectDiagram}/>,
            component: <CreateProjectTypeForm viewType="elementTypes" showEdit={showEditDialog}
                                              setShowEdit={setShowEditDialog}/>,
            projectRequired: false,
            buttons: (
                <ViewButtons title={''} items={[
                    makeButtonItem(buttonAddNewElementType, t, () => {
                        setShowEditDialog(true);
                    }, true, false),
                ]}/>
            ),
            visible: props.project === null,
        },
        {

            /* [Elemenetfeldtypen] */
            id: 'elementFieldTypes',
            label: t('element_field_types'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faProjectDiagram}/>,
            component: <CreateProjectTypeForm viewType="elementFieldTypes" showEdit={showEditDialog}
                                              setShowEdit={setShowEditDialog}/>,
            projectRequired: false,
            buttons: (
                <ViewButtons title={''} items={[
                    makeButtonItem(buttonAddNewElementFieldType, t, () => {
                        setShowEditDialog(true);
                    }, true, false),
                ]}/>
            ),
            visible: props.project === null,
        },
        {

            /* [Benutzerdefiniertetypen] */
            id: 'customerTypes',
            label: t('customerTypes'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faProjectDiagram}/>,
            component: <CreateProjectTypeForm viewType="customerTypes" showEdit={showEditDialog}
                                              setShowEdit={setShowEditDialog}/>,
            projectRequired: false,
            buttons: (
                <ViewButtons title={''} items={[
                    makeButtonItem(buttonAddNewCustomerType, t, () => {
                        setShowEditDialog(true);
                    }, true, false),
                ]}/>
            ),
            visible: props.project === null,
        },
        {

            /* [Prüfaufgaben] */
            id: 'inspectionTaskTypes',
            label: t('inspection_task_types'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faClipboardList}/>,
            component: <CreateProjectTypeForm viewType="inspectionTaskTypes" showEdit={showEditDialog}
                                              setShowEdit={setShowEditDialog}/>,
            projectRequired: false,
            buttons: (
                <ViewButtons title={''} items={[
                    makeButtonItem(buttonAddNewInspectionTaskType, t, () => {
                        setShowEditDialog(true);
                    }, true, false),
                ]}/>
            ),
            visible: props.project === null,
        },
        /*{

            /!* [Prüfarten] *!/
            id: 'inspectionTypes',
            label: t('inspection_types'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faClipboardList}/>,
            component: <CreateProjectTypeForm project={null} viewType="inspectionTypes"/>,
            projectRequired: false,
            visible: props.project === null,
        },*/
    ];

    return (
        <>
            <LayoutMainContentTabMenu items={menuItems}/>
        </>)
        ;
};
