import settings from '../config/settings';
import geoSettings from "../config/geoSettings";
import {TileWMS} from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import OpenLayersParser from 'geostyler-openlayers-parser';
import React from 'react';
import keycloakfetch from './keycloakfetch';
import VectorLayer from "ol/layer/Vector";

export const getUrlBasedByLayer = (workspace, layerKind, mapDataWorkBench) => {
    let url = '';
    switch (layerKind) {
        case 'rasterData':
            url = geoSettings.geoserverRestPath + 'workspaces/' + workspace + '/wmslayers.json';
            break;
        case 'coverageData':
            url = geoSettings.geoserverRestPath + 'workspaces/' + workspace + '/coverages.json';
            break;
        default:
            url = geoSettings.geoserverRestPath + 'workspaces/' + mapDataWorkBench + '/featuretypes.json';
            break;
    }
    return url;
};

export const setPermaLayerVisible = (layerVisibility, isPermalink, permaArray, layerId) => {
    if (isPermalink) {
        if (permaArray.includes(layerId)) {
            return true;
        }
        return false;
    }
    return layerVisibility;
};


export const callBaseLayerMap = (workspace) => {
    let url = getUrlBasedByLayer(workspace, 'rasterData', null);
    const requestOptions = {
        method: 'GET',
        headers: geoSettings.geoserverAuthentification,
    };

    return new Promise((resolve, reject) => {
        fetch(url, requestOptions)
            .then(async res => {
                console.log('result wms: ', res);
                if (res.ok) {
                    return res.json();
                } else {
                    let errorMessage = '';
                    const reader = res.body.getReader();
                    // read() returns a promise that resolves when a value has been received
                    await reader.read().then(function pump({done, value}) {
                        if (done) {
                            // Do something with last chunk of data then exit reader
                            return;
                        }
                        // Otherwise do something here to process current chunk
                        // Uint8Array in Text konvertieren
                        errorMessage = new TextDecoder('utf-8').decode(value);

                        // Read some more, and call this function again
                        return reader.read().then(pump);
                    });
                    throw new Error(errorMessage);
                }
            })
            .then(
                (result) => {
                    resolve({
                        success: true,
                        data: result
                    });
                },
                (error) => {
                    reject({
                        success: false,
                        info: 'GET from Geoserver failed: ' + error
                    });
                });
    });

    // return new Promise((resolve, reject) => {
    //     fetch(url, requestOptions)
    //         .then(res => res.json())
    //         .then(
    //             (result) => {
    //                 resolve({
    //                     success: true,
    //                     data: result
    //                 });
    //             },
    //             (error) => {
    //                 console.log(error)
    //                 reject({
    //                     success: false,
    //                     info: 'GET failed: ' + error
    //                 });
    //             });
    // });
};

export const parseStyle = (styleToParse, parseObject) => {
    const parser = new OpenLayersParser();
    parser
        .writeStyle(styleToParse)
        .then((olStyle) => parseObject.setStyle(olStyle.output))
        .catch((error) => console.log(error));
};

export const getWmsLayer = (layerDesignation, workspace, visible, type, baseLayer, layerId, styleId, styleId4Raster, layerIndex) => {
    let newId = ((styleId !== null) && (typeof styleId !== 'undefined')) ? styleId : layerId;
    let lowerId = newId.toLowerCase();
    let baseUrl = geoSettings.geoserverPath + workspace + '/wms';
    // console.log('wmslayerdata',newId,styleId4Raster, styleId)

    //styleId=(styleId==='dbnetz_400_3501_tbw_sfs1733km1000_pt')?'DBNETZ_400_3501_tbw_sfs1733km1000_pt':styleId;

    return new TileLayer({
        name: layerDesignation,
        id: lowerId,
        baseLayer: baseLayer,
        visible: visible,
        type: type,
        zIndex: layerIndex,
        source: new TileWMS({
            url: baseUrl,
            params: {
                'LAYERS': workspace + ':' + layerDesignation,
                'VERSION': '1.3.0',
                'FORMAT': 'image/png',
                'STYLES': styleId4Raster == null ? null : styleId,
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0,
            opacity: 1,

        })
    });
};


export const generateImageForLegend = (geoserverWorkspace, mainLayerId, legendArray, styleIdUnformated, styleId, layerId, externLegendPic, baseWmsWorkspace) => {
    let url = geoSettings.geoserverPath + geoserverWorkspace + '/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&TRANSPARENT=true&LAYER=' + geoserverWorkspace + ':' + mainLayerId + '&LEGEND_OPTIONS=forceLabels:on';

    if (styleId !== layerId && styleId !== null) {
        url = url + '&STYLE=' + baseWmsWorkspace + ':' + styleIdUnformated;
    }

    if (legendArray && legendArray.url) {

        url = legendArray.url;
        if (legendArray.extern === 'large') {
            return (alert('Die Legende ist zu groß für die Darstellung. Bitte Admin kontaktieren.'));
            //return '<a href="javascript:Ext.create(\'toolbox.base.components.LegendWindow\', {title:\'' + mainLayerId + '\', url:\'' + url + '\'});">' + translate('fieldLabel.legend') + '</a>';
        }
    }
    if (legendArray && !legendArray.url && legendArray.extern !== null) {
        return false;
    }
    if (typeof externLegendPic == 'string') {
        url = externLegendPic;
    }
    // console.log(url
    //  addLegendToDiv(node.visibility, node.legendTitle, styleId, image, node.divIndex)
    return <img src={url} className="legendBackgroundImg" alt={'legendpic'}/>;
};

export const getBaseWMSLayers = (layerArray, baseLayer, isPermaLink, permaLinkArray, baseWmsWorkspace) => {
    let basisLayer = baseLayer!==null?baseLayer.toLowerCase():null;
    return new Promise(async function (resolve, reject) {
        try {
            let wmsArray = [];
            for (const layer of layerArray) {
                let visible = false;
                let layerId = layer.name.toLowerCase();
                let baseLayer = (basisLayer !== null && basisLayer !== geoSettings.activeBaseLayer) ? basisLayer : geoSettings.activeBaseLayer;
                if (layerId === baseLayer) {
                    visible = true;
                }
                const layerVisibility = setPermaLayerVisible(visible, isPermaLink, permaLinkArray, layerId);
                let wmsLayer = getWmsLayer(layer.name, baseWmsWorkspace, layerVisibility, 'raster', true, layerId, null, null, null);
                wmsArray.push(wmsLayer);
                if (wmsArray.length === layerArray.length) {
                    resolve({success: true, layers: wmsArray});
                }
            }

        } catch (e) {
            reject(e);
        }
    });
};

export const getLegendControllArray = (currentChanged, changedLayer) => {
    let copyOldChangedValues = JSON.parse(JSON.stringify(currentChanged));
    if (changedLayer.length > 0) {
        copyOldChangedValues.forEach((formerElem) => {
            const found = changedLayer.find((newElem) => formerElem.id === newElem.id);
            if (found) {
                copyOldChangedValues = copyOldChangedValues.filter((item) => item.id !== formerElem.id)
            }
        });
    }
    return [...copyOldChangedValues, ...changedLayer];
}


export const setValIfUndefined = (condititon, val1, val2) => {
    return condititon ? val1 : val2;
};
export const setTreeValIfUndefined = (condititon, val1Key, val1, val2) => {
    //console.log(condititon, val1Key, val1, val2, val1[val1Key]);
    return condititon ? val1[val1Key] : val2;
};

export const isNotUndefined = (val) => {
    if (typeof val == 'undefined') {
        return false;
    }
    return true;
};

export const getVectorLayerObject = (source, layerId ,layerVisibility,type,key, style) => {

    const vectorLayer = new VectorLayer({
        source: source
    });
    vectorLayer.set('name', layerId);
    vectorLayer.set('id', layerId);
    vectorLayer.set('type', type);
    vectorLayer.set('zIndex', key);
    vectorLayer.set('visible', layerVisibility);
    vectorLayer.setStyle(style);

    return vectorLayer;
};

export const scaleElem = {
    12:0.8,
    11:0.75,
    10:0.7,
    9:0.65,
    8:0.6,
    7:0.55,
    6:0.5,
    5:0.45,
    4:0.4,
    3:0.35,
    2:0.3,
    1:0.3,
    default: 1
};
export const toCamelCase = (string) => {
    if (!string && typeof string == 'undefined') return string;
    let stringCamelCase = string.charAt(0).toLowerCase() + string.substring(1);
    stringCamelCase = string.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, function (match) {
        return match.charAt(match.length - 1).toUpperCase();
    });

    if (string === string.toUpperCase()) {
        return string;
    } else {
        return stringCamelCase;
    }
};

export const fetchChannels = async (projectId, locationId) => {
    let retValue = [];
    await keycloakfetch.get(settings.apiUrl + '/getChannelIdsByLocationId?project_id=' + projectId + '&location_id=' + locationId).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
};

export const setGeoData=(props)=>{
    let geoDataProject=geoSettings.altBaseGeodata;
    if(props.project!==null){
        geoDataProject={
            base_layer:props.project.base_layer,
            baselayer_workspace:props.project.baselayer_workspace,
            geoserver_workspace:props.project.geoserver_workspace,
            location:props.project.location,
            location_3587:props.project.location_3587,
            location_epsg_code:props.project.location_epsg_code,
            location_zoom:props.project.location_zoom
        };
    }
    return geoDataProject;
};
