import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import Input from '../global/Input';
import CustomDataTable from '../global/CustomDataTable';
import {
    Project_CustomerType,
    Project_InspectionTaskType,
    getInspectionTaskTypesColumns, getInspectionTaskTypesFilters
} from '../functions/TypeManagement';
import CustomDialog from '../global/CustomDialog';
import {showMessageOnSuccess} from '../global/CustomToast';
import {
    useAddTaskTypeMutation,
    useLazyGetAllInspectionTaskTypesQuery,
    useUpdateTaskTypeMutation
} from "../../redux/rtk/injectedInspectionApi";
import {useGetProjectTypesQuery, useLazyGetProjectTypesQuery} from "../../redux/rtk/injectedProjectTypes";

type Props = {
    showEdit: boolean,
    setShowEdit: (value: boolean) => void
}

export const InspectionTaskTypeEditForm_New = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [selectedItem, setSelectedItem] = useState<Project_CustomerType | undefined>(undefined);
    const [showDialog, setShowDialog] = useState<boolean>(props.showEdit);

    const projectTypes = useGetProjectTypesQuery({});

    const [getInspectionTaskType, inspectionTaskTypeResult] = useLazyGetAllInspectionTaskTypesQuery();
    const [addTaskType] = useAddTaskTypeMutation();
    const [updateTaskType] = useUpdateTaskTypeMutation();

    const defaultValues: Project_InspectionTaskType = {
        id: null,
        name: '',
        project_type_id: null
    };

    const {control, formState: {errors}, handleSubmit, setValue, getValues, watch, reset} = useForm({defaultValues});

    useEffect(() => {
        getInspectionTaskType({});
    }, []);

    useEffect(() => {
        setShowDialog(props.showEdit);
    }, [props.showEdit]);

    const getFormErrorMessage = (name: string) => {
        if (name.indexOf('.') >= 0) {
            const split = name.split('.');

            if (split.length === 3) {
                // @ts-ignore
                if (errors[split[0]] && errors[split[0]][split[1]] && errors[split[0]][split[1]][split[2]]) {
                    // @ts-ignore
                    const text = errors[split[0]][split[1]][split[2]].message;

                    return <small className="p-error custom">{text}</small>;
                }
            }
        } else {
            // @ts-ignore
            if (errors[name]) {
                // @ts-ignore
                const text = errors[name].message;

                return <small className="p-error custom">{text}</small>;
            }
        }
        return undefined;
    };

    const onEditClick = useCallback((data: Project_CustomerType) => {
        setSelectedItem(data);
        reset(data);
        setShowDialog(true);
    }, []);

    const hideDialog = () => {
        setShowDialog(false);
        setTimeout(() => {
            setSelectedItem(undefined);
            reset(defaultValues);
            props.setShowEdit(false);
        }, 200);
    };

    const onSave = (data: Project_InspectionTaskType) => {
        if (selectedItem) {
            updateTaskType(data).then((res: any) => {
                if (!res.error) {
                    showMessageOnSuccess(t('success'), t('saved'));
                    getInspectionTaskType({});
                    hideDialog();
                }
            });
        } else {
            addTaskType(data).then((res: any) => {
                if (!res.error) {
                    showMessageOnSuccess(t('success'), t('saved'));
                    getInspectionTaskType({});
                    hideDialog();
                }
            });
        }
    };

    return (
        <>
            <CustomDataTable id={'project_inspection_task_types'} headerTitle={t('inspection_task_types')}
                             columns={getInspectionTaskTypesColumns(t, {
                                 projectTypes: projectTypes.data,
                             })}
                             filters={getInspectionTaskTypesFilters()}
                             editable={true}
                             sortable={true}
                             paginator={true}
                             value={inspectionTaskTypeResult.data} dataloaded={inspectionTaskTypeResult.data == null}
                             ableToUpdate={true}
                             onEditClick={onEditClick}/>
            <CustomDialog
                header={selectedItem ?
                    <div className='flex'><i className='pi pi-pencil my-auto mr-2'/>{t('inspection_task_type_edit')}
                    </div> :
                    <div className='flex'><i className='pi pi-plus my-auto mr-2'/>{t('inspection_task_type_new')}
                    </div>}
                visible={showDialog}
                onCancel={hideDialog} formName={'customerTypeForm_New'} style={{maxWidth: '800px'}}>
                <form id='customerTypeForm_New' onSubmit={handleSubmit(onSave)}>
                    <div className="card">
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <Input
                                    edit={true}
                                    label={t('project_type_id')}
                                    name={`project_type_id`}
                                    disabled={selectedItem != null}
                                    type={'dropdown'}
                                    dropdownOptions={projectTypes.data}
                                    options={{optionLabel: 'name', optionValue: 'id'}}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('input_required')}}

                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <Input
                                    edit={true}
                                    label={t('name')}
                                    name={`name`}
                                    type={'text'}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('input_required')}}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </>
    );
};
