import {baseApi} from './baseApi';

export const injectedInspectionApi = baseApi.injectEndpoints({
    addTagTypes: ['Inspection'],
    endpoints: (builder) => ({
        // Inspections
        getInspections: builder.query({
            query: (args) => {
                const {projectId, elementId} = args;

                let url = `getInspections?project_id=${projectId}`;

                if (elementId) {
                    url += '&element_id=' + elementId;
                }

                return {url: url};
            },
            providesTags: ['Inspection']
        }),
        getInspectionTasks: builder.query({
            query: (args) => {
                const {projectId, inspectionId, elementId} = args;

                let url = `getInspectionTasks?project_id=${projectId}${inspectionId ? `&inspection_id=${inspectionId}` : ''}&element_id=${elementId}`;

                return {url: url};
            },
            providesTags: ['Inspection']
        }),
        getInspectionFields: builder.query({
            query: (args) => {
                const {projectId, inspectionId, locale} = args;

                let url = `getInspectionFields?project_id=${projectId}&locale=${locale}${inspectionId ? `&inspection_id=${inspectionId}` : ''}`;

                return {url: url};
            },
            providesTags: ['Inspection']
        }),
        getInspectionTaskType: builder.query({
            query: (args) => {
                const {projectId, elementId} = args;

                let url = `getInspectionTaskType?project_id=${projectId}${elementId ? `&element_id=${elementId}` : ''}`;

                return {url: url};
            },
            providesTags: ['Inspection']
        }),
        getInspectionTaskFields: builder.query({
            query: (args) => {
                const {projectId, inspectionTaskId, locale} = args;

                let url = `getInspectionTaskFields?project_id=${projectId}&inspection_task_id=${inspectionTaskId}&locale=${locale}`;

                return {url: url};
            },
            providesTags: ['Inspection']
        }),
        updateInspectionTask: builder.mutation({
            query: (document) => ({
                url: 'updateInspectionTask',
                method: 'PUT',
                body: document,
            }),
            invalidatesTags: ['Inspection', 'UpdateInspectionTask'],
        }),
        getInspectionElements: builder.query({
            query: (args) => {
                const {projectId, inspectionId} = args;

                let url = `getInspectionElements?project_id=${projectId}&inspection_id=${inspectionId}`;

                return {url: url};
            },
            providesTags: ['Inspection']
        }),
        deleteInspectionTasks: builder.mutation({
            query: (unit) => ({
                url: 'deleteInspectionTasks',
                method: 'DELETE',
                body: unit,
            }),
            invalidatesTags: ['Inspection']
        }),
        getInspectionTaskDocuments: builder.query({
            query: (args) => {
                const {projectId, inspectionTaskId} = args;

                const url = `getInspectionTaskDocuments?project_id=${projectId}&inspection_task_id=${inspectionTaskId}`;

                return {url: url};
            },
            providesTags: ['Inspection']
        }),
        createInspectionTaskDocument: builder.mutation({
            query: (document) => ({
                url: 'createInspectionTaskDocument',
                method: 'POST',
                body: document,
            }),
            invalidatesTags: ['Inspection']
        }),
        setInspectionTaskDocumentPreview: builder.mutation({
            query: (document) => ({
                url: 'setInspectionTaskDocumentPreview',
                method: 'PUT',
                body: document,
            }),
            invalidatesTags: ['Inspection']
        }),
        createInspection: builder.mutation({
            query: (item) => ({
                url: 'createInspection',
                method: 'POST',
                body: item,
            }),
            invalidatesTags: ['Inspection']
        }),
        updateInspection: builder.mutation({
            query: (item) => ({
                url: 'updateInspection',
                method: 'PUT',
                body: item,
            }),
            invalidatesTags: ['Inspection']
        }),
        deleteInspection: builder.mutation({
            query: (item) => ({
                url: 'deleteInspection',
                method: 'DELETE',
                body: item,
            }),
            invalidatesTags: ['Inspection']
        }),
        getUsers: builder.query({
            query: (args) => {
                const {projectId, roleId} = args;

                const url = `getUsers?project_id=${projectId}&role_id=${roleId}`;

                return {url: url};
            },
            providesTags: ['Inspection']
        }),

        // Inspection Types
        getInspectionType: builder.query({
            query: (args) => {

                const {projectId} = args;

                const url = 'getInspectionType?project_id=' + projectId;

                return {url: url};
            },
            providesTags: ['InspectionTypes']
        }),
        getProjectTypesInTasks: builder.query({
            query: () => {
                const url = 'getProjectTypesInTasks';

                return {url: url};
            },
            providesTags: ['InspectionTypes']
        }),

        getAllInspectionTaskTypes: builder.query({
            query: (args) => {
                const {projectTypeId} = args;

                let url = `getAllInspectionTaskTypes`;

                if (projectTypeId) {
                    url += '?projectTypeId=' + projectTypeId;
                }

                return {url: url};
            },
            providesTags: ['InspectionTypes']
        }),
        getAllInspectionTaskTypesGlobal: builder.query({
            query: (args) => {
                const {projectTypeId} = args;

                let url = 'getAllInspectionTaskTypesGlobal';

                if (projectTypeId) {
                    url += '?projectTypeId=' + projectTypeId;
                }

                return {url: url};
            }, providesTags: ['InspectionTypes']
        }),
        getInspectableElementTypes: builder.query({
            query: () => {
                const url = 'getInspectableElementTypes';

                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }),
        addTaskType: builder.mutation({
            query: (unit) => ({
                url: 'addTaskType',
                method: 'POST',
                body: unit,
            }),
            invalidatesTags: ['InspectionTypes']
        }),
        updateTaskType: builder.mutation({
            query: ({id, name, project_type_id}) => ({
                url: 'updateTaskType',
                method: 'PUT',
                body: {id, name, project_type_id},
            }),
            invalidatesTags: ['InspectionTypes']
        }),
        getAllInspectionTypes: builder.query({
            query: (args) => {
                const {elementTypeId} = args;

                const url = `getAllInspectionTypes?elementTypeId=${elementTypeId}`;

                return {url: url};
            },
            providesTags: ['InspectionTypes']
        }),
        addInspectionType: builder.mutation({
            query: (unit) => ({
                url: 'addInspectionType',
                method: 'POST',
                body: unit,
            }),
            invalidatesTags: ['InspectionTypes']
        }),
        updateInspectionType: builder.mutation({
            query: ({id, name, project_type_id, cycle_in_month, element_type_id}) => ({
                url: 'updateInspectionType',
                method: 'PUT',
                body: {id, name, project_type_id, cycle_in_month, element_type_id},
            }),
            invalidatesTags: ['InspectionTypes']
        }),
        getInspectionElementTypes: builder.query({
            query: () => {
                return {url: 'getInspectionElementTypes'};
            },
            providesTags: ['InspectionTypes']
        }),

    }),
});

export const {
    useGetInspectionsQuery,
    useGetInspectionTasksQuery,
    useGetInspectionFieldsQuery,
    useGetInspectionTypeQuery,
    useGetInspectionTaskTypeQuery,
    useLazyGetInspectionTaskTypeQuery,
    useLazyGetInspectionTaskFieldsQuery,
    useUpdateInspectionTaskMutation,
    useGetInspectionElementsQuery,
    useDeleteInspectionTasksMutation,
    useGetInspectionTaskDocumentsQuery,
    useCreateInspectionTaskDocumentMutation,
    useSetInspectionTaskDocumentPreviewMutation,
    useCreateInspectionMutation,
    useUpdateInspectionMutation,
    useDeleteInspectionMutation,
    useLazyGetProjectTypesInTasksQuery,
    useGetAllInspectionTaskTypesQuery,
    useLazyGetAllInspectionTaskTypesQuery,
    useLazyGetAllInspectionTaskTypesGlobalQuery,
    useLazyGetInspectableElementTypesQuery,
    useAddTaskTypeMutation,
    useUpdateTaskTypeMutation,
    useLazyGetAllInspectionTypesQuery,
    useAddInspectionTypeMutation,
    useUpdateInspectionTypeMutation,
    useLazyGetInspectionElementTypesQuery
} = injectedInspectionApi;
