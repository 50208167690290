import CustomDataTable from '../global/CustomDataTable';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {deleteRole, getPermissionTypes, getRoles, Role} from '../functions/UserManagement';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {Chip} from 'primereact/chip';

type Nullable<T> = T | null;

type Props = {
    ableToWriteRole: boolean,
    projectId: string,
    reload: boolean,
    setReload: (realod: boolean) => void
    selectedRole: Nullable<Role>,
    setSelectedRole: any,
    setShowRoleDialog: any
}
export const RoleOverview = (props: Props) => {
    const {t} = useTranslation(['common']);
    const [roles, setRoles] = useState<Role[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (props.reload) {
            props.setReload(false);
            getRoles(props.projectId).then(roles => {
                getPermissionTypes(props.projectId, t).then(permissionTypes => {
                    for (let i = 0; i < roles.length; i++) {
                        const role = roles[i];

                        const itemPermissionTypes = permissionTypes.filter(function (e: any) {
                            // @ts-ignore
                            return this.indexOf(e.key) >= 0;
                        }, role.permissions);

                        role['expandableElement'] = (<div className="grid pt-3">
                            {itemPermissionTypes.map((permission: any) => {
                                return (
                                    <Chip
                                        key={permission.key}
                                        label={permission.label}
                                        className="col-auto mr-2 mb-2"
                                        icon={permission.icon}
                                    />
                                );
                            })}
                        </div>);
                    }
                    setRoles(roles);
                    setDataLoaded(true);
                });
            });
        }
    }, [props.reload]);


    return <CustomDataTable
        id='roles-role'
        columns={[
            {field: 'name', header: t('userM_role'), style: {minWidth: '200px'}, className: 'no-hover'},
            {field: 'number_of_permissions', header: t('userM_numberOfPermissions'), className: 'no-hover'}
        ]}
        editable={props.ableToWriteRole}
        sortable={true}
        value={roles}
        expandable={true}
        onRemoveClick={(role: any) => {
            props.setSelectedRole(null);
            CustomConfirmDialog({
                message: t('userMgmt_dialogs_deleteRole_messagePart1') + '"' + role.name + '"' + t('userMgmt_dialogs_deleteRole_messagePart2'),
                header: t('userMgmt_deleteRoleDialog_header'),
                translation: t,
                onConfirm: () => {
                    deleteRole(props.projectId, role.id).then(result => {
                        if (parseInt(result) === 1) {
                            props.setReload(true);
                            showMessageOnSuccess(t('success'), t('userMgmt_toasts_roleDeleted'));
                        } else {
                            showMessageOnError(t('error'), t('errorMessages:' + result.error));
                        }
                    });
                },
            });
        }}
        onEditClick={(e: any) => {
            props.setSelectedRole(e);
            props.setShowRoleDialog(true);
        }}
        ableToUpdate={props.ableToWriteRole}
        ableToDelete={props.ableToWriteRole}
        dataloaded={dataLoaded}
    />;
};
