import {baseApi} from './baseApi';

export const injectedCustomerTypesApi = baseApi.injectEndpoints({
    addTagTypes: ['CustomerTypes'],
    endpoints: (builder) => ({
        getAllCustomerTypes: builder.query({
            query: () => {
                const url = 'getAllCustomerTypes';

                return {url: url};
            },
            providesTags: ['CustomerTypes']
        }),
        getSingleTypeNames: builder.query({
            query: () => {
                const url = 'getSingleTypeNames';

                return {url: url};
            },
            providesTags: ['CustomerTypes']
        }),
        getSingleTypeNamesGlobal: builder.query({
            query: () => {
                const url = 'getSingleTypeNamesGlobal';

                return {url: url};
            },
            providesTags: ['CustomerTypes']
        }),
        getExactCustomerTypes: builder.query({
            query: (args) => {
                const {customerType} = args;

                return {url: 'getExactCustomerTypes?customer_type=' + customerType};
            },
            providesTags: ['CustomerTypes']
        }),
        createCustomerType: builder.mutation({
            query: (unit) => ({
                url: 'createCustomerType',
                method: 'POST',
                body: unit,
            }),
            invalidatesTags: ['CustomerTypes']
        }),
        updateCustomerType: builder.mutation({
            query: (unit) => ({
                url: 'updateCustomerType',
                method: 'PUT',
                body: unit,
            }),
            invalidatesTags: ['CustomerTypes']
        }),
        deleteCustomerType: builder.mutation({
            query: (unit) => ({
                url: 'deleteCustomerType',
                method: 'DELETE',
                body: unit,
            }),
            invalidatesTags: ['CustomerTypes']
        }),
    }),
});

export const {
    useGetAllCustomerTypesQuery,
    useLazyGetAllCustomerTypesQuery,
    useGetSingleTypeNamesQuery,
    useLazyGetSingleTypeNamesGlobalQuery,
    useLazyGetSingleTypeNamesQuery,
    useLazyGetExactCustomerTypesQuery,
    useCreateCustomerTypeMutation,
    useUpdateCustomerTypeMutation,
    useDeleteCustomerTypeMutation,
} = injectedCustomerTypesApi;
