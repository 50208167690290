import React from "react";
import {ColumnObject, ColumnObjectType} from "../global/CustomDataTable";
import {filterTriStateCheckBox} from "../global/filter";
import {getColumnField, getFilterField} from "./Global";
import {FilterMatchMode, FilterService} from "primereact/api";

export type Project_ProjectType = {
    "id": number,
    "name": string,
    "style_type": string
};

export type Project_ElementType = {
    has_inspection: boolean
    has_parent: boolean
    icon: string
    id: number
    location_supported: boolean
    multi_parents: boolean
    name: string
    name_field_label: string
    project_type_id: number
    show_as_tree: boolean
    children_type_ids: number[]
    sibling_type_ids: number[]
    inspection_task_type_ids: number[]
}

export type Project_ElementFieldType = {
    id: number | null,
    project_type_id: number | null,
    element_type_id: number | null,
    element_subtype_id: number | null,
    name: string,
    count: number | null,
    data_type: string,
    object_type: string,
    check_value: string,
    api_call: string
}

export type Project_CustomerType = {
    id: number | null,
    name: string,
    type_name: string,
}

export type Project_InspectionTaskType = {
    id: number | null,
    name: string,
    project_type_id: number | null,
}

export const getProjectTypesColumns = (t: any) => {
    const result: ColumnObject[] = [];

    result.push(getColumnField(t, 'id', 'project_types_', false, {className: 'no-hover'}));
    result.push(getColumnField(t, 'name', 'project_types_', true, {className: 'no-hover'}));
    result.push(getColumnField(t, 'style_type', 'project_types_', true, {className: 'no-hover'}));
    return result;
};

export const getProjectTypesFilters = () => {

    // FilterService.register('custom_timestamp', (value, filters) => {
    //     const [from, to] = filters ?? [null, null];
    //     if (value) {
    //         const valueDate = new Date(value);
    //         if (from === null && to === null) return true;
    //         if (from !== null && to === null) return from <= valueDate;
    //         if (from === null && to !== null) return valueDate <= to;
    //         return from <= valueDate && valueDate <= to;
    //     }
    //     return true;
    // });

    return {
        ...getFilterField('name'),
        ...getFilterField('style_type'),
        // 'timestamp': {value: null, matchMode: FilterMatchMode.CUSTOM},
    };
};

export const getProjectElementTypesColumns = (t: any, lists: any) => {
    const result: ColumnObject[] = [];

    result.push(getColumnField(t, 'project_type_id', 'element_types_', true, {
        filterDropdownValues: lists.projectTypes ? lists.projectTypes.map((item: Project_ProjectType) => {
            return {value: item.id, name: item.name};
        }) : [],
        body: (dataRow: any) => {
            if (lists && lists.projectTypes) {
                const item = lists.projectTypes.find((item: any) => item.id == dataRow.project_type_id);
                if (item) {
                    return item.name;
                }
            }
            return dataRow.id;
        }
    }));
    result.push(getColumnField(t, 'name', 'element_types_', true));
    result.push(getColumnField(t, 'name_field_label', 'element_types_', true));
    result.push(getColumnField(t, 'icon', 'element_types_', true, {
        body: (dataRow: any) => {
            return <div className={'flex'}><i className={'mr-2 ' + dataRow.icon}></i>{dataRow.icon}</div>
        }
    }));


    result.push(getColumnField(t, 'children_type_ids', 'element_types_', true, {
        filter: false,
        body: (dataRow: any) => {
            if (dataRow.children_type_ids) {
                return dataRow.children_type_ids.length;
            }
        }
    }));
    result.push(getColumnField(t, 'sibling_type_ids', 'element_types_', true, {
        filter: false,
        body: (dataRow: any) => {
            if (dataRow.sibling_type_ids) {
                return dataRow.sibling_type_ids.length;
            }
        }
    }));

    result.push(getColumnField(t, 'inspection_task_type_ids', 'element_types_', true, {
        filter: false,
        body: (dataRow: any) => {
            if (dataRow.inspection_task_type_ids) {
                return dataRow.inspection_task_type_ids.length;
            }
        }
    }));

    result.push(getColumnField(t, 'has_parent', 'element_types_', true, {
        type: ColumnObjectType.Boolean,
        filter: false
    }));
    result.push(getColumnField(t, 'show_as_tree', 'element_types_', true, {
        type: ColumnObjectType.Boolean,
        filter: false
    }));
    result.push(getColumnField(t, 'multi_parents', 'element_types_', true, {
        type: ColumnObjectType.Boolean,
        filter: false
    }));
    result.push(getColumnField(t, 'location_supported', 'element_types_', true, {
        type: ColumnObjectType.Boolean,
        filter: false
    }));
    result.push(getColumnField(t, 'has_inspection', 'element_types_', true, {
        type: ColumnObjectType.Boolean,
        filter: false
    }));

    return result;
};

export const getElementTypesFilters = () => {
    return {
        project_type_id: {
            value: null,
            matchMode: FilterMatchMode.IN
        },
        ...getFilterField('name'),
        ...getFilterField('name_field_label'),
        ...getFilterField('icon'),
        ...getFilterField('has_parent'),
        ...getFilterField('show_as_tree'),
        ...getFilterField('multi_parents'),
        ...getFilterField('location_supported'),
        ...getFilterField('has_inspection'),
    };
};

export const getProjectElementFieldTypesColumns = (t: any, lists: any) => {
    const result: ColumnObject[] = [];

    result.push(getColumnField(t, 'id', '', false));
    result.push(getColumnField(t, 'project_type_id', '', true, {
        filterDropdownValues: lists.projectTypes ? lists.projectTypes.map((item: Project_ProjectType) => {
            return {value: item.id, name: item.name};
        }) : [],
        body: (dataRow: any) => {
            if (lists && lists.projectTypes) {
                const item = lists.projectTypes.find((item: any) => item.id == dataRow.project_type_id);
                if (item) {
                    return item.name;
                }
            }
            return dataRow.id;
        }
    }));
    result.push(getColumnField(t, 'element_type_id', '', true, {
        filterDropdownValues: lists.elementTypes ? lists.elementTypes.map((item: Project_ElementType) => {
            return {value: item.id, name: item.name};
        }) : [],
        body: (dataRow: any) => {
            if (lists && lists.elementTypes) {
                const item = lists.elementTypes.find((item: any) => item.element_type_id == dataRow.element_type_id);
                if (item) {
                    return item.element_type_name;
                }
            }
            return dataRow.id;
        }
    }));
    result.push(getColumnField(t, 'name', '', true));
    result.push(getColumnField(t, 'count', '', true));
    result.push(getColumnField(t, 'data_type', '', true));
    result.push(getColumnField(t, 'object_type', '', true));
    result.push(getColumnField(t, 'check_value', '', true));
    result.push(getColumnField(t, 'element_subtype_id', '', true));
    result.push(getColumnField(t, 'api_call', '', true));
    return result;
};

export const getElementFieldTypesFilters = () => {
    return {
        project_type_id: {
            value: null,
            matchMode: FilterMatchMode.IN
        },
        element_type_id: {
            value: null,
            matchMode: FilterMatchMode.IN
        },
        ...getFilterField('name'),
        ...getFilterField('count'),
        ...getFilterField('data_type'),
        ...getFilterField('object_type'),
        ...getFilterField('check_value'),
        ...getFilterField('element_subtype_id'),
        ...getFilterField('api_call'),
    };
};

export const getProjectCustomertypesColumns = (t: any) => {
    const result: ColumnObject[] = [];

    result.push(getColumnField(t, 'id', 'customer_types_', false));
    result.push(getColumnField(t, 'type_name', 'customer_types_', true, {className: 'no-hover'}));
    result.push(getColumnField(t, 'name', 'customer_types_', true, {className: 'no-hover'}));
    return result;
};

export const getCustomerTypesFilters = () => {
    return {
        ...getFilterField('name'),
        ...getFilterField('type_name'),
    };
};

export const getInspectionTaskTypesColumns = (t: any, lists: any) => {
    const result: ColumnObject[] = [];

    result.push(getColumnField(t, 'id', '', false));
    result.push(getColumnField(t, 'project_type_id', '', true, {
        className: 'no-hover',
        filterDropdownValues: lists.projectTypes ? lists.projectTypes.map((item: Project_ProjectType) => {
            return {value: item.id, name: item.name};
        }) : [],
        body: (dataRow: any) => {
            if (lists && lists.projectTypes) {
                const item = lists.projectTypes.find((item: any) => item.id == dataRow.project_type_id);
                if (item) {
                    return item.name;
                }
            }
            return dataRow.id;
        }
    }));
    result.push(getColumnField(t, 'name', '', true, {className: 'no-hover'}));
    return result;
};

export const getInspectionTaskTypesFilters = () => {
    return {
        ...getFilterField('name'),
        ...getFilterField('project_type_id'),
    };
};


