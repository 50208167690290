import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import testTreeData from '../../data/test-tree.json';

// import '../../style/UnitExplorer.scss';
import Input from '../global/Input';
import {useTranslation} from 'react-i18next';
import {UnitExplorerPath} from './UnitExplorerPath';
import {UnitExplorerContent} from './UnitExplorerContent';
import {OverlayPanel} from 'primereact/overlaypanel';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {
    useAddElementMutation,
    useDeleteElementMutation,
    useGetElementTreeQuery,
    useGetElementTypesQuery,
    useGetObjectTypesQuery,
    useLazyGetFieldsQuery, usePasteElementMutation,
    useSetElementInactiveMutation,
    useTogglePipelineOrderMutation,
    useUpdateElementConnectionMutation,
    useUpdateElementMutation
} from '../../redux/rtk/injectedUnitsApi';
import {
    ElementTypeItem, Field,
    getElementType, getElementTypeName, setUnitTreeItemById,
    UnitItem
} from '../functions/Units';
import {v4 as uuidv4} from 'uuid';
import {Splitter, SplitterPanel} from 'primereact/splitter';
import {
    checkUnsavedChangesAndGoOn,
    getUserFromLocalStorage,
    isTablet,
    Nullable,
    setUserSettings
} from '../functions/Global';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {resetElementChanges} from '../../redux/slices/ElementChangesSlice';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import CustomDialog from '../global/CustomDialog';
import {setGeoData} from '../../functions/functionsOpenlayer';
import {UnitExplorerSearch} from './UnitExplorerSearch';
import {ProgressSpinner} from 'primereact/progressspinner';
import {resetApiCalls} from '../../redux/slices/ApiCallsSlice';
import {confirmDialog} from 'primereact/confirmdialog';
import {VirtualScroller} from 'primereact/virtualscroller';
import {faFolderOpen} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faCaretRight, faCopy, faScissors} from '@fortawesome/free-solid-svg-icons';
import {Tooltip} from 'primereact/tooltip';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';
import {setReloadData} from '../../redux/slices/GeomonitoringSlice';
import {initUnitExplorerSettings, UnitExplorerSettings} from './UnitExplorerSettings';

type Props = {
    project: any,
    item: Nullable<any[]>
}

export const UnitExplorer = (props: Props) => {
    const TREE_ITEM_HEIGHT: number = 25;

    const {t} = useTranslation(['common']);
    // console.log(props)
    const user = getUserFromLocalStorage();

    const isDirty = useAppSelector((state: any) => {
        return state.elementChangesData.isDirty;
    });
    const elementChanges: any[] = useAppSelector((state: any) => state.elementChangesData.elementChanges);
    const dispatch = useAppDispatch();

    const [project, setProject] = useState<any>(props.project);

    const [triggerGetFields, resultGetFields, lastPromiseGetFields] = useLazyGetFieldsQuery();

    const [addElement, resultAddElement] = useAddElementMutation();
    const [updateElement, resultUpdateElement] = useUpdateElementMutation();
    const [updateElementConnection, resultUpdateElementConnection] = useUpdateElementConnectionMutation();
    const [deleteElement, resultDeleteElement] = useDeleteElementMutation();
    const [setElementInactive, resultSetElementInactive] = useSetElementInactiveMutation();
    const [togglePipelineOrder, resultTogglePipelineOrder] = useTogglePipelineOrderMutation();
    const [pasteElement, resultPasteElement] = usePasteElementMutation();

    const treeRef: any = useRef(null);
    const menu: any = useRef(null);

    const [menuItems, setMenuItems] = useState<any[]>([]);

    const scrollRef = useRef<any>(null);

    const [canEdit, setCanEdit] = useState<boolean>(true);
    const [editMode, setEditMode] = useState<boolean>(true);

    const [unitTree, setUnitTree] = useState<any>(null);
    const [useHash, setUseHash] = useState<boolean>(true);
    const [activeItem, setActiveItem] = useState<any>(undefined);
    const [activeContentItem, setActiveContentItem] = useState<UnitItem | undefined>(undefined);
    const [scrollIntoViewId, setScrollIntoViewId] = useState<string>('');
    const [activeFields, setActiveFields] = useState<Nullable<Field[]>>(null);
    const [activePath, setActivePath] = useState<any[]>([]);
    const [addedElementId, setAddedElementId] = useState<string>('');
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string>('');
    const [inactiveId, setInactiveId] = useState<string>('');
    const [treeDrawed, setTreeDrawed] = useState<boolean>(false);
    const refElementChanges = useRef(elementChanges);
    const [triggerUpdateTree, setTriggerUpdateTree] = useState<boolean>(false);

    const [isPipeline, setIsPipeline] = useState<boolean>(false);
    // const [splitterSizes, setSplitterSizes] = useState<any>(undefined);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showLoadingContent, setShowLoadingContent] = useState<boolean>(false);

    const [showSettingsDialog, setShowSettingsDialog] = useState<boolean>(false);
    const [unitExplorerSettings, setUnitExplorerSettings] = useState<UnitExplorerSettings>(initUnitExplorerSettings);
    const [unitExplorerSettingsTemp, setUnitExplorerSettingsTemp] = useState<UnitExplorerSettings>(initUnitExplorerSettings);
    const [showVS, setShowVS] = useState<boolean>(false);

    const [numToleratedItems, setNumToleratedItems] = useState<number | undefined>(undefined);

    const [treeItems, setTreeItems] = useState<any[]>([]);

    const [copiedElement, setCopiedElement] = useState<UnitItem | undefined>(undefined);
    const [copiedElementOperation, setCopiedElementOperation] = useState<string>('');

    const geoDataProject = useMemo(() => {
        return setGeoData(props);
    }, [props, setGeoData]);

    const elementTreeQuery = useGetElementTreeQuery({projectId: props.project.id}, {skip: props.item != null});
    const elementTypesQuery = useGetElementTypesQuery({
        projectId: props.project.id,
        locale: user ? user.language_id : 'de'
    });

    useEffect(() => {
        // const user = getUserFromLocalStorage();

        // if (user) {
        //     if (user.settings && user.settings[props.project.id]) {
        //         const splitter = user.settings[props.project.id].unit_tree_splitter;
        //
        //         if (splitter) {
        //             setSplitterSizes(JSON.parse(splitter));
        //         }
        //     }
        // }

        _setMenuItems();
        getUnitExplorerSettings();
        setShowVS(true);
    }, []);

    const getUnitExplorerSettings = () => {
        const user = getUserFromLocalStorage();
        const ueSettings = user.settings && user.settings.uesettings ? JSON.parse(JSON.stringify(user.settings.uesettings)) : undefined;
        if (ueSettings) {
            setUnitExplorerSettings(ueSettings);
        }
    };

    useEffect(() => {
        _setMenuItems();
    }, [editMode]);

    useEffect(() => {
        setProject(props.project);
    }, [props.project]);

    useEffect(() => {
        // console.log('data to save', elementChanges);
        refElementChanges.current = elementChanges;
    }, [elementChanges]);

    useEffect(() => {
        if (project) {
            setIsPipeline(project.style_type === 'polyline');
        }
    }, [project]);

    useEffect(() => {
        //  console.log('aF', activeFields);
    }, [activeFields]);

    useEffect(() => {
        if (unitTree) {
            if (useHash) {
                const hash = window.location.hash.split('_');

                if (hash.length) {
                    getUnitByIdSetActive(hash[1], true);
                }

                setUseHash(false);
            }

            if (addedElementId !== '') {
                getUnitByIdSetActive(addedElementId, false);
            }
        }
    }, [unitTree, addedElementId]);

    useEffect(() => {
        updateTree();
        setShowLoading(false);
    }, [elementTreeQuery.data, isPipeline]);

    useEffect(() => {
        if (triggerUpdateTree) {
            setTriggerUpdateTree(false);

            if (unitTree && unitTree.length) {
                setUnitTree((prevState: any) => {
                    let tempState: any[] = [];

                    if (prevState) {
                        tempState = JSON.parse(JSON.stringify(prevState));
                    }

                    for (let i = 0; i < tempState.length; i++) {
                        addPath(tempState[i], []);
                    }

                    return tempState;
                });
            }
        }
    }, [unitTree]);

    const updateTree = () => {
        if (elementTreeQuery.data) {
            // if (isPipeline) {
            //     const temp = JSON.parse(JSON.stringify(elementTreeQuery.data));
            //
            //     const flat = [];
            //     for (let i = 0; i < temp.length; i++) {
            //         const item = temp[i];
            //         flat.push(flattenObjectForPipeline(item));
            //     }
            //     console.log('flat', flat);
            //     setUnitTree(flat);
            // } else {
            const temp = JSON.parse(JSON.stringify(elementTreeQuery.data));

            for (let i = 0; i < temp.length; i++) {
                addPath(temp[i], []);
            }

            if (activeContentItem) {

                const tempResult = getUnitById(activeContentItem.id, temp);
                if (tempResult) {
                    setActiveContentItem(tempResult);
                }
            }

            if (unitTree && unitTree.length) {
                setUnitTree((prevState: any) => {
                    let tempState: any[] = [];

                    if (prevState) {
                        tempState = JSON.parse(JSON.stringify(prevState));
                    }

                    if (temp.length === tempState.length) {
                        for (let i = 0; i < temp.length; i++) {
                            resetExpanded(temp[i], tempState[i]);
                        }
                    }

                    tempState = temp;
                    return tempState;
                });
            } else {
                setUnitTree(temp);
            }
            // }
        }
    };

    const resetExpanded = (newItem: any, oldItem: any) => {
        newItem.expanded = oldItem.expanded;
        if (newItem.children && oldItem.children && newItem.children.length === oldItem.children.length) {
            for (let i = 0; i < newItem.children.length; i++) {
                resetExpanded(newItem.children[i], oldItem.children[i]);
            }
        }
    };

    useEffect(() => {
        dispatch(resetApiCalls);
        if (props.item) {
            const temp = JSON.parse(JSON.stringify(props.item));

            for (let i = 0; i < temp.length; i++) {
                addPath(temp[i], []);
            }

            setUnitTree(temp);
        }
    }, [JSON.stringify(props.item)]);

    useEffect(() => {
        if (activeItem) {
            if (activeItem.parent) {
                setActivePath([...activeItem.parent, {id: activeItem.id, name: activeItem.name}]);

                setUnitTree((prevState: any) => {
                    if (prevState) {
                        const temp = JSON.parse(JSON.stringify(prevState));

                        for (let i = 0; i < activeItem.parent.length; i++) {
                            const parent = activeItem.parent[i];

                            for (let j = 0; j < temp.length; j++) {

                                const tempItem = _getUnitById(parent.id, temp[j]);

                                if (tempItem) {
                                    tempItem.expanded = true;
                                }
                            }
                        }

                        return temp;
                    }
                });
            }

            if (props.item) {
                const elementType = getElementType(activeItem.element_type_id, elementTypesQuery.data);
                if (elementType) {
                    setActiveFields(elementType.fields);
                }
            } else {
                triggerGetFields({projectId: project.id, elementId: activeItem.id});
            }

            const hash = window.location.hash.split('_');

            if (hash.length) {
                window.location.hash = hash[0] + (activeItem ? '_' + activeItem.id : '');
            }
        } else {
            // setShowLoading(true);
            setActiveFields(null);
            setActivePath([]);

            setUnitTree((prevState: any) => {
                if (prevState) {
                    const temp = JSON.parse(JSON.stringify(prevState));

                    return temp;
                }
            });

            // const hash = window.location.hash.split('_');
            //
            // if (hash.length) {
            //     window.location.hash = hash[0];
            // }
        }
    }, [activeItem]);

    useEffect(() => {
        setShowLoadingContent(false);
        if (resultGetFields.data) {
            if (activeItem) {
                const elementType = getElementType(activeItem.element_type_id, elementTypesQuery.data);
                if (elementType) {
                    const fields = JSON.parse(JSON.stringify(elementType.fields));

                    for (let i = 0; i < fields.length; i++) {
                        const field = fields[i];

                        if (field) {
                            const value = resultGetFields.data.find((item: any) => {
                                return item.name === field.name;
                            });

                            if (value) {
                                if (field.data_type === 'smallint') {
                                    field.value = parseInt(value.value);
                                } else {
                                    field.value = value.value;
                                }
                            } else {
                                field.value = '';
                            }
                        }
                    }
                    setActiveFields(fields);
                }
            }
        }
    }, [resultGetFields]);

    // useEffect(() => {
    //     console.log('unitTree', unitTree);
    //     if (activeItem) {
    //         getUnitByIdSetActive(activeItem.id, scrollIntoView);
    //     }
    //
    // }, [JSON.stringify(unitTree)]);

    useEffect(() => {
        setActiveContentItem(undefined);
        if (activeItem && activeFields) {
            setActiveContentItem((prevState: any) => {
                return {...activeItem, fields: activeFields};
            });
        }
    }, [activeFields]);

    // useEffect(() => {
    //     console.log('activeContentItem', activeContentItem);
    // }, [activeContentItem]);

    useEffect(() => {
        // console.log(resultUpdateElementConnection);
        if (resultUpdateElementConnection.data && resultUpdateElementConnection.data.status === 'ok') {
            window.location.reload();
        }
    }, [resultUpdateElement]);

    const addPath = (treeItem: any, parent: any[]) => {
        treeItem.parent = parent;

        treeItem.statusProgress = [];

        const tempParent = JSON.parse(JSON.stringify(parent));
        tempParent.push({id: treeItem.id, name: treeItem.name});

        if (treeItem.children) {
            // let lowestStatus = 0;

            const isActive = true;
            let lastActiveIndex = -1;

            for (let i = 0; i < treeItem.children.length; i++) {
                const unit = treeItem.children[i];

                // if (unit.inspection_task_status > 0) {
                //     treeItem.inspection_is_active_passive = true;
                //     if (lowestStatus === 0 || lowestStatus > unit.inspection_task_status) {
                //         lowestStatus = unit.inspection_task_status;
                //     }
                // }

                if (unit.is_active) {
                    lastActiveIndex = i;
                }

                // if (i === treeItem.children.length - 1) {
                //     unit.lastItem = true;
                // }

                if (unit.is_active) {
                    treeItem.statusProgress.push({status: unit.inspection_task_status, id: unit.id});
                }

                addPath(unit, tempParent);

                treeItem.statusProgress.push(...unit.statusProgress);

                if (unit.inspection_is_active_passive) {
                    treeItem.inspection_is_active_passive = true;
                    // if (lowestStatus === 0 || lowestStatus > unit.inspection_status_passive) {
                    //     lowestStatus = unit.inspection_status_passive;
                    // }
                }
            }

            if (lastActiveIndex >= 0 && treeItem.children.length > lastActiveIndex) {
                treeItem.children[lastActiveIndex].lastItem = true;
            }

            // if (treeItem.inspection_is_active_passive) {
            //     treeItem.inspection_status_passive = lowestStatus;
            // }
        }
    };

    const getStatusProgress = (treeItem: any) => {
        const result: any[] = [];

        let lastStatus = 0;
        let firstId = '';
        let count = 0;

        let left = 0;
        const width = 0;
        let index = 0;

        if (treeItem.statusProgress) {
            for (let i = 0; i < treeItem.statusProgress.length; i++) {
                index = i;
                const status = treeItem.statusProgress[i].status;
                const id = treeItem.statusProgress[i].id;

                if (i > 0 && status !== lastStatus) {
                    const width = count * (100 / treeItem.statusProgress.length);

                    pushStatusProgress(treeItem.id, firstId, index, count, width, left, lastStatus, result);

                    left += width;
                    count = 1;
                    lastStatus = status;
                    firstId = '';
                } else {
                    lastStatus = status;
                    count++;
                }

                if (!firstId) {
                    firstId = id;
                }
            }

            if (treeItem.statusProgress.length !== count || lastStatus > 0) {

                const width = count * (100 / treeItem.statusProgress.length);

                pushStatusProgress(treeItem.id, firstId, index + 1, count, width, left, lastStatus, result);
            }
        }
        return result;
    };

    const pushStatusProgress = (parentId: string, elementId: string, index: number, count: number, width: number, left: number, lastStatus: number, result: any[]) => {
        let tempWidth = width;
        const tempTop = 0;
        let showMarker = false;

        if (lastStatus === 1 && width < 1) {
            tempWidth = 2;
            // tempTop = -3;
            showMarker = true;
        }

        result.push(<>
            <Tooltip target={'#sp_' + parentId + '_' + index} position={'top'} showDelay={300}>
                {`${t('units_count')}: ${count}`}
            </Tooltip>
            <div id={'sp_' + parentId + '_' + index} className={'status-progress-item status-' + lastStatus}
                 style={{
                     width: tempWidth + '%',
                     top: tempTop + 'px',
                     left: left + '%'
                 }}
                 onClick={(e: any) => {
                     e.stopPropagation();
                     if (elementId) {
                         // console.log(elementId)
                         getUnitByIdSetActive(elementId, true);
                     }
                 }}
            ></div>
            {/* {showMarker &&*/}
            {/* <FontAwesomeIcon className='status-marker' style={{*/}
            {/*    left: tempWidth + '%'*/}
            {/*    // left: 0 +'px'*/}
            {/* }}*/}
            {/*                 icon={faCaretDown}/>}*/}
        </>);

    };

    const getTreeItem = (treeItem: any, item: any): any => {
        if (treeItem == item) {
            return item;
        } else {
            if (treeItem.children) {
                for (let i = 0; i < treeItem.children.length; i++) {
                    const item1 = treeItem.children[i];
                    return getTreeItem(item1, item);
                }
            }
        }
        return undefined;
    };

    const onClickAddObjectTypeButton = useCallback((parentId: string, objectTypeItem: ElementTypeItem | undefined, sibling: boolean) => {
        if (objectTypeItem) {
            // setShowLoadingContent(true);
            const id = uuidv4();
            setUnitTree((prevState: any) => {
                let temp: any = undefined;

                if (prevState) {
                    temp = JSON.parse(JSON.stringify(prevState));
                }

                const value: UnitItem = {
                    id: id,
                    is_active: false,
                    belong_to: '',
                    element_type_id: objectTypeItem.element_type_id,
                    element_subtype_id: objectTypeItem.element_subtype_id,
                    object_type_name: objectTypeItem.name,
                    children_type_ids: objectTypeItem.children_type_ids,
                    icon: objectTypeItem.icon,
                    inspection_is_active_passive: false,
                    inspection_is_active: false,
                    inspection_task_status: 0,
                    name: objectTypeItem.name,
                    fields: objectTypeItem.fields,
                    children: null,
                    expanded: true,
                    parent: [],
                    pthname: [],
                    internal_elements: []
                };

                if (parentId != '') {
                    const tempItem: UnitItem | undefined = getUnitById(parentId, temp);

                    if (tempItem) {
                        // if (sibling && tempItem.parent.length && tempItem.parent[tempItem.parent.length - 1]) {
                        //
                        //     const tempItemParent: UnitItem | undefined = getUnitById(tempItem.parent[tempItem.parent.length - 1].id, temp);
                        //
                        //     if (tempItemParent) {
                        //         tempItem = tempItemParent;
                        //     }
                        // }

                        if (!tempItem.children) {
                            tempItem.children = [];
                        }
                        tempItem.children.push(value);
                    }
                } else {
                    if (temp) {
                        temp.push(value);
                    } else {
                        temp = [value];
                    }
                }

                for (let i = 0; i < temp.length; i++) {
                    addPath(temp[i], []);
                }

                setShowLoading(true);
                addElement({
                    project_id: project.id,
                    element_type_id: value.element_type_id,
                    element_id: value.id,
                    name: value.name,
                    inspection_is_active: value.inspection_is_active,
                    inspection_task_status: 1,
                    element_parent_id: value.parent.length ? value.parent[value.parent.length - 1].id : ''
                });

                return temp;
            });
            // props.onChange();
            setAddedElementId(id);
        }
    }, [unitTree, addElement]);

    const onConnectPipelines = useCallback((startId: string, targetId: string) => {
        showWaitAnimation();
        updateElementConnection({project_id: project.id, element_id: startId, parent_element_id: targetId}).then(() => {
            hideWaitAnimation();
        });
    }, [updateElementConnection]);

    const onClickRemoveItem = useCallback((id: string) => {
        setDeleteId(id);
    }, []);

    const onClickSetItemInactive = useCallback((id: string) => {
        setInactiveId(id);
    }, []);


    useEffect(() => {
        (async () => {
            if (deleteId) {
                setShowLoading(true);
                await deleteElement({element_id: deleteId, project_id: project.id});
                dispatch(setReloadData(true));
            }
        })();
    }, [deleteId]);

    useEffect(() => {
        if (resultDeleteElement) {
            switch (resultDeleteElement.status) {
                case 'fulfilled':
                    setUnitTree((prevState: any) => {
                        let temp = [];

                        if (prevState) {
                            temp = JSON.parse(JSON.stringify(prevState));
                        }
                        const item = getUnitById(deleteId, temp);

                        if (item) {
                            removeUnitItem(temp, item);
                        }

                        setActiveItem(undefined);
                        return temp;
                    });

                    if (activeItem && activeItem.id === deleteId) {
                        setActiveItem(undefined);
                    }
                    setDeleteId('');
                    break;
                case 'pending':
                    // Do nothing
                    break;

                case 'rejected':
                    showMessageOnError(t('error'), t('units_delete_element_error'));
                    setDeleteId('');
                    setShowLoading(false);
                    break;
            }
        }
    }, [resultDeleteElement]);

    useEffect(() => {
        (async () => {
            if (inactiveId) {
                setShowLoading(true);
                await setElementInactive({element_id: inactiveId, project_id: project.id});
                dispatch(setReloadData(true));
                setInactiveId('');
            }
        })();
    }, [inactiveId]);

    const getUnitByIdSetActive = useCallback((id: string, scrollIntoView: boolean) => {
        if (id && unitTree) {
            for (let i = 0; i < unitTree.length; i++) {
                const item = _getUnitById(id, unitTree[i]);

                if (item) {
                    if (!activeItem || (activeItem.id !== item.id || activeItem.name !== item.name || JSON.stringify(activeItem.children) !== JSON.stringify(item.children)) || JSON.stringify(activeItem.fields) !== JSON.stringify(item.fields)) {
                        _setActiveItem(item, scrollIntoView);
                    }

                    setAddedElementId('');
                    break;
                }
            }
        } else {
            _setActiveItem(undefined, false);
        }
    }, [activeItem, treeItems, unitTree]);

    useEffect(() => {
        console.log('treeItems', treeItems);
    }, [treeItems]);

    const getUnitById = (id: string, items: UnitItem[]) => {
        for (let i = 0; i < items.length; i++) {
            const item = _getUnitById(id, items[i]);

            if (item) {
                return item;
            }
        }
    };

    const _getUnitById = (id: string, item: any): any => {
        let result: any = undefined;
        if (item.id === id) {
            result = item;
        } else {
            if (item.children) {
                for (let i = 0; i < item.children.length; i++) {
                    const item1 = item.children[i];
                    result = _getUnitById(id, item1);
                    if (result) {
                        break;
                    }
                }
            }
        }
        return result;
    };

    const removeUnitItem = (items: UnitItem[], remove: UnitItem) => {
        const removed = false;

        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            if (item.id === remove.id) {
                items = items.splice(i, 1);
            } else {
                item.children = item.children ? item.children.filter((x) => {
                    return x.id !== remove.id;
                }) : [];
            }

            if (item.children) {
                removeUnitItem(item.children, remove);
            }
        }
    };

    const _getSearchchildren = (value: string, item: any, array: any[]): any => {
        let result: any = array;
        if (item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
            result.push(item);
        } else {
            if (item.children) {
                for (let i = 0; i < item.children.length; i++) {
                    const item1 = item.children[i];
                    result = _getSearchchildren(value, item1, result);
                }
            }
        }
        return result;
    };

    const _setUnitTreeItemById = (id: string, key: string, value: any) => {
        setUnitTreeItemById(id, key, value, false, setUnitTree, undefined);
    };

    const _setActiveItem = useCallback((item: any, scrollIntoView: boolean) => {
        checkUnsavedChangesAndGoOn(t, isDirty, dispatch, () => {
            setScrollIntoViewId('');

            setActiveItem(item);

            if (scrollIntoView) {
                setScrollIntoViewId(item.id);
            }
        });
    }, [isDirty]);

    const virtualScrollerRef = useRef<any>(null);

    useEffect(() => {
        if (scrollIntoViewId && treeItems.length) {
            const treeItem = treeItems.find((itemTemp) => itemTemp.props.children.props.id === scrollIntoViewId);

            // console.log('TREEITEM', treeItems, treeItem);

            if (treeItem && virtualScrollerRef.current) {
                const treeItemIndex = treeItems.indexOf(treeItem);
                virtualScrollerRef.current.scrollToIndex(treeItemIndex);

                setScrollIntoViewId('');
            }
        }
    }, [scrollIntoViewId, treeItems, virtualScrollerRef.current]);

    const confirmSaveDialog = useCallback(async () => {
        let error = false;
        // console.log(elementChanges, refElementChanges.current)
        for (let i = 0; i < refElementChanges.current.length; i++) {
            const item = {...refElementChanges.current[i]};
            item.project_id = project.id;
            // console.log('update')
            await updateElement(item).then((result: any) => {
                if (result.error) {
                    error = true;
                } else {
                    if (item.name) {
                        _setUnitTreeItemById(item.element_id, 'name', item.name);
                    }
                }
            });
        }

        if (!error) {
            showMessageOnSuccess(t('success'), t('units_dialog_elementSaved'));
            dispatch(resetElementChanges());
            dispatch(setReloadData(true));
        }
    }, [updateElement, addElement]);

    // const changeInspectionStatus = useCallback((status: number) => {
    //     if (activeItem) {
    //         _setUnitTreeItemById(activeItem.id, 'inspection_task_status', status);
    //         setTriggerUpdateTree(true);
    //     }
    // }, [activeItem]);

    // const cancelSaveDialog = () => {
    //     dispatch(resetElementChanges());
    //     setActiveItem(tempActiveItem);
    //     setTempActiveItem(undefined);
    // };
    //
    // const hideDialogs = () => {
    //     setTempActiveItem(undefined);
    //     setShowInfo(false);
    // };

    const toggleEdit = () => {
        setEditMode(!editMode);

        // <Button type='button' icon={editMode ? "pi pi-eye" : "pi pi-pencil"}
        //         className="p-button-outlined p-button-secondary ml-2"
        //         onClick={toggleEdit}/>
        _setMenuItems();
    };

    const _setMenuItems = () => {
        setMenuItems([
            {
                label: t('units_options'),
                items: [
                    {
                        label: editMode ? t('units_stop_edit_mode') : t('untis_start_edit_mode'),
                        icon: editMode ? 'pi pi-eye' : 'pi pi-pencil',
                        command: () => {
                            toggleEdit();
                        }
                    },
                ]
            },
        ]);
    };

    const onResizeEnd = useCallback((e) => {
        //  console.log(e);
        setUserSettings('unit_tree_splitter', JSON.stringify(e.sizes), project.id);
    }, []);

    const topElements = useMemo(() => {
        return unitTree ? unitTree.map((item: UnitItem) => {
            return {value: item.id, label: item.name, belongsTo: item.element_type_id === 8};
        }) : [];
    }, [unitTree]);

    useEffect(() => {
        if (unitTree) {
            const treeItemsTemp: any = [];
            for (let i = 0; i < unitTree.length; i++) {
                createTreeItem(treeItemsTemp, undefined, unitTree[i], 0, false, false);
            }
            setTreeItems(treeItemsTemp);
        }
    }, [unitTree, isDirty, copiedElement, copiedElementOperation]);

    // useEffect(() => {
    //     console.log('treeItems', treeItems);
    // }, [treeItems]);

    // useEffect(() => {
    //     if (unitTree && treeRef.current && !treeDrawed) {
    //         createTree(treeRef.current, undefined, unitTree, 0, false, true);
    //         setTreeDrawed(true);
    //     }
    // }, [unitTree]);

    const toggleExpanded = useCallback((treeItem: any) => {
        treeItem.expanded = !treeItem.expanded;

        setUnitTree((prevState: any) => {
            if (prevState) {
                const temp = JSON.parse(JSON.stringify(prevState));

                for (let i = 0; i < temp.length; i++) {
                    const itemTemp = getTreeItem(temp[i], treeItem);

                    if (itemTemp) {
                        itemTemp.expanded = !itemTemp.expanded;
                        break;
                    }

                }
                return temp;
            }
        });
    }, []);

    const expandItem = useCallback((treeItem: any) => {
        treeItem.expanded = true;

        setUnitTree((prevState: any) => {
            if (prevState) {
                const temp = JSON.parse(JSON.stringify(prevState));

                for (let i = 0; i < temp.length; i++) {
                    const itemTemp = getTreeItem(temp[i], treeItem);

                    if (itemTemp) {
                        itemTemp.expanded = true;
                        break;
                    }

                }
                return temp;
            }
        });
    }, []);

    const elementTypes: any = {};

    const createTreeItem = (result: any[], parent: any, treeItem: any, i: number, lastItem: boolean, expanded: boolean) => {
        const style = {
            marginLeft: i * (isPipeline ? 5 : 20) + 'px',
            // overflow: 'hidden',
        };

        const style2 = {
            display: !parent || expanded ? 'inherit' : 'none',
        };

        let elementType = elementTypes[treeItem.element_type_id];

        if (!elementType) {
            elementType = getElementType(treeItem.element_type_id, elementTypesQuery.data);
            elementTypes[treeItem.element_type_id] = elementType;
        }

        let belongToName = '';

        if (treeItem.belong_to) {
            const topElementFound = topElements.find((item: any) => {
                return item.value === treeItem.belong_to;
            });

            if (topElementFound) {
                belongToName = topElementFound.label;
            }
        }

        const elementName = (<>
            <span>{treeItem.name}</span>
            <span> (</span>
            <span>{elementType.name}</span>
            {/* <span> / </span>*/}
            <span>{treeItem.children ? <span> / {treeItem.children.length}</span> : isPipeline ? '' : ' / 0'}</span>
            {belongToName && <span>{' / ' + belongToName}</span>}
            <span>)</span>
        </>);
        // (${elementType.name}${treeItem.children ? '/ ' + treeItem.children.length : isPipeline ? '' : '/ ' + 0})`}</span>

        const typeIconClass = treeItem.icon; // getElementTypeIconClass(treeItem.element_type_id, elementTypesQuery.data);

        const isPipelineWithParent = isPipeline && parent;
        const isPipelineWithoutParent = isPipeline && !parent;
        const isPiplineWithParentOrNotTree = isPipeline && (parent || (elementType && !elementType.show_as_tree));

        result.push(
            <div
                id={'treeitem_' + treeItem.id}
                className={'ue_tree-content-block scroll-container' + (lastItem ? ' last' : '')}
                style={style2}>
                <div
                    id={treeItem.id}
                    className={'connect tree-item grid grid-nogutter'
                        // + (isPipelineWithParent ? '' : isPipelineWithoutParent ? ' mt-1 mb-0' : ' my-1')
                        + (lastItem ? ' last' : '') + (treeItem.expanded ? ' expanded' : '')
                        + (treeItem.is_active !== false ? ' inspection-status-' + treeItem.inspection_task_status : '')}
                    style={style}>
                    {/* <FontAwesomeIcon className='my-auto mr-1 ml-2 pi pi-angle-right folder-arrow' icon={faCaretRight} style={{*/}
                    {/*    visibility: ((treeItem.children && treeItem.children.length)) ? 'visible' : 'hidden',*/}
                    {/*    cursor: ((treeItem.children && treeItem.children.length)) ? 'pointer' : 'default'*/}
                    {/* }} onClick={() => {*/}
                    {/*    toggleExpanded(treeItem);*/}
                    {/* }}/>*/}
                    <i className={'my-auto mr-1 ml-2 pi pi-angle-right folder-arrow'}
                       style={{
                           visibility: ((treeItem.children && treeItem.children.length)) ? 'visible' : 'hidden',
                           cursor: ((treeItem.children && treeItem.children.length)) ? 'pointer' : 'default'
                       }} onClick={() => {
                        toggleExpanded(treeItem);
                    }}></i>
                    <div
                        className={'flex w-full ue_tree-item-content select-none' + (activeItem && activeItem.id === treeItem.id ? ' active' : '')}
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={(e) => {
                            // console.log('detail', e.detail);
                            if (e.detail > 1) {
                                toggleExpanded(treeItem);
                            } else {
                                if (!activeItem || activeItem.id !== treeItem.id) {
                                    _setActiveItem(treeItem, false);
                                }

                                if (isTablet()) {
                                    expandItem(treeItem);
                                }
                            }
                        }}>
                        {elementType && <>
                            {(elementType.show_as_tree) && <i className={'ue_tree-icon-content ' + typeIconClass}/>}
                            {(!parent && !elementType.show_as_tree) &&
                                <i className={'ue_tree-icon-content-free'}>
                                    {/* <FontAwesomeIcon className='sidebar-icon mr-2' icon={faLinkSlash}/>,*/}
                                </i>}
                        </>}
                        {treeItem.is_active === false &&
                            <i className={'my-auto mr-1 pi pi-times unit_explorer-inactive-item'}></i>}
                        {isPipeline &&
                            <>
                                <i className={'ue_tree-icon-content-pipeline_' + treeItem.element_type_id}/>
                                <i className={'ue_tree-icon-content-pipeline-addition_' + treeItem.element_type_id}/>
                            </>}
                        <div className={'flex w-full my-auto'}>
                            <p className={'ue_icon-tree-text m-0' + (isPiplineWithParentOrNotTree ? ' ml-3' : ' ml-2')}>{elementName}</p>
                            <div className={'m-0 flex w-full justify-content-end'}>
                                <div className={'status-progress'}>
                                    {getStatusProgress(treeItem)}
                                    {/* {treeItem.statusProgress.map((status: number) => {*/}
                                    {/*    return <div className={'status-progress-item status-' + status}*/}
                                    {/*                style={{width: (100 / treeItem.statusProgress.length) + '%'}}></div>*/}
                                    {/* })}*/}
                                </div>
                            </div>
                            {copiedElement && treeItem.id === copiedElement.id &&
                                <div className={'flex justify-content-end w-full my-auto'}><FontAwesomeIcon
                                    icon={copiedElementOperation === 'copy' ? faCopy : faScissors}/>
                                </div>}
                        </div>
                    </div>
                    {/* {props.item != null && <div className={'flex justify-content-end w-full'}><Button*/}
                    {/*    className={'p-0 h-auto p-button-icon p-button-sm p-button-danger p-button-rounded p-button-text ml-3'}*/}
                    {/*    icon={'pi pi-times'}*/}
                    {/*    onClick={() => {*/}
                    {/*        confirmDialog({*/}
                    {/*            header: t('dialogs_removeElementHeader'),*/}
                    {/*            message: t('dialogs_removeElementMessage'),*/}
                    {/*            icon: 'pi pi-exclamation-triangle',*/}
                    {/*            acceptLabel: t('yes'),*/}
                    {/*            rejectLabel: t('no'),*/}
                    {/*            accept: () => {*/}
                    {/*                onClickRemoveItem(treeItem.id);*/}
                    {/*            }*/}
                    {/*        });*/}
                    {/*    }}/></div>}*/}
                </div>
            </div>);

        if (treeItem.expanded && treeItem.children) {
            for (let j = 0; j < treeItem.children.length; j++) {
                createTreeItem(result, treeItem, treeItem.children[j], i + 1, treeItem.children ? i === treeItem.children.length - 1 : false, treeItem.expanded);
            }
        }
    };

    const onClickTogglePipelineOrder = useCallback((id: string) => {
        if (ref.current) {
            // setShowLoading(true);
            const elements: any = ref.current;
            const found = unitTree.find((item: any) => item.id === id);

            if (found) {
                // TREE_ITEM_HEIGHT

                let foundAll = true;

                for (let i = 0; i < found.children.length; i++) {
                    const child = found.children[i];
                    const element = elements.querySelector('#treeitem_' + child.id);

                    if (!element) {
                        foundAll = false;
                        break;
                    }
                }

                if (foundAll) {
                    const count = found.children.length;

                    let firstIndex = 0;
                    let lastIndex = count - 1;
                    let offsetFirstIndex = 0;

                    let setFirstIndex = found.element_type_id === 8 ?? false;

                    while (firstIndex < lastIndex) {
                        const firstChild = !setFirstIndex ? found : found.children[firstIndex];

                        const firstElement = elements.querySelector('#treeitem_' + firstChild.id + ' .ue_tree-item-content');

                        if (!setFirstIndex) {
                            const status = elements.querySelector('#treeitem_' + firstChild.id + ' .ue_tree-item-content .status-progress');
                            if (status) {
                                status.style.display = 'none';
                            }
                        }

                        const lastChild = found.children[lastIndex];
                        const lastElement = elements.querySelector('#treeitem_' + lastChild.id + ' .ue_tree-item-content');

                        const yOffset = (lastIndex - offsetFirstIndex) + (found.element_type_id === 8 ? 0 : 1);

                        firstElement.style.transition = 'all 0.3s ease-in-out';
                        firstElement.style.transform = firstElement.style.transform ? '' : 'translate(0px, ' + TREE_ITEM_HEIGHT * yOffset + 'px)';
                        lastElement.style.transition = 'all 0.3s ease-in-out';
                        lastElement.style.transform = lastElement.style.transform ? '' : 'translate(0px,-' + TREE_ITEM_HEIGHT * yOffset + 'px)';

                        // firstElement.style.transform = 'translateX(-25px)';
                        // lastElement.style.transform = 'translateX(25px)';
                        //
                        //
                        // setTimeout(() => {
                        //     firstElement.style.transform = 'translate(-25px, ' + TREE_ITEM_HEIGHT * yOffset + 'px)';
                        //     lastElement.style.transform = 'translate(25px,-' + TREE_ITEM_HEIGHT * yOffset + 'px)';
                        //
                        //     setTimeout(() => {
                        //         firstElement.style.transform = 'translate(0px, ' + TREE_ITEM_HEIGHT * yOffset + 'px)';
                        //         lastElement.style.transform = 'translate(0px,-' + TREE_ITEM_HEIGHT * yOffset + 'px)';
                        //     }, 300)
                        // }, 300);

                        if (setFirstIndex) {
                            firstIndex++;
                        }
                        setFirstIndex = true;
                        offsetFirstIndex++;
                        lastIndex--;
                    }
                }
            }
        }

        setShowLoading(true);
        togglePipelineOrder({project_id: props.project.id, element_id: id});
    }, [unitTree]);

    useEffect(() => {
        if (resultTogglePipelineOrder.status === 'fulfilled') {
            window.location.reload();
        }
    }, [resultTogglePipelineOrder]);

    const ref = useRef(null);

    const _showSettingsDialog = () => {
        setShowSettingsDialog(true);
        setShowVS(false);
    };

    const _hideSettingsDialog = () => {
        setShowSettingsDialog(false);
        setShowVS(true);
    };

    const onCopyElement = useCallback((element: UnitItem) => {
        setCopiedElement(element);
        setCopiedElementOperation('copy');
    }, []);

    const onCutElement = useCallback((element: UnitItem) => {
        setCopiedElement(element);
        setCopiedElementOperation('cut');
    }, []);

    const onPasteElement = useCallback(() => {
        (async () => {
            setShowLoading(true);
            if (copiedElement) {
                await pasteElement({
                    project_id: props.project.id,
                    element_id: copiedElement.id,
                    parent_element_id: activeItem.id,
                    operation: copiedElementOperation
                });
                setCopiedElement(undefined);
                setCopiedElementOperation('');
            }
        })();
    }, [activeItem, copiedElement, copiedElementOperation]);

    return (<>
        {unitExplorerSettings.showPath && <div className={'grid ue_top-bar' + (editMode ? ' unit_new' : '')}>
            <div className={'col-6 md:col relative'}>
                <div className={'card mb-0 ue_path'} style={{height: '40px'}}>
                    <UnitExplorerPath path={activePath} onClick={getUnitByIdSetActive}/>
                </div>
            </div>
            <div className={'col m-auto grid md:col-fixed relative h-full flex h-full visible md:hidden'}
                 style={{width: '250px'}}>
                <div className='col card mb-0 ue_search-field'>
                    <UnitExplorerSearch projectId={project.id} onClick={(id) => {
                        getUnitByIdSetActive(id, true);
                    }}/>
                </div>
            </div>
        </div>}
        <div className={'w-full ue_container'}>
            <Splitter className={'h-full'} onResizeEnd={onResizeEnd} gutterSize={isTablet() ? 15 : 4}
                      stateKey={'unit_splitter'} stateStorage={'local'}>
                <SplitterPanel className={'hidden md:inline-block'}>
                    <div className={'relative h-full w-full ue_tree'}>
                        <div className='card h-full w-full overflow-hidden m-0 ue_icon-tree'>
                            <div className={'grid'}>
                                <div className={'col'}>
                                    <UnitExplorerSearch projectId={project.id} onClick={(id) => {
                                        getUnitByIdSetActive(id, true);
                                    }}/>
                                </div>
                                <div className={'col-fixed'}>
                                    <Button className={'p-button-rounded p-button-text'} icon={'pi pi-cog'}
                                            onClick={_showSettingsDialog}></Button>
                                </div>
                            </div>
                            {!unitTree &&
                                <div className={'flex justify-content-center w-full'} style={{marginTop: '30vh'}}>
                                    <ProgressSpinner
                                        className={'m-auto'}/>
                                </div>}
                            {treeItems &&
                                <div
                                    ref={ref}
                                    className={'px-2'}
                                    style={{
                                        display: 'inline-block',
                                        height: 'auto',
                                        position: 'absolute',
                                        bottom: '10px',
                                        top: '60px',
                                        right: '0',
                                        left: '0',
                                        overflow: 'auto'
                                    }}
                                >
                                    {showVS &&
                                        <VirtualScroller ref={virtualScrollerRef} items={treeItems}
                                                         itemSize={TREE_ITEM_HEIGHT}
                                                         numToleratedItems={numToleratedItems}
                                                         itemTemplate={(item: any, options: any) => {
                                                             return item;
                                                         }}/>}
                                </div>}
                            {/* <div ref={treeRef}></div>*/}
                            {props.item == null && unitTree && unitTree.length <= 0 &&
                                <div>{t('units_no_units')}</div>}
                        </div>
                    </div>
                </SplitterPanel>
                <SplitterPanel>
                    <div className={'relative h-full'}>
                        <div className='card h-full w-full overflow-auto ue_content relative'>
                            <UnitExplorerContent
                                key={activeContentItem ? activeContentItem.id : '0'}
                                showLoading={showLoadingContent}
                                project={project}
                                item={activeContentItem}
                                editMode={editMode}
                                // fields={[]}
                                elementTypes={elementTypesQuery.data ? elementTypesQuery.data : []}
                                topElements={topElements}
                                isPipeline={isPipeline}
                                showTopbar={unitExplorerSettings.showElementTopbar}
                                showElements={unitExplorerSettings.showElementsInElement}
                                onClick={getUnitByIdSetActive}
                                onAddClick={onClickAddObjectTypeButton}
                                onConnectClick={onConnectPipelines}
                                saveData={confirmSaveDialog}
                                geoData={geoDataProject}
                                onDeleteClick={onClickRemoveItem}
                                onInactiveClick={onClickSetItemInactive}
                                onClickTogglePipelineOrder={onClickTogglePipelineOrder}
                                copiedElement={copiedElement}
                                copiedElementOperation={copiedElementOperation}
                                onCopyClick={onCopyElement}
                                onCutClick={onCutElement}
                                onPasteClick={onPasteElement}
                                // onChangeInspectionStatus={changeInspectionStatus}
                            />
                        </div>
                    </div>
                </SplitterPanel>
            </Splitter>
            {showLoading &&
                <div className={'unit_explorer-loading flex justify-content-center w-full'}><ProgressSpinner
                    className={'m-auto'}/>
                </div>}
            {showSettingsDialog && <CustomDialog onCancel={_hideSettingsDialog} visible={showSettingsDialog}
                                                 header={t('unit_explorerSettingsDialogHeader')} onClick={() => {
                setUnitExplorerSettings(unitExplorerSettingsTemp);
                setUserSettings('uesettings', unitExplorerSettingsTemp);
                _hideSettingsDialog();
            }}>
                <UnitExplorerSettings settings={unitExplorerSettings} onChange={(settings: UnitExplorerSettings) => {
                    setUnitExplorerSettingsTemp(settings);
                }}/>
            </CustomDialog>}
        </div>
    </>);
};
