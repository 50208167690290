
const geoSettings = {
    geoserverRestPath: 'https://gis.dmt.de/geoserver/rest/',
    geoserverPath: 'https://gis.dmt.de/geoserver/',
    geoserverAuthentification: {Authorization: 'Basic ' + 'YWRtaW46QXVndXN0QDIwMTE='},
    wmsWorkspace: 'DMT',
    activeBaseLayer: 'gm_layer_maps',
    counterLayers:5,
    sensorTypeImageUrl: '/assets/sensortypes/',
    poiLayerKey:100000000000,
    redirectUrlPermalink: process.env.NODE_ENV === 'development' ? 'http://localhost:1841/' : 'https://safeguard.dmt-group.com/',
    altBaseGeodata: {
        base_layer: 'gm_layer_maps',
        baselayer_workspace: 'DMT_GERMANY',
        geoserver_workspace: null,
        //location: {coordinates: [7.058313404815774, 51.46218275313686]},//DMT
        location: {coordinates: [10.234022, 51.050244]},
        location_3587: {coordinates: [1139246.1178071592, 6630186.121449438]},
        location_epsg_code: 4326,
        location_zoom: 12
    },
};

export default geoSettings;