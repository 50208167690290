/*
 * Admins.tsx
 * Author: lnappenfeld
 * Date: 08.03.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import UserEditForm from './UserEditForm';
import UserAddForm from './UserAddForm';
import {useNavigate} from 'react-router-dom';
import {deleteUsers, getRole, getRoles, getUsers, Role} from '../functions/UserManagement';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCommentSms, faEnvelope, faPencil, faSquarePlus} from '@fortawesome/free-solid-svg-icons';
import {Nullable} from '../functions/Global';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';

type Props = {
    type: string // 'user' or 'admin'
    projectId: any,
    reloadUsers: boolean
    setReloadUsers: (e: boolean) => void,
    mayEditUM: boolean,
    editUserVisible: boolean,
    setEditUserVisible: (e: boolean) => void,
    addUsersVisible: boolean,
    setAddUsersVisible: (e: boolean) => void,
    showDeleteUsersDialog: boolean,
    setShowDeleteUsersDialog: (e: boolean) => void,
    selectedUsers: any[],
    setSelectedUsers: (e: any[]) => void,
    setSelectedAction?: (e: Nullable<any>) => void
    setShowActionDialog?: (e: boolean) => void,
    setSelectedRole?: (e: Nullable<Role>) => void
    setShowRoleDialog?: (e: boolean) => void,
}

const UsersComponents = (props: Props): JSX.Element => {
    const typeIsAdmin = (props.type === 'admin');
    const {t} = useTranslation(['common']);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const navigate = useNavigate();
    const [users, setUsers] = useState<any[]>([]);
    const [userDeleted, setUserDeleted] = useState<boolean>(false);
    const [userRoles, setUserRoles] = useState<Role[]>([]);

    const _setUsers = () => {
        getUsers(typeIsAdmin ? 'none' : props.projectId).then(result => {
            setUsers(result);
            setDataLoaded(true);
        });
    };

    const _setRoles = () => {
        getRoles(typeIsAdmin ? 'none' : props.projectId).then(result => {
            if (result)
                setUserRoles(result);
        });
    };

    useEffect(() => {
        if (props.reloadUsers) {
            _setUsers();
            _setRoles();
            props.setReloadUsers(false);
        }
    }, [props.reloadUsers]);

    // useEffect(() => {
    //     _setUsers();
    //     _setRoles();
    // }, [props.type]);

    useEffect(() => {
        if (props.type === 'admin' || props.projectId) {
            _setUsers();
            _setRoles();
            props.setSelectedUsers([]);
        }
    }, [props.projectId, props.type]);

    useEffect(() => {
        if (userDeleted) {
            getUsers(typeIsAdmin ? 'none' : props.projectId).then(result => {
                if (result.error)
                    navigate('/accessdenied');
                else
                    setUsers(result);
                setDataLoaded(true);
                setUserDeleted(false);
            });
        }
    }, [userDeleted]);

    useEffect(() => {
        if (props.showDeleteUsersDialog) {
            delUsers(props.selectedUsers);
        }
    }, [props.showDeleteUsersDialog]);

    const delUser = (data: any) => {
        CustomConfirmDialog({
            message: typeIsAdmin ? t('userM_deleteAdminDialog_message') : t('userM_deleteUserDialog_message'),
            header: typeIsAdmin ? t('confirmation') : t('confirmation'),
            translation: t,
            onConfirm: () => {
                // Kann eine Weile dauern, wegen den Grafana- und den Nextcloud-Rechten. Deswegen zeige WaitAnimation
                showWaitAnimation();
                deleteUsers(typeIsAdmin ? 'none' : data.project_id, [data.id]).then(result => {
                    hideWaitAnimation();
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        props.setShowDeleteUsersDialog(false);
                        setUserDeleted(true);
                        showMessageOnSuccess(t('success'), typeIsAdmin ? t('userMgmt_toasts_adminDeleted') : t('userMgmt_toasts_userDeleted'))
                    }
                });
            }
        });
    };

    // lösche mehrere Benutzer (auch globale Benutzer)
    const delUsers = (data: any[]) => {
        const userIds: string[] = [];
        for (const user of data) {
            userIds.push(user.id);
        }
        CustomConfirmDialog({
            message: typeIsAdmin ? t('userM_deleteAdminsDialog_message') : t('userM_deleteUsersDialog_message'),
            header: typeIsAdmin ? t('confirmation') : t('confirmation'),
            translation: t,
            onConfirm: () => {
                // Kann eine Weile dauern, wegen den Grafana- und den Nextcloud-Rechten. Deswegen zeige WaitAnimation
                showWaitAnimation();
                deleteUsers(typeIsAdmin ? 'none' : props.projectId, userIds).then(result => {
                    hideWaitAnimation();
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        setUserDeleted(true);
                        showMessageOnSuccess(t('success'), typeIsAdmin ? t('userMgmt_toasts_adminDeleted') : t('userMgmt_toasts_userDeleted'))
                    }
                    props.setShowDeleteUsersDialog(false);
                    if (props.setSelectedUsers) props.setSelectedUsers([]);
                });
            },
            onCancel: () => {
                props.setShowDeleteUsersDialog(false);
            }
        });
    };

    const dialogFinished = (success: boolean) => {
        if (success) {
            getUsers(typeIsAdmin ? 'none' : props.projectId).then(result => {
                if (result)
                    setUsers(result);
                setDataLoaded(true);
            });
        }
    };

    const userColumns: ColumnObject[] = [
        {field: 'personnel_number', header: t('userM_attribs_personnelNumber'), display: false, filter: true},
        {field: 'username', header: t('attributes.username'), filter: true},
        {field: 'first_name', header: t('userM_attribs_firstname'), filter: true},
        {field: 'last_name', header: t('userM_attribs_lastname'), filter: true},
        {
            field: 'role_name', header: t('userM_role'),
            filter: true,
            filterDropdownValues: userRoles,
            filterField: 'role_id',
            body: !typeIsAdmin && props.mayEditUM ? (user: any) => {
                return <u className='cell-function' onClick={(e) => {
                    e.stopPropagation();
                    getRole(props.projectId, user.role_id).then(result => {
                        if (props.setSelectedRole) props.setSelectedRole(result);
                        if (props.setShowRoleDialog) props.setShowRoleDialog(true);
                    });
                }}><FontAwesomeIcon icon={faPencil} className='ml-2'/> {user.role_name}</u>;
            } : null
        },
        {
            field: 'email',
            header: 'Email',
            style: {minWidth: '250px'},
            body: !typeIsAdmin && props.mayEditUM ? (user: any) => {
                if (user.has_email_action) {
                    return <u className='cell-function' onClick={(e) => {
                        e.stopPropagation();
                        /*getActionOfUser(props.projectId, user.id, 1, t).then(result => {
                            if (props.setSelectedAction) props.setSelectedAction(result);
                            if (props.setShowActionDialog) props.setShowActionDialog(true);
                        });*/
                        // props.setSelectedUsers([user]);
                    }}><FontAwesomeIcon icon={faEnvelope} className='ml-2'/> {user.email}</u>;
                } else if (!user.has_email_action && user.email) {
                    return <div className='cell-function' onClick={(e) => {
                        e.stopPropagation();
                        const action = {
                            // 'action_name': 'E-Mail an ' + user.first_name + ' ' + user.last_name,
                            'action_name': user.last_name + ', ' + user.first_name + ' (E-Mail)',
                            'action_type_id': 1,
                            'project_id': props.projectId,
                            'recipient_id': user.id,
                            'recipient_address_source': 0,
                            'recipient_address': user.email
                        };
                        // props.setSelectedUsers([user]);
                        if (props.setSelectedAction) props.setSelectedAction(action);
                        if (props.setShowActionDialog) props.setShowActionDialog(true);
                    }}><FontAwesomeIcon icon={faSquarePlus} className='ml-2'/> {user.email}</div>
                }
            } : null
        },
        {
            field: 'sms',
            header: 'SMS',
            body: !typeIsAdmin && props.mayEditUM ? (user: any) => {
                if (user.has_sms_action) {
                    return <u className='cell-function' onClick={(e) => {
                        e.stopPropagation();
                        /*getActionOfUser(props.projectId, user.id, 0, t).then(result => {
                            if (props.setSelectedAction) props.setSelectedAction(result);
                            if (props.setShowActionDialog) props.setShowActionDialog(true);
                        });*/
                        // props.setSelectedUsers([user]);
                    }}><FontAwesomeIcon icon={faCommentSms} className='ml-2'/> {user.sms}</u>;
                } else if (!user.has_sms_action && user.sms) {
                    return <div className='cell-function' onClick={(e) => {
                        e.stopPropagation();
                        const action = {
                            // 'action_name': 'SMS an ' + user.first_name + ' ' + user.last_name,
                            'action_name': user.last_name + ', ' + user.first_name + ' (SMS)',
                            'action_type_id': 0,
                            'project_id': props.projectId,
                            'recipient_id': user.id,
                            'recipient_address_source': 0,
                            'recipient_address': user.sms
                        };
                        // props.setSelectedUsers([user]);
                        if (props.setSelectedAction) props.setSelectedAction(action);
                        if (props.setShowActionDialog) props.setShowActionDialog(true);
                    }}><FontAwesomeIcon icon={faSquarePlus} className='ml-2'/> {user.sms}</div>;
                }
            } : null
        },
        {field: 'number_of_logins', header: t('userM_attribs_numberOfLogins'), style: {minWidth: 220}},
        {field: 'last_login', header: t('userM_attribs_lastLogin'), type: 4}
    ];

    const filters = {
        'personnel_number': {
            operator: FilterOperator.AND,
            constraints: [{
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            }]
        },
        'username': {
            operator: FilterOperator.AND,
            constraints: [{
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            }]
        },
        'first_name': {
            operator: FilterOperator.AND,
            constraints: [{
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            }]
        },
        'last_name': {
            operator: FilterOperator.AND,
            constraints: [{
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            }]
        },
        'role_id': {
            value: null,
            matchMode: FilterMatchMode.IN
        },
    };

    const dynamicSelectionMode = props.mayEditUM ? {
        selectionMode: 'multiple'
    } : {};

    return (
        <>
            <CustomDataTable
                {...dynamicSelectionMode}
                id='users-users'
                columns={userColumns}
                editable={props.mayEditUM}
                sortable={true}
                value={users}
                selection={props.selectedUsers}
                onSelectionChange={(e: any) => {
                    if (props.setSelectedUsers) props.setSelectedUsers(e.value);
                }}
                onRemoveClick={delUser}
                onEditClick={(data: any) => {
                    props.setSelectedUsers([data]);
                    props.setEditUserVisible(true);
                }}
                ableToDelete={props.mayEditUM}
                ableToUpdate={props.mayEditUM}
                filters={filters}
                dataloaded={dataLoaded}
                displayColumnFilter={true}
            />
            {props.editUserVisible && // Enforce mounting
                <CustomDialog
                    header={props.selectedUsers.length > 0 ? t('userM_editUserDialog_header') : t('userM_createUserDialog_header')}
                    headerType={props.selectedUsers.length > 0 ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
                    onCancel={() => {
                        props.setEditUserVisible(false);
                    }}
                    visible={props.editUserVisible}
                    onHide={() => props.setEditUserVisible(false)}
                    formName={'formDialog'}
                    className={'w-4'}
                >
                    <UserEditForm
                        type={typeIsAdmin ? 'admin' : 'user'}
                        projectId={props.projectId} // TODO
                        onFinished={dialogFinished}
                        userData={props.selectedUsers.length > 0 ? props.selectedUsers[0] : null}
                        setVisible={props.setEditUserVisible}
                    />
                </CustomDialog>
            }
            {props.addUsersVisible && // Enforce mounting
                <CustomDialog
                    header={t('userM_addUserDialog_header')}
                    formName={'formDialog'}
                    onCancel={() => {
                        props.setAddUsersVisible(false);
                    }}
                    visible={props.addUsersVisible}
                    onHide={() => props.setAddUsersVisible(false)}
                    style={{ width: '400px'}}
                >
                    <UserAddForm
                        type={typeIsAdmin ? 'admin' : 'user'}
                        projectId={props.projectId} // TODO
                        onFinished={dialogFinished}
                        setVisible={props.setAddUsersVisible}
                    />
                </CustomDialog>
            }
        </>
    );
};


export default UsersComponents;
