import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {faCirclePlus, faListCheck, faPen, faTimes, faLanguage} from '@fortawesome/free-solid-svg-icons';
import {
    useGetProjectTypesQuery,
    useAddElementFieldTypeMutation,
    useUpdateElementFieldTypeMutation,
    useLazyGetElementTypesGlobalQuery,
    useLazyGetAllElementFieldTypesDataQuery,
    useGetAllElementTypesWithoutFieldsQuery
} from '../../redux/rtk/injectedProjectTypes';
import Input from '../global/Input';
import ProjectTypeSelectList from './ProjectTypeSelectList';
import CustomDataTable from '../global/CustomDataTable';
import {
    Project_ElementType,
    getProjectElementTypesColumns,
    getProjectElementFieldTypesColumns,
    Project_ElementFieldType,
    getElementFieldTypesFilters
} from '../functions/TypeManagement';
import CustomDialog from '../global/CustomDialog';
import {showMessageOnSuccess} from '../global/CustomToast';
import {Tooltip} from "primereact/tooltip";
import ProjectElementTypeSelectList from "./ProjectElementTypeSelectList";
import ProjectElementFieldTypeData from "./ProjectElementFieldTypeData";
import ProjectElementFieldTypeObject from "./ProjectElementFieldTypeObject";
import ProjectElementFieldSubtype from "./ProjectElementFieldSubtype";
import {getUserFromLocalStorage} from "../functions/Global";
import ElementFieldTypeApiCallList from "./ElementFieldTypeApiCallList";

type Props = {
    showEdit: boolean,
    setShowEdit: (value: boolean) => void
}

export const ProjectElementFieldTypeEditForm_New = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    let user = getUserFromLocalStorage();

    const navigate = useNavigate();

    const [selectedItem, setSelectedItem] = useState<Project_ElementFieldType | undefined>(undefined);
    const [showDialog, setShowDialog] = useState<boolean>(props.showEdit);
    const [globalElementTypes, setGlobalElementTypes] = useState<any[]>([]);

    const projectTypes = useGetProjectTypesQuery({});
    const elementTypes = useGetAllElementTypesWithoutFieldsQuery({});
    const [triggerGlobalElementTypes, globalElementTypesResult] = useLazyGetElementTypesGlobalQuery();
    const [triggerElementFieldTypes, elementFieldTypesResult] = useLazyGetAllElementFieldTypesDataQuery();

    const [addProjectElementFieldType] = useAddElementFieldTypeMutation();
    const [updateProjectElementFieldType] = useUpdateElementFieldTypeMutation();

    const defaultValues: Project_ElementFieldType = {
        id: 0,
        project_type_id: null,
        element_type_id: null,
        element_subtype_id: null,
        name: '',
        count: null,
        data_type: '',
        object_type: '',
        check_value: '',
        api_call: ''
    };

    const {control, formState: {errors}, handleSubmit, setValue, getValues, watch, reset} = useForm({defaultValues});

    const selectedProjectTypeId = watch('project_type_id');
    const selectedElementTypeId = watch('element_type_id');
    const selectedObjectType = watch('object_type');

    useEffect(() => {
        if (globalElementTypesResult?.data) {
            setGlobalElementTypes(globalElementTypesResult.data.map((item: any) => {
                return {value: item.element_type_id, label: item.name};
            }));
        }
    }, [globalElementTypesResult]);

    useEffect(() => {
        triggerElementFieldTypes({});
    }, []);

    useEffect(() => {
        setShowDialog(props.showEdit);
    }, [props.showEdit]);

    useEffect(() => {
        setGlobalElementTypes([]);
        if (selectedProjectTypeId) {
            triggerGlobalElementTypes({
                locale: 'de',
                projectTypeId: selectedProjectTypeId
            });
        }
    }, [selectedProjectTypeId]);


    const getFormErrorMessage = (name: string) => {
        if (name.indexOf('.') >= 0) {
            const split = name.split('.');

            if (split.length === 3) {
                // @ts-ignore
                if (errors[split[0]] && errors[split[0]][split[1]] && errors[split[0]][split[1]][split[2]]) {
                    // @ts-ignore
                    const text = errors[split[0]][split[1]][split[2]].message;

                    return <small className="p-error custom">{text}</small>;
                }
            }
        } else {
            // @ts-ignore
            if (errors[name]) {
                // @ts-ignore
                const text = errors[name].message;

                return <small className="p-error custom">{text}</small>;
            }
        }
        return undefined;
    };

    const nameFieldLabelValidation = (t: any) => {
        return {
            pattern: {
                value: /^[a-zA-ZäöüÄÖÜ_]*$/,
                message: t('input:invalidNameFieldLabel')
            }
        };
    };

    const onEditClick = useCallback((data: Project_ElementFieldType) => {
        setSelectedItem(data);
        reset(data);
        setShowDialog(true);
    }, []);

    const onDeleteClick = useCallback(() => {

    }, []);

    const hideDialog = () => {
        setShowDialog(false);
        setTimeout(() => {
            setSelectedItem(undefined);
            reset(defaultValues);
            props.setShowEdit(false);
        }, 200);
    };

    const onSave = (data: Project_ElementFieldType) => {
        if (selectedItem) {
            updateProjectElementFieldType(data).then((res: any) => {
                if (!res.error) {
                    showMessageOnSuccess(t('success'), t('saved'));
                    triggerElementFieldTypes({});
                    hideDialog();
                }
            });
        } else {
            addProjectElementFieldType([data]).then((res: any) => {
                if (!res.error) {
                    showMessageOnSuccess(t('success'), t('saved'));
                    triggerElementFieldTypes({});
                    hideDialog();
                }
            });
        }
    };

    return (
        <>
            <CustomDataTable id={'project_element_field_types'} headerTitle={t('element_field_types')}
                             columns={getProjectElementFieldTypesColumns(t, {
                                 projectTypes: projectTypes.data,
                                 elementTypes: elementTypes.data
                             })}
                             filters={getElementFieldTypesFilters()}
                             editable={true}
                             sortable={true}
                             paginator={true}
                             value={elementFieldTypesResult.data} dataloaded={elementFieldTypesResult.data == null}
                             ableToUpdate={true}
                             onEditClick={onEditClick}
                // ableToDelete={true}
                             onRemoveClick={onDeleteClick}/>
            <CustomDialog
                header={selectedItem ?
                    <div className='flex'><i className='pi pi-pencil my-auto mr-2'/>{t('element_field_type_edit')}
                    </div> : <div className='flex'><i className='pi pi-plus my-auto mr-2'/>{t('element_field_type_new')}
                    </div>}
                visible={showDialog}
                onCancel={hideDialog} formName={'elementFieldTypeForm_New'} style={{maxWidth: '800px'}}>
                <form id='elementFieldTypeForm_New' onSubmit={handleSubmit(onSave)}>
                    <div className="card">
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <ProjectTypeSelectList
                                    setValue={() => {
                                        setValue('element_type_id', 0);
                                    }}
                                    disabled={selectedItem != null}
                                    label={t('projM_projectTypeName')}
                                    name={`project_type_id`}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('input_required')}}
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <ProjectElementTypeSelectList
                                    disabled={selectedItem != null}
                                    locale={user.language_id}
                                    projectTypeId={selectedProjectTypeId?.toString() || ''}
                                    label={t('elementTypeName')}
                                    name={`element_type_id`}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('input_required')}}
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <Input
                                    edit={true}
                                    label={t('elementFieldTypeName')}
                                    name={`name`}
                                    type={'text'}
                                    tooltip={t('projM_tooltip_translation')}
                                    labelIcon={<FontAwesomeIcon className='sidebar-icon' icon={faLanguage}
                                                                onClick={() => {
                                                                    CustomConfirmDialog({
                                                                        message: t('typeM_goToTranslationTool'),
                                                                        header: t('typeM_goToTranslationTool_header'),
                                                                        translation: t,
                                                                        onConfirm: () => {
                                                                            navigate('/#languageManagement');
                                                                        },
                                                                    });
                                                                }}/>}
                                    labelIconTooltip={t('translation_icon_tooltip')}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{...{required: t('input_required'), ...nameFieldLabelValidation(t)}}}

                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <Input
                                    edit={true}
                                    label={t('count')}
                                    name={`count`}
                                    type={'text'}
                                    tooltip={t('projM_tooltip_count')}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('input_required')}}

                                />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <ProjectElementFieldTypeData
                                    label={t('data_type')}
                                    name={`data_type`}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('input_required')}}
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <ProjectElementFieldTypeObject
                                    label={t('object_type')}
                                    name={`object_type`}
                                    tooltip={t('projM_tooltip_objectType')}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('input_required')}}
                                />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <Input
                                    edit={true}
                                    label={t('check_value')}
                                    name={`check_value`}
                                    type={'text'}
                                    tooltip={t('projM_tooltip_checkValue')}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <ProjectElementFieldSubtype
                                    projectElementTypeId={selectedElementTypeId?.toString() || ''}
                                    locale={user.language_id}
                                    name={'element_subtype_id'}
                                    label={t('element_field_subtype')}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                {(selectedObjectType === 'dropdown' || selectedObjectType === 'multiselect') && (
                                    <ElementFieldTypeApiCallList
                                        label={t('api_call')}
                                        name={`api_call`}
                                        validationControl={control}
                                        validationErrorMessage={getFormErrorMessage}
                                        validationRules={{required: false}}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </>
    );
};
