import React, { useState, useEffect } from 'react';
import { useLazyGetElementTypesGlobalQuery, useLazyGetElementSubtypesQuery } from '../../redux/rtk/injectedProjectTypes';
import Input from '../global/Input';

type Props = {
    projectTypeId: string,
    locale?: string,
    label: string,
    name: string,
    value?: string;
    setValue?: (e: any) => void,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
}

const ProjectElementTypeSelectList = (props: Props): JSX.Element => {
    const [list, setList] = useState([]);
    const [triggerElementTypes, resultElementTypes] = useLazyGetElementTypesGlobalQuery();
    const [triggerElementSubtypes] = useLazyGetElementSubtypesQuery();

    useEffect(() => {
        if (props.projectTypeId && props.locale) {
            triggerElementTypes({ locale: props.locale, projectTypeId: props.projectTypeId });
        }
    }, [props.projectTypeId, props.locale]);

    useEffect(() => {
        if (resultElementTypes.data) {
            setList(resultElementTypes.data.map((item: any) => ({ value: item.element_type_id, label: item.name })));
        }
    }, [resultElementTypes]);

    const handleChange = (e: any) => {
        if (props.setValue) {
            props.setValue(e.value);
            triggerElementSubtypes({ locale: props.locale, element_type_id: e.value });
        }
    };

    console.log(props.value);

    return (
        <Input
            name={props.name}
            edit={true}
            disabled={props.disabled}
            label={props.label}
            value={props.value}
            dropdownOptions={list}
            scrollHeight={'350px'}
            type={'dropdown'}
            onChange={handleChange}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
            selectionMode={'checkbox'}
        />
    );
};

export default ProjectElementTypeSelectList;
