import React, {useEffect, useState} from 'react';
import {ElementTypeItem, getElementType, UnitItem} from "../functions/Units";
import Input from "../global/Input";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {addApiCalls, removeApiCalls} from "../../redux/slices/ApiCallsSlice";
import {changeField} from "../../redux/slices/ElementChangesSlice";
import {useTranslation} from "react-i18next";
import {useLazyGetCustomerTypesQuery} from "../../redux/rtk/injectedUnitsApi";
import {ElementFileManagement} from "./ElementFileManagement";
import {Button} from "primereact/button";
import {confirmDialog} from "primereact/confirmdialog";
import {checkPermission} from "../../functions/functionLibrary";
import {permissions} from "../../config/permissions";


export enum UnitFieldType {
    element = 'element',
    inspectionTask = 'inspection_task',
    inspection = 'inspection'
}

type Props = {
    project: any,
    fields: any;
    id: string,
    type: UnitFieldType,
    className?: string;
    showOnly?: boolean,
    form?: any,
    validationErrorMessage?: (name: string) => void,
    editable?: boolean
}

export const UnitFields = (props: Props) => {
    const {t} = useTranslation(['common']);

    const apiCalls: any = useAppSelector((state: any) => state.apiCallsData.apiCalls);
    const dispatch = useAppDispatch();
    const [triggerGetCustomerTypes, resultCustomerTypes, lastPromiseCustomerTypes] = useLazyGetCustomerTypesQuery();

    const [fields, setFields] = useState<any>(props.fields);
    const [result, setResult] = useState<any[]>([]);

    const [multiSelectAdd, setMultiSelectAdd] = useState<any>({});
    const [dropdownAdd, setDropdownAdd] = useState<any>({});

    useEffect(() => {
        setFields(props.fields);
    }, [JSON.stringify(props.fields)]);

    useEffect(() => {
        if (fields) {
            (async () => {
                const n = await getFields();

                Promise.all(n).then((result) => {
                    setResult(result);
                });
            })();
        }
    }, [JSON.stringify(fields)]);

    // useEffect(() => {
    //     if (values) {
    //         setResult((prevState) => {
    //             let temp = [...prevState];
    //
    //             for (let i = 0; i < temp.length; i++) {
    //                 let tempItem = temp[i];
    //
    //                 if (values[tempItem.name] != null) {
    //                     tempItem.value = values[tempItem.name];
    //                 }
    //             }
    //
    //             return temp;
    //         });
    //     }
    // }, [values]);

    const getFields = async () => {
        const result = [];

        if (fields) {
            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];
                if (field) {
                    let type = 'text';
                    let dropdownOptions: any[] = [];
                    let value = field.value ? field.value : '';

                    switch (field.object_type) {
                        case 'textarea':
                            type = 'textarea';
                            break;
                        case 'number':
                            type = 'number';
                            break;

                        case 'boolean':
                            type = 'checkbox';
                            break;

                        case 'file':
                            type = 'file';
                            break;

                        case 'date':
                            type = 'date';

                            if (value) {
                                value = new Date(value);
                            }
                            break;

                        case 'dropdown':
                        case 'dropdown_edit':
                        case 'multiselect':
                        case 'multiselect_edit':
                            type = field.object_type;

                            if (field.api_call && apiCalls[field.api_call]) {
                                dropdownOptions = apiCalls[field.api_call];
                            } else {
                                dropdownOptions = await triggerGetCustomerTypes({
                                    customerType: field.api_call,
                                    projectId: props.project.id
                                }).unwrap()
                                    .then((result: any) => {
                                        const returnResult =
                                            result.map((item: any) => {
                                                if (field.object_type.includes('_edit')) {
                                                    return {value: item.name, label: item.name};
                                                } else {
                                                    return {value: item.id, label: item.name};
                                                }
                                            });

                                        // if (field.api_call) {
                                        //     if (!apiCalls[field.api_call]) {
                                        //         dispatch(addApiCalls({key: field.api_call, value: returnResult}));
                                        //     }
                                        // }

                                        return returnResult;
                                    });
                            }

                            if (type.includes('multiselect')) {
                                // if (value && typeof value === 'string') {
                                //     value = JSON.parse(value);
                                // }
                                if (!props.showOnly) {
                                    if (value && typeof value === 'string') {
                                        value = value.replace('[', '').replace(']', '').split(',').map((item: any) => {
                                            if (type.includes('_edit')) {
                                                return item;
                                            } else {
                                                return parseInt(item);
                                            }
                                        });
                                    }
                                }
                            } else {
                                if (props.showOnly) {
                                    let find = dropdownOptions.find((item: any) => {
                                        return item.value === value
                                    });

                                    if (find) {
                                        value = find.map((item: any) => {
                                            return item.label
                                        });
                                    }

                                } else {
                                    if (!type.includes('_edit')) {
                                        value = parseInt(value);
                                    }
                                }
                            }

                            break;
                        // case 'multiselect_edit':
                        //     type = field.object_type;
                        //
                        //     if (field.api_call && apiCalls[field.api_call]) {
                        //         dropdownOptions = field.name === 'schweisser_no_1' ? [
                        //             {label: '1', value: '1'},
                        //             {label: '2', value: '2'},
                        //             {label: '3', value: '3'}] : apiCalls[field.api_call];
                        //     } else {
                        //         dropdownOptions = field.name === 'schweisser_no_1' ? [
                        //             {label: '1', value: '1'},
                        //             {label: '2', value: '2'},
                        //             {label: '3', value: '3'}
                        //         ] : await triggerGetCustomerTypes({
                        //             customerType: field.api_call
                        //         }).unwrap()
                        //             .then((result: any) => {
                        //                 const returnResult =
                        //                     result.map((item: any) => {
                        //                         return {value: item.id, label: item.name};
                        //                     });
                        //
                        //                 if (field.api_call) {
                        //                     if (!apiCalls[field.api_call]) {
                        //                         dispatch(addApiCalls({key: field.api_call, value: returnResult}));
                        //                     }
                        //                 }
                        //
                        //                 return returnResult;
                        //             });
                        //     }
                        //     break;
                    }

                    let temp = {
                        api_call: field.api_call,
                        value: value,
                        label: field.display_name,
                        name: field.name,
                        type: type,
                        fieldName: field.name,
                        dropdownOptions: dropdownOptions,
                        showTime: false,
                        className: field.className,
                        rows: value && typeof value === 'string' ? value.split(/\r\n|\r|\n/).length : 2,
                        timeForKey: new Date().getTime(),
                        validationRules: field.validationRules || [{required: false}],
                    }

                    if (props.id || type !== 'file') {
                        result.push(temp);
                    }
                }
            }
        }
        return result;
    };

    const onChangeField = (value: any, fieldName: string) => {
        dispatch(changeField({
            element_id: props.type === UnitFieldType.element ? props.id : undefined,
            inspection_task_id: props.type === UnitFieldType.inspectionTask ? props.id : undefined,
            inspection_id: props.type === UnitFieldType.inspection ? props.id : undefined,
            key: fieldName,
            value: value
        }));

        setResult((prevState) => {
            let temp = [...prevState];

            for (let i = 0; i < temp.length; i++) {
                let tempItem = temp[i];

                if (tempItem.name === fieldName) {
                    tempItem.value = value;
                }
            }

            return temp;
        });

        // setValues((prevState: any) => {
        //     const temp = JSON.parse(JSON.stringify(prevState));
        //     return {...temp, [fieldName]: value};
        // });
    };

    return <>{props.showOnly && <div>
        {result && result.map((itemResult: any) => {
            switch (itemResult.type) {
                // case 'file':
                //     return <div className={props.className}>
                //         <ElementFileManagement project={props.project} id={props.id} type={props.type}
                //                                editable={false}
                //                                header={itemResult.label}
                //                                setFilesCount={() => {
                //                                }} showPreview={false} skeletonCount={1}/></div>;

                case 'checkbox':
                    return <div className={'grid' + (props.className ? ' ' + props.className : '')}>
                        <div className={'col'}>{itemResult.label}</div>
                        <div className={'col flex justify-content-end font-bold'}>{itemResult.value ?
                            <i className={'pi pi-check-square'}/> : <i className={'pi pi-stop'}/>}</div>
                    </div>

                default:
                    return <div className={'grid' + (props.className ? ' ' + props.className : '')}>
                        <div className={'col'}>{itemResult.label}</div>
                        <div
                            className={'col flex justify-content-end font-bold'}>{itemResult.value ? itemResult.value : '-'}</div>
                    </div>
            }
        })}
    </div>}
        {!props.showOnly && result && result.map((itemResult: any, index: number) => {
            switch (itemResult.type) {
                case 'multiselect_edit': {
                    return <div className={'col-12'}>
                        <div className={'grid edit_list'}>
                            <div className={'col edit_list_col1'}>
                                <Input edit={props.editable === false ? props.editable : checkPermission(permissions.editElements)}
                                       className={'no-field-margin'}
                                       name={itemResult.fieldName}
                                       value={props.form ? undefined : itemResult.value}
                                       label={itemResult.label}
                                       type={'multiselect'}
                                       dropdownOptions={itemResult.dropdownOptions}
                                       showClear={true}
                                       onChange={(e) => {
                                           if (fields) {
                                               if (itemResult.type === 'number') {
                                                   onChangeField(e, itemResult.fieldName);
                                               } else {
                                                   onChangeField(e.target.value || '', itemResult.fieldName);
                                               }
                                           }
                                       }}
                                       options={{
                                           virtualScrollerOptions: {itemSize: 29}
                                       }}
                                       validationControl={props.form ? props.form.control : undefined}
                                       validationErrorMessage={props.validationErrorMessage}
                                       validationRules={itemResult.validationRules}
                                />
                            </div>
                            <div className={'col-fixed flex edit_list_col2 mt-auto'}>
                                <Button className={'p-button h-full'} icon={'pi pi-angle-double-left'}
                                        disabled={!multiSelectAdd[itemResult.fieldName + '_add']}
                                        onClick={() => {
                                            let value = multiSelectAdd[itemResult.fieldName + '_add'];
                                            if (value) {

                                                let found = itemResult.dropdownOptions.find((item: any) => {
                                                    return item.value === value;
                                                });
                                                if (!found) {
                                                    itemResult.dropdownOptions = [...itemResult.dropdownOptions, {
                                                        value: value,
                                                        label: value
                                                    }];
                                                }
                                                let temp: string[] = [...itemResult.value];
                                                temp.push(value);
                                                onChangeField(temp, itemResult.fieldName);

                                                setMultiSelectAdd((prevState: any) => {
                                                    let temp = {...prevState};

                                                    temp[itemResult.fieldName + '_add'] = '';

                                                    return temp;
                                                });

                                                dispatch(removeApiCalls({key: itemResult.api_call}));
                                            }
                                        }}></Button>
                                <Input edit={props.editable === false ? props.editable : checkPermission(permissions.editElements)}
                                       className={'no-field-margin'}
                                       name={itemResult.fieldName + '_add'}
                                       value={multiSelectAdd[itemResult.fieldName + '_add']}
                                       placeholder={t('add')}
                                       onChange={(e) => {
                                           setMultiSelectAdd((prevState: any) => {
                                               let temp = {...prevState};

                                               temp[itemResult.fieldName + '_add'] = e.target.value;

                                               return temp;
                                           });
                                       }}/>
                            </div>
                        </div>
                    </div>
                }
                // case 'file':
                // return <div className={props.className}>
                // <ElementFileManagement project={props.project} id={props.id} type={props.type}
                //                                editable={true}
                //                                header={itemResult.label}
                //                                setFilesCount={() => {
                //                                }} showPreview={false} skeletonCount={1}/></div>;

                case 'dropdown_edit': {
                    return <div className={'col-12'}>
                        <div className={'grid edit_list'}>
                            <div className={'col edit_list_col1'}>
                                <Input edit={props.editable === false ? props.editable : checkPermission(permissions.editElements)}
                                       className={'no-field-margin'}
                                       name={itemResult.fieldName}
                                       value={props.form ? undefined : itemResult.value}
                                       label={itemResult.label}
                                       type={'dropdown'}
                                       dropdownOptions={itemResult.dropdownOptions}
                                       showClear={true}
                                       onChange={(e) => {
                                           if (fields) {
                                               if (itemResult.type === 'number') {
                                                   onChangeField(e, itemResult.fieldName);
                                               } else {
                                                   onChangeField(e.target.value || '', itemResult.fieldName);
                                               }
                                           }
                                       }}
                                       options={{
                                           virtualScrollerOptions: {itemSize: 29}
                                       }}
                                       validationControl={props.form ? props.form.control : undefined}
                                       validationErrorMessage={props.validationErrorMessage}
                                       validationRules={itemResult.validationRules}/>
                            </div>
                            <div className={'col-fixed flex edit_list_col2 mt-auto'}>
                                <Button className={'p-button h-full'} icon={'pi pi-angle-double-left'}
                                        disabled={!dropdownAdd[itemResult.fieldName + '_add']}
                                        onClick={() => {
                                            let value = dropdownAdd[itemResult.fieldName + '_add'];
                                            if (value) {

                                                let found = itemResult.dropdownOptions.find((item: any) => {
                                                    return item.value === value;
                                                });
                                                if (!found) {
                                                    itemResult.dropdownOptions = [...itemResult.dropdownOptions, {
                                                        value: value,
                                                        label: value
                                                    }];
                                                }
                                                let temp: string = value;

                                                onChangeField(temp, itemResult.fieldName);

                                                setDropdownAdd((prevState: any) => {
                                                    let temp = {...prevState};

                                                    temp[itemResult.fieldName + '_add'] = '';

                                                    return temp;
                                                });

                                                dispatch(removeApiCalls({key: itemResult.api_call}));

                                                if (props.form) {
                                                    props.form.setValue(itemResult.fieldName, value);
                                                }
                                            }
                                        }}></Button>
                                <Input edit={props.editable === false ? props.editable : checkPermission(permissions.editElements)}
                                       className={'no-field-margin'}
                                       name={itemResult.fieldName + '_add'}
                                       value={dropdownAdd[itemResult.fieldName + '_add']}
                                       placeholder={t('add')}
                                       onChange={(e) => {
                                           setDropdownAdd((prevState: any) => {
                                               let temp = {...prevState};

                                               temp[itemResult.fieldName + '_add'] = e.target.value;

                                               return temp;
                                           });
                                       }}/>
                            </div>
                        </div>
                    </div>
                    // return <div className={itemResult.className ?? props.className}>
                    //     <Input key={itemResult.fieldName + '_' + itemResult.timeForKey}
                    //            edit={props.editable === false ? props.editable : checkPermission(permissions.editElements)}
                    //            name={itemResult.fieldName}
                    //            value={itemResult.value}
                    //            label={itemResult.label} type='dropdown'
                    //            options={{editable: true}}
                    //            dropdownOptions={itemResult.dropdownOptions}
                    //            showClear={true}
                    //            rows={itemResult.rows}
                    //            showTime={itemResult.showTime}
                    //            maxFractionDigits={4}
                    //            onChange={(e) => {
                    //                if (fields) {
                    //                    if (itemResult.type === 'number') {
                    //                        onChangeField(e, itemResult.fieldName);
                    //                    } else {
                    //                        onChangeField(e.target.value || '', itemResult.fieldName);
                    //                    }
                    //                    dispatch(removeApiCalls({key: itemResult.api_call}));
                    //                }
                    //            }}/></div>;
                }

                default:
                    return <div className={itemResult.className ?? props.className}>
                        <Input key={itemResult.fieldName + '_' + itemResult.timeForKey}
                               edit={props.editable === false ? props.editable : checkPermission(permissions.editElements)}
                               name={itemResult.fieldName}
                               value={props.form ? undefined : itemResult.value}
                               label={itemResult.label} type={itemResult.type}
                               dropdownOptions={itemResult.dropdownOptions}
                               showClear={true}
                               rows={itemResult.rows}
                               showTime={itemResult.showTime}
                               maxFractionDigits={4}
                               onChange={(e) => {
                                   if (fields) {
                                       if (itemResult.type === 'number') {
                                           onChangeField(e, itemResult.fieldName);
                                       } else {
                                           onChangeField(e.target.value || '', itemResult.fieldName);
                                       }
                                   }
                               }}
                               validationControl={props.form ? props.form.control : undefined}
                               validationErrorMessage={props.validationErrorMessage}
                               validationRules={itemResult.validationRules}
                        /></div>
            }
        })}
    </>
}
