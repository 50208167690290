import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';

import MapContext from '../map/MapContext';
import Select, {SelectEvent} from 'ol/interaction/Select.js';
import {clusterBaseStyle, getClusterStyle, getFeatureView, getLayerById} from '../../functions/Geo';
import {useAppSelector} from "../../../redux/hooks";

type props = {layerId:any, poiLayer:string};
const PoiClusterInteraction: FunctionComponent<props> = ({layerId, poiLayer}) => {
    // @ts-ignore
    const {map} = useContext(MapContext);
    const [clusterOverlayLayer, setClusterOverlayLayer] = useState({});
    const [overlaySource, setClusterOverlaySource] = useState({
        clear() {
        },
        addFeature(feature: any) {
        }
    });

    const refClusterOverlayLayer = useRef(clusterOverlayLayer);
    const refClusterOverlaySource = useRef(overlaySource);

    const getOverlayLayer = () => {
        getLayerById(map, layerId).then(layerById => {
            if (layerById.found == true) {
                // @ts-ignore
                setClusterOverlayLayer(layerById.layer);
                // @ts-ignore
                setClusterOverlaySource(layerById.layer.getSource());
                // @ts-ignore
                refClusterOverlayLayer.current = layerById.layer;
                // @ts-ignore
                refClusterOverlaySource.current = layerById.layer.getSource();
            }
        });
    };

    const selectFunction = (selected: any) => {

        const overlaySource = refClusterOverlaySource.current;
        if (selected && selected.values_.features) {
            overlaySource.clear();

            const features = selected.values_.features;
            const mainCoordinates = selected.getGeometry().getCoordinates();
            getFeatureView(features, mainCoordinates, map, refClusterOverlaySource.current);
        }
    };

    const getInteraction = () => {
        getOverlayLayer();
        // DrawingLayer

        const select = new Select({
            // @ts-ignore
            filter: function (feature: any | undefined, layer: any | undefined) {
                if (layer && layer.get('id') === poiLayer && (Object.keys(feature.values_)).indexOf('features') >= 0 && feature.get('id') !== 'shadow-layer') {

                    return true;
                }
            },
            condition: function (evt) {
                return evt.type === 'singleclick';
            },
            style:clusterBaseStyle

        });

        select.on('select', function (evt: any) {
            let selected: any = null;

            if ((evt instanceof SelectEvent)) {
                if (evt && evt.selected && evt.selected[0]) {
                    selected = evt.selected[0];
                }
            }

            if (selected) {
                const id = selected.getId();
                if (id && selected.get('layerKind') === poiLayer) {
                } else {
                    selectFunction(selected);
                }

            }
        });
        return select;
    };

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        const selectInteraction = getInteraction();
        getOverlayLayer();
        map.addInteraction(selectInteraction);
        map.getViewport().addEventListener('contextmenu', function () {
            if (refClusterOverlayLayer.current) {
                refClusterOverlaySource.current.clear();
                selectInteraction.getFeatures().clear();
            }
        });

        return () => map.interactions.remove(selectInteraction);
    }, [map]);

    return null;
};

export default PoiClusterInteraction;
