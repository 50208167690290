import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import MapContext from '../map/MapContext';
import {OverlayPanel} from 'primereact/overlaypanel';
import {useTranslation} from 'react-i18next';
import {useAppSelector, useAppDispatch} from '../../../redux/hooks';
import {generateImageForLegend, getLegendControllArray} from '../../../functions/functionsOpenlayer';
import settings from '../../../config/settings';
import geoSettings from '../../../config/geoSettings';


type legendProps = {
    projectId: any
}

const LegendControl: FunctionComponent<legendProps> = (projectId) => {
    // @ts-ignore
    const {map} = useContext(MapContext);
    const {t} = useTranslation(['geomonitoring']);
    const op = useRef(null);
    const layerArray = useAppSelector((state: any) => state.configLayer.layerData.layerArray);
    const styleArraySet = useAppSelector((state: any) => state.configLayer.layerData.styleArraySet);
    const styleArray = useAppSelector((state: any) => state.configLayer.layerData.styleArray);
    const layerCounter = useAppSelector((state: any) => state.configLayer.layerData.layerCounterArray);
    const geoserverWorkspace = useAppSelector((state: any) => state.configLayer.layerData.geoserverWorkspace);
    const externLegend = useAppSelector((state: any) => state.configLayer.layerData.externLegendArray);
    const externLegendPic = useAppSelector((state: any) => state.configLayer.layerData.externLegendPic);
    const changedLayer = useAppSelector((state: any) => state.configLayer.layerData.changedLayer);
    const mpTypesArray = useAppSelector((state: any) => state.configLayer.layerData.mpTypes);
    const filterMp = useAppSelector((state: any) => state.configLayer.layerData.filterMp);
    const baseWmsWorkspace = useAppSelector((state: any) => state.configLayer.layerData.baseLayerWorkspace);
    const [legendContent, setLegendContent]: any = useState([]);
    const [toogleAllowed, setToogleAllowed] = useState(false);
    const toogleRef = useRef(toogleAllowed);
    const [changedArray, setChangedArray]: any = useState([]);
    const refChanged = useRef(changedArray);
    const createLegendButton = () => {
        const buttonLegendMap = document.createElement('button');
        buttonLegendMap.id = 'buttonLegend';
        buttonLegendMap.title = t('geomon_legendTitle');
        buttonLegendMap.innerHTML = '<span style="color: black"><i class="fas fa-layer-group"/></span>';
        buttonLegendMap.addEventListener('click', openDropdown, false);
        return buttonLegendMap;
    };

    const openDropdown = (e: any) => {
        // @ts-ignore
        op.current.toggle(e);
        iterateAndToogleDivs();
        toogleFnc(true);
    };

    const toogleFnc = (val: boolean) => {
        setToogleAllowed(val);
        toogleRef.current = val;
    };

    const addControl = () => {

        const legendElem = document.getElementById('buttonLegend');
        if (legendElem === null) {
            const buttonLegendMap = createLegendButton();
            const elementLegende = document.getElementById('olControls');
            elementLegende?.appendChild(buttonLegendMap);
        }
        return [legendElem];
    };

    const getLegendImage = (layer: any) => {
        const mainLayerId = layer.mainLayerId;
        const styleId = layer.id;
        const styleIdUnformated = layer.styleId;
        const layerId = styleId.toLowerCase();
        const image = generateImageForLegend(geoserverWorkspace, mainLayerId, externLegend[layerId], styleIdUnformated, styleId, layerId, externLegendPic[layerId], baseWmsWorkspace);
        return (image);
    };

    const addLayerLegendPics = () => {
        layerArray.forEach((layer: any) => {
            if (typeof layer.layerConfig !== 'undefined' && layer.layerConfig !== null) {
                const image = getLegendImage(layer);
                addLegendToDiv(image, layer, 'legend-image');
            }
        });
    };

    const sortLegendDivs = () => {
        const parent = document.getElementById('map_legende');
        if (parent !== null) {
            let toSort: any = parent.children;

            toSort = Array.prototype.slice.call(toSort, 0);
            toSort.sort(function (a: { id: string; getAttribute: (arg0: string) => string | number; }, b: {
                    getAttribute: (arg0: string) => string | number;
                }) {
                if (a.id === 'legendHeader') {
                    return -1;
                }
                const aord = +a.getAttribute('data-value');
                const bord = +b.getAttribute('data-value');
                return (aord < bord) ? 1 : -1;
            });

            parent.innerHTML = '';

            for (let i = 0, l = toSort.length; i < l; i++) {
                parent.appendChild(toSort[i]);
            }
        }

    };

    const getLegendElemTitle = (layer: {
            mainLayerId: string;
            id: string;
            title: any;
            styles: {};
            mp: any;
            layerConfig: { layerName: any; };
        }) => {
        const mainLayerId = layer.mainLayerId ? layer.mainLayerId.toLowerCase() : layer.id;
        const styleData = styleArray.hasOwnProperty(mainLayerId) ? styleArray[mainLayerId][layer.id.toLowerCase()] : null;
        let legendTitle = '';

        switch (true) {
            case (layer.mp):
                legendTitle = layer.layerConfig.layerName;
                break;
            case (layer.styles && Object.keys(layer.styles).length > 1):
                legendTitle = styleData.name;
                break;
            default:
                legendTitle = layer.title;
                break;
        }
        return legendTitle;
    };

    const addLegendToDiv = (image: string | boolean | void | JSX.Element, layer: any, className: string) => {
        const divId = 'imageDiv_' + layer.id;
        const dataValue = layer.layerParts ? layer.layerParts.generatedIndex : '0';
        const legendTitle = getLegendElemTitle(layer);

        const singleLegendDiv = (
            <div id={divId} key={divId} className={'pt-2'} style={{display: (layer.visible ? 'block' : 'none')}}
                data-value={dataValue}>
                {legendTitle} <br/>
                <div className={className}>{image}</div>
            </div>
        );
        setLegendContent((legendContent: any) => [...legendContent, singleLegendDiv]);
    };

    const iterateAndToogleDivs = () => {
        const objectKeysLayer = Object.keys(refChanged.current);
        if (objectKeysLayer.length > 0) {
            refChanged.current.forEach((layer: any) => {
                toogleDiv(layer.id, layer.visible);
            });
            // sortLegendDivs();
        }
        sortLegendDivs();
    };

    const toogleDiv = (layerId: string, visibility: any) => {
        const legendDiv = document.getElementById('imageDiv_' + layerId);
        if (legendDiv) {

            legendDiv.style.display = visibility ? 'block' : 'none';
        }
    };

    const contextMenuListener = () => {
        map.getViewport().addEventListener('contextmenu', function (evt: { preventDefault: () => void; }) {
            if (toogleRef.current) {
                // @ts-ignore
                op.current.toggle({currentTarget: null});
                toogleFnc(false);
            }
        });
    };

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        const legendControl = addControl();
        contextMenuListener();
        return () => map.controls.remove(legendControl);
    }
    , [map]);

    useEffect(() => {
        if (!layerArray || Object.keys(layerArray).length === 0) return;
        const count = layerCounter.reduce((count: number, num: number) => num === projectId.projectId ? count + 1 : count, 0);
        if (geoSettings.counterLayers === count && styleArraySet) {
            addLayerLegendPics();
        }
    }, [layerArray, layerCounter, styleArraySet]);

    useEffect(() => {
        const mergedArray = getLegendControllArray(refChanged.current, changedLayer);
        setChangedArray(mergedArray);
        refChanged.current = mergedArray;
        iterateAndToogleDivs();
    }, [changedLayer]);

    useEffect(() => {
        if (!filterMp || Object.keys(filterMp).length === 0) return;

        filterMp.forEach((feature: any) => {
            const status = feature.value ? feature.value.checked : false;
            toogleDiv(feature.mpType, status);
        });

    }, [filterMp]);


    const mapELem = document.getElementsByClassName('ol-viewport');

    return (// @ts-ignore
        <OverlayPanel className={'layer-legend'} ref={op} showCloseIcon={true} dismissable={false} appendTo={mapELem[0]}>
            <div id="map_legendeParent" className="mapLegendParentDiv">
                <div className="mapLegendDiv" data-value="100000000" id="legendHeader">{t('geomon_legendTitle')}</div>
                <div id="map_legende">{legendContent}</div>
            </div>
        </OverlayPanel>

    );
}
;

export default LegendControl;
