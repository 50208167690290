import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from 'primereact/button';

export const UnderConstruction = (props: any) => {
    const navigate = useNavigate();

    const goDashboard = () => {
        navigate('/');
    };

    return (
        <div className="exception-body underconstruction">
            <div className="exception-panel">
                <div className='flex relative'>
                    <i className='pi pi-exclamation-triangle'></i>
                    <i className='icon-background absolute'></i>
                </div>
                <h1>UNDER</h1>
                <h3>construction</h3>
                <p>Bitte versuchen Sie es später erneut.</p>
                {/*<Button type="button" label="Go back to home" onClick={goDashboard}></Button>*/}
            </div>
        </div>
    );
};
