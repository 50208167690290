import {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import MapContext from '../map/MapContext';
import {Vector as VectorSource} from 'ol/source';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import geoSettings from '../../../config/geoSettings';
import {useLazyGetPolylineDataQuery} from '../../../redux/rtk/injectedGeoApi';
import {
    createPoiFeatures,
    createPolylineFeatures, getColorByStatePolyLine, getLayerById,
    getPolyLineBottomStyle,
    getPolyLinePointsStyle,
    getPolyLineTopStyle,
    getPolyLineUpperStyle, getScaleValue
} from '../../functions/Geo';
import {
    addLayerToCounterArray,
    setDataLoaded,
    setLayerArray,
    setPolylineCluster
} from '../../../redux/slices/GeomonitoringSlice';
import {getVectorLayerObject, scaleElem} from '../../../functions/functionsOpenlayer';
import {unByKey} from "ol/Observable";
import {getVectorContext} from "ol/render";
import {easeOut} from "ol/easing";
import {Stroke, Style} from "ol/style";
import CircleStyle from "ol/style/Circle";

type poiProps = {
    projectId: number,
    itemId?: any,
    zoomMap: number,
    projectType: string
}

const PolylineLayer: FunctionComponent<poiProps> = ({projectId, itemId, zoomMap, projectType}) => {
    // @ts-ignore
    const {map, setMap} = useContext(MapContext);
    const [zoom, setZoom] = useState(zoomMap);
    const dispatch = useAppDispatch();
    const [vLayer, setVlayer] = useState(null);
    const [triggerPolyline, {
        data: resultPolylineData,
        isPolylineLoading,
        isPolylineError,
        isSuccess
    }] = useLazyGetPolylineDataQuery();

    const [nSource, setNSource] = useState({
        removeFeature(feature: any) {
        },
        addFeature(mp: any) {
        },
        clear() {
        },
        addFeatures(features: any[]) {
        },
        getFeatures() {

        }
    });
    const refVLayer = useRef(vLayer);
    const refNSource = useRef(nSource);
    const dataChanges: any[] = useAppSelector((state: any) => state.configLayer.layerData.reloadData);

    const [clusterOverlayLayer, setClusterOverlayLayer] = useState({});
    const [selectedFeature, setSelectedFeature] = useState();
    const refSelectedFeature = useRef(selectedFeature);
    const [overlaySource, setClusterOverlaySource] = useState({
        clear() {
        },
        addFeature(feature: any) {
        },
        addFeatures(features: any[]) {
        },
        getFeatures() {

        }
    });

    const refClusterOverlayLayer = useRef(clusterOverlayLayer);
    const refClusterOverlaySource = useRef(overlaySource);

    const infoArray = document.getElementsByClassName('tooltip_info');
    const info: any = infoArray[0];

    const clearStyle = () => {
        if (refSelectedFeature.current) { // @ts-ignore
            refSelectedFeature.current.setStyle(null);
            // console.log('style nulled')
        }
    }

    const clearSelectedFeature = () => {
        clearStyle();
        setSelectedFeature(undefined);
    }

    const displayFeatureInfo = function (pixel: string[], target: { closest: (arg0: string) => any; }) {
        const scaleValue = getScaleValue(map.getView().getZoom());
        const feature = map.forEachFeatureAtPixel(pixel, function (feature: any) {
            return feature;
        });
        if (feature&&(feature.getId()!=='shadow-layer')) {
            const style = getPolyLinePointsStyle(scaleValue / 0.5, true, feature);
            info.style.left = (Number(pixel[0]) + 5) + 'px';
            info.style.top = (Number(pixel[1]) - 30) + 'px';
            if (feature.get('element_type_id') === 10) {
                clearSelectedFeature();
                info.style.visibility = 'visible';
                info.style.backgroundColor = getColorByStatePolyLine(feature.get('status'));
                info.style.color = 'black';
                info.style.border = '1px solid black';
                info.innerText = feature.get('name');
                if(feature.get('cluster')==false)feature.setStyle(style);
                refSelectedFeature.current = feature;
                // animateScale(feature)
            } else if (feature && feature.get('element_type_id') !== 10) {
                clearSelectedFeature();
            } else {
                clearStyle();
            }
        } else {
            clearSelectedFeature();
            info.style.visibility = 'hidden';
        }
    };

    const duration = 500;

    function animateScale(feature: any) {
        const start = Date.now();
        const geom = feature.getGeometry().clone()

        const listenerKey = map.getLayers().getArray()[0].on('postrender', animate);

        function animate(event: any) {
            const frameState = event.frameState;
            const elapsed = frameState.time - start;
            if (elapsed >= duration) {
                unByKey(listenerKey);
                return;
            }
            const vectorContext = getVectorContext(event);
            const elapsedRatio = elapsed / duration;
            // radius will be 5 at start and 30 at end.
            // const radius = easeOut(elapsedRatio) * 25 + 5;
            // const opacity = easeOut(1 - elapsedRatio);
            const scale = easeOut(elapsedRatio) * 2;

            const scaleValue = getScaleValue(map.getView().getZoom());
            const style = getPolyLinePointsStyle(scaleValue * scale, false, feature);

            // const style = new Style({
            //     image: new CircleStyle({
            //         radius: radius,
            //         stroke: new Stroke({
            //             color: 'rgba(255, 0, 0, ' + opacity + ')',
            //             width: 0.25 + opacity,
            //         }),
            //     }),
            // });

            vectorContext.setStyle(style);
            vectorContext.drawGeometry(geom);
            // tell OpenLayers to continue postrender animation
            map.render();
        }
    }

    const getLineStyles = (feature: any, resolution: number) => {
        const scaleValue = getScaleValue(map.getView().getZoom());

        return [getPolyLineBottomStyle(scaleValue, feature), getPolyLineUpperStyle(scaleValue), getPolyLineTopStyle(scaleValue), getPolyLinePointsStyle(scaleValue / 1.5, false, feature)];
    };

    const createLayer = async (map: any) => {
        const layerId = 'polylineLayer';
        const source = new VectorSource({});
        const vectorLayer = getVectorLayerObject(source, layerId, true, 'vector', geoSettings.poiLayerKey, getLineStyles);
        map.addLayer(vectorLayer);
        polylineDispatches(layerId, true);
        setNSource(source);
        // @ts-ignore
        setVlayer(vectorLayer);
        setMap(map);
    };

    const getOverlayLayer = () => {
        getLayerById(map, 'clusterOverlayLayer').then(layerById => {
            if (layerById.found == true) {
                // @ts-ignore
                setClusterOverlayLayer(layerById.layer);
                // @ts-ignore
                setClusterOverlaySource(layerById.layer.getSource());
                // @ts-ignore
                refClusterOverlayLayer.current = layerById.layer;
                // @ts-ignore
                refClusterOverlaySource.current = layerById.layer.getSource();
            }
        });
    };

    const polylineDispatches = (layerId: string, toogleVisibility: boolean) => {
        dispatch(setLayerArray({
            name: layerId,
            mainLayerId: layerId,
            id: layerId,
            type: 'vector',
            parentLayer: null,
            toogleHidden: toogleVisibility,
            visible: true,
            styleId: null,
            simpleLayer: true,
            layerParts: null,
            layerConfig: null,
            styles: null,
            title: 'Polyline'
        }));
        dispatch(addLayerToCounterArray(projectId));
    };


    const addToolTipOnHover = () => {
        map.on('pointermove', function (evt: {
            dragging: any;
            originalEvent: { target: { closest: (arg0: string) => any; }; };
        }) {
            if (evt.dragging) {
                info.style.visibility = 'hidden';
                //  @ts-ignore
                setSelectedFeature(undefined);
                return;
            }
            const pixel = map.getEventPixel(evt.originalEvent);
            displayFeatureInfo(pixel, evt.originalEvent.target);
        });

        map.on('click', function (evt: {
            pixel: string[];
            originalEvent: { target: { closest: (arg0: string) => any; }; };
        }) {
            displayFeatureInfo(evt.pixel, evt.originalEvent.target);
        });

        map.getTargetElement().addEventListener('pointerleave', function () {
            clearSelectedFeature();
            info.style.visibility = 'hidden';
        });
    };

    useEffect(() => {
        // @ts-ignore
        if (vLayer !== null) {
            refVLayer.current = vLayer;
            refNSource.current = nSource;

            if (isSuccess) {
                if (resultPolylineData && resultPolylineData.length > 0) {
                    createPolylineFeatures(resultPolylineData).then(result => {
                        if (result.features.length > 0) {
                            if (itemId && itemId !== null) {
                                const polyline = result.features[0];
                                refNSource.current.addFeature(polyline);
                            } else {
                                refNSource.current.addFeatures(result.features);
                                // @ts-ignore
                                // console.log(refNSource.current.getFeatures())
                                const zoomMap = map.getView().getZoom();
                                map.getView().setZoom(zoomMap + 0.1);
                            }
                        }
                        createPoiFeatures(resultPolylineData, null).then(result2 => {

                            if (result2.features.length > 0) {
                                dispatch(setPolylineCluster({
                                    originalData: resultPolylineData,
                                    centerCoords: result.centercoords
                                }));
                                refNSource.current.addFeatures(result2.features);
                            }

                            dispatch(setDataLoaded(true));
                        });
                    });

                } else {
                    dispatch(setDataLoaded(true));
                }
            }
        }
    }, [isSuccess, resultPolylineData, vLayer]);


    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        // eslint-disable-next-line no-console

        createLayer(map).then(r => console.log('Polyline Layer erstellt'));
        getOverlayLayer();
        addToolTipOnHover();
    }, [map]);

    useEffect(() => {
        // @ts-ignorif( refSelectedFeature.current)refSelectedFeature.current.setStyle(null)
        refSelectedFeature.current = selectedFeature;
    }, [selectedFeature]);

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        refNSource.current.clear();
        triggerPolyline({project_id: projectId});

    }, [dataChanges]);


    useEffect(() => {
        if (projectType === 'polyline') {
            triggerPolyline({project_id: projectId});

        }
    }, []);
    return null;
};

export default PolylineLayer;
