import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import CustomDataTable from '../global/CustomDataTable';
import {getElementFileColumns, getElementFileFilters} from '../functions/Units';
import {useTranslation} from 'react-i18next';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {faEye} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CustomDialog from '../global/CustomDialog';
import {Button} from 'primereact/button';
import {showMessageOnError, showMessageOnSuccess, showMessageOnWarn} from '../global/CustomToast';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';
import {
    useCreateDocumentMutation,
    useDeleteDocumentMutation,
    useGetDocumentsQuery,
    useGetDocumentTypeQuery,
    useGetFileTypeQuery, useLazyGetDocumentQuery,
    useSetDocumentPreviewMutation
} from '../../redux/rtk/injectedUnitsApi';
import Input from '../global/Input';
import settings from '../../config/settings';
import {
    useCreateInspectionTaskDocumentMutation,
    useGetInspectionTaskDocumentsQuery,
    useSetInspectionTaskDocumentPreviewMutation
} from '../../redux/rtk/injectedInspectionApi';
import {UnitFieldType} from './UnitFields';
import * as fs from 'fs';
import moment from 'moment';
import {checkPermission} from "../../functions/functionLibrary";
import {permissions} from "../../config/permissions";

type Props = {
    project: any,
    id: string,
    type: UnitFieldType,
    editable: boolean
    setFilesCount: (count: number) => void,
    setPreviewImages: (images: any[]) => void
    showPreview: boolean,
    header?: string,
    skeletonCount?: number
}

export const ElementFileManagement = (props: Props) => {
    const {t} = useTranslation(['common']);

    const inputFile: any = useRef(null);

    const [value, setValue] = useState<any[]>([]);
    const [addedFile, setAddedFile] = useState<any>(undefined);
    const [selectedDocumentType, setSelectedDocumentType] = useState<any>(undefined);

    const [documents, setDocuments] = useState<any[] | null>(null);
    const [documentTypes, setDocumentTypes] = useState<any[]>([]);
    const fileTypes = useRef<any[]>([])

    const [createDocument, setCreateDocument] = useState<any>(undefined);
    const [setDocumentPreview, setSetDocumentPreview] = useState<any>(undefined);

    const [deleteDocument] = useDeleteDocumentMutation();

    const [_createDocument] = useCreateDocumentMutation();
    const [_setDocumentPreview] = useSetDocumentPreviewMutation();

    const [_createInspectionTaskDocument] = useCreateInspectionTaskDocumentMutation();
    const [_setInspectionTaskPreview] = useSetInspectionTaskDocumentPreviewMutation();

    const documentTypesQuery = useGetDocumentTypeQuery({
        projectTypeId: props.project.project_type_id
    });
    const fileTypesQuery = useGetFileTypeQuery({
        projectTypeId: props.project.project_type_id
    });

    const documentsQuery = useGetDocumentsQuery({
            projectId: props.project.id,
            elementId: props.id
        },
        {skip: props.type !== UnitFieldType.element}
    );

    const [getDocumentTrigger, getDocumentResult, getDocumentLastPromise] = useLazyGetDocumentQuery();

    const documentsInspectionTaskQuery = useGetInspectionTaskDocumentsQuery({
            projectId: props.project.id,
            inspectionTaskId: props.id
        },
        {skip: props.type !== UnitFieldType.inspectionTask}
    );
    useEffect(() => {
        switch (props.type) {
            case UnitFieldType.element:
                setCreateDocument(() => {
                    return _createDocument;
                });
                setSetDocumentPreview(() => {
                    return _setDocumentPreview;
                });
                break;

            case UnitFieldType.inspectionTask:
                setCreateDocument(() => {
                    return _createInspectionTaskDocument;
                });
                setSetDocumentPreview(() => {
                    return _setInspectionTaskPreview;
                });
                break;


        }
    }, [props.type]);

    // useEffect(() => {
    //     console.log('files', value);
    // }, [value]);

    useEffect(() => {
        if (documentTypesQuery.data) {
            setDocumentTypes(documentTypesQuery.data);
        }
    }, [documentTypesQuery.data]);

    useEffect(() => {
        if (fileTypesQuery.data) {
            fileTypes.current = fileTypesQuery.data;
        }
    }, [fileTypesQuery.data]);

    useEffect(() => {
        if (documentsQuery.data) {
            setDocuments(documentsQuery.data);
            props.setFilesCount(documentsQuery.data.length);
        } else if (documentsInspectionTaskQuery.data) {
            setDocuments(documentsInspectionTaskQuery.data);
            props.setFilesCount(documentsInspectionTaskQuery.data.length);
        }
    }, [documentsQuery.data, documentsInspectionTaskQuery.data]);

    useEffect(() => {
        if (documents) {
            const temp = JSON.parse(JSON.stringify(documents));

            setValue(temp);

            props.setFilesCount(documents.length);

            const _result: any[] = [];
            for (let i = 0; i < documents.length; i++) {
                const data = documents[i];
                if (data.is_preview) {

                    // Variante 1 (fehlerhaft, Links werden korrekt erzeugt, die Galleria wird aber nicht dargestellt):
                    // Ohne öffentlicher Link, mit Download vom Nextcloudserver
                    /*
                    showWaitAnimation();
                    getDocumentTrigger({projectId: props.project.id, nextcloudFileId: data.nextcloud_file_id}).then((result: any) => {
                        hideWaitAnimation();
                        const blob = base64ToBlob(result.data.file, data.mimetype);
                        const fileURL = URL.createObjectURL(blob);
                        _result.push({
                            src: fileURL,
                            alt: data.name
                        });
                    });
                    */

                    // Variante 2:
                    // Öffentlicher Link in Nextcloud
                    _result.push({
                        src: settings.nextcloudUrl + '/s/' + data.token + '/preview',
                        alt: data.name
                    });
                }
            }
            props.setPreviewImages(_result);
        }
    }, [documents]);


    const removeClick = useCallback((e: any) => {
        CustomConfirmDialog({
            header: t('dialogs_removeElementFileHeader'),
            message: t('dialogs_removeElementFileMessage'),
            translation: t,
            onConfirm: () => {
                showWaitAnimation();
                deleteDocument({'project_id': props.project.id, 'file_id': e.file_id}).then((result: any) => {
                    hideWaitAnimation();
                    if (!result.error) {
                        showMessageOnSuccess(t('success'), t('projM_fileDeleted'));
                    } else {
                        // showMessageOnError(t('error'), result.error.data.error);
                    }
                });
            }
        });
    }, [props.project.id]);

    const chooseFile = (e: any) => {
        if (inputFile.current !== null) {
            inputFile.current.click();
        }
    };

    const getBase64 = (file: any, cb: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const addTempFile = useCallback((e: any) => {
        if (e.target.files[0].size > 5000000) {
            showMessageOnError(t('error'), t('error_fileExceed5MbLimitError'));
        } else {

            const fileTypesArr = fileTypes.current.map((x: any) => {
                return x.name.toLowerCase();
            });

            getBase64(e.target.files[0], (result: any) => {
                const arr = result.split('base64,');
                if (arr.length === 2) {

                    const split = e.target.files[0].name.split('.');
                    const extension = split[split.length - 1].toLowerCase();
                    let file_type_id = 0;

                    if (fileTypesArr.indexOf(extension) < 0) {
                        showMessageOnError(t('error'), t('error_fileExtensionNotSupportedError'));
                    } else {
                        switch (extension) {
                            case 'png':
                                file_type_id = 0;
                                break;

                            case 'jpg':
                            case 'jpeg':
                                file_type_id = 1;
                                break;

                            case 'pdf':
                                file_type_id = 2;
                                break;

                            case 'doc':
                                file_type_id = 3;
                                break;

                            case 'docx':
                                file_type_id = 4;
                                break;
                        }

                        const body = {
                            action: 'add',
                            project_id: props.project.id,
                            element_id: props.type === 'element' ? props.id : undefined,
                            inspection_task_id: props.type === 'inspection_task' ? props.id : undefined,
                            file_type_id: file_type_id,
                            data: arr[1],
                            document_type_id: 0,
                            name: e.target.files[0].name,
                        };

                        setAddedFile(body);

                        // setValue((prevState) => {
                        //     let temp = JSON.parse(JSON.stringify(prevState));
                        //
                        //     temp.push(body);
                        //
                        //     return temp;
                        // });

                        // showWaitAnimation();
                        // createDocument(body).then((result: any) => {
                        //     hideWaitAnimation();
                        //     if (!result.error) {
                        //         showMessageOnSuccess(t('success'), t('projectManagement:toasts.fileUploaded'));
                        //         // _getFiles();
                        //     }
                        // });
                    }
                }
            });
        }
    }, [fileTypes.current]);

    const addFile = () => {
        if (addedFile) {
            setValue((prevState) => {
                const temp = JSON.parse(JSON.stringify(prevState));

                const tempValue = {
                    ...addedFile,
                    'document_type_id': selectedDocumentType,
                    'project_type_id': props.project.project_type_id
                };

                if (tempValue) {
                    if (createDocument) {
                        showWaitAnimation();
                        createDocument(tempValue).then((result: any) => {
                            hideWaitAnimation();
                            if (!result.error) {
                                showMessageOnSuccess(t('success'), t('toasts_fileUploaded'));
                                // Füge die nextcloud fileId hinzu, die vom Server kommt
                                temp.push({...tempValue, nextcloud_file_id: result.data.nextcloud_file_id});
                            }
                        });
                    }
                }

                return temp;
            });
        }

        setAddedFile(undefined);
        setSelectedDocumentType(undefined);
    };

    const hideDialogs = () => {
        setAddedFile(undefined);
        setSelectedDocumentType(undefined);
    };

    const onAddDocument = () => {
        if (inputFile.current !== null) {
            inputFile.current.click();
        }
    };

    const getColumns = useCallback(() => {
        return getElementFileColumns(t, documentTypes, props.project.id, props.showPreview, setDocumentPreview)
    }, [documentTypes, props.project.id, props.showPreview, setDocumentPreview]);

    const getFilters = useCallback(() => {
        return getElementFileFilters()
    }, [])


    function base64ToBlob(base64: string, mimeType: string): any {
        // Umwandeln des Base64-Strings in ein Uint8Array
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Erstellen des Blobs aus dem Uint8Array
        return new Blob([byteArray], {type: mimeType});
    }


    const customButtons = useMemo(() => {
        return [
            {
                icons: [{key: null, value: null, icon: <FontAwesomeIcon icon={faEye}/>}],
                tooltip: t('show'),
                tooltipOptions: {position: 'top'},
                onClick: (data: any) => {
                    if (data.action === 'add') {
                        showMessageOnWarn('', t('dialogs_unsavedShowWarning'));
                    } else {
                        showWaitAnimation();
                        getDocumentTrigger({
                            projectId: props.project.id,
                            nextcloudFileId: data.nextcloud_file_id
                        }).then((result: any) => {
                            hideWaitAnimation();
                            // console.log('data: ', result.data.file);
                            // console.log('data.mimetype: ', data.mimetype);
                            const blob = base64ToBlob(result.data.file, data.mimetype);
                            const fileURL = URL.createObjectURL(blob);
                            window.open(fileURL, '_blank');
                        });

                    }
                },
                permitted: true,
            }];
    }, []);

    const header = useMemo(() => {
        return <div className={'grid'}>
            <div className={'col'}>
                <h4 className={'m-0'}>{props.header ? props.header : t('units_files_header')}</h4>
            </div>
            <div className={'col flex justify-content-end flex-wrap mb-auto mx-2'}>
                <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    accept={fileTypes.current.map((x: any) => {
                        return '.' + x.name;
                    }).join(',')}
                    onInput={addTempFile}
                    style={{display: 'none'}}
                />
                {checkPermission(permissions.editDocuments) && props.editable && <Button type={'button'}
                                                                                         className={'p-button-success p-button-icon-only p-button-outlined'}
                                                                                         icon={'pi pi-plus'}
                                                                                         onClick={onAddDocument}/>}
            </div>
        </div>
    }, [props.header, props.editable])

    return (<div className={'mb-4'}>
        <CustomDataTable id={'unit_files'}
                         columns={getColumns()}
                         filters={getFilters()}
                         header={header}
                         editable={checkPermission(permissions.editDocuments) && props.editable} sortable={true}
                         prependCustomButtons={true}
                         customButtons={customButtons}
                         value={value}
                         dataloaded={documents != null}
                         ableToDelete={checkPermission(permissions.editDocuments)}
                         onRemoveClick={removeClick}
                         skeletonCount={props.skeletonCount}/>
        <CustomDialog onCancel={hideDialogs} visible={addedFile} header={t('dialogs_documentType')}
                      onClick={addFile}>
            <Input edit={true} type={'dropdown'} name={'documenttype_dropdown'} value={selectedDocumentType}
                   onChange={(e: any) => {
                       setSelectedDocumentType(e.target.value);
                   }}
                   dropdownOptions={documentTypes.map((x: any) => {
                       return {value: x.id, label: x.name};
                   })}/>
        </CustomDialog>
    </div>);
};
