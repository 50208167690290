import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    useGetInspectionFieldsQuery,
    useGetInspectionTypeQuery,
    useLazyGetInspectionTaskTypeQuery,
} from '../../redux/rtk/injectedInspectionApi';
import {
    useGetElementTreeQuery,
    useGetElementTypesQuery,
    useLazyGetCustomerTypesQuery
} from '../../redux/rtk/injectedUnitsApi';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {useForm} from "react-hook-form";
import {Inspection} from "../functions/Inspection";
import {VirtualScroller} from "primereact/virtualscroller";
import {UnitFields, UnitFieldType} from "../unitManagement/UnitFields";
import Input from "../global/Input";
import {getUserFromLocalStorage} from "../functions/Global";
import {User} from "../functions/UserManagement";
import {useGetUsersQuery} from "../../redux/rtk/injectedGlobal";
import {changeField, resetElementChanges} from "../../redux/slices/ElementChangesSlice";
import {showMessageOnError, showMessageOnWarn} from "../global/CustomToast";
import {useCreateInspectionMutation, useUpdateInspectionMutation} from "../../redux/rtk/injectedInspectionApi";
import {ElementTypeItem, getElementTypeName} from "../functions/Units";
import {UnitSubtype} from "../unitManagement/UnitSubtype";
import {TabPanel, TabView} from "primereact/tabview";
import {checkPermission} from "../../functions/functionLibrary";
import {permissions} from "../../config/permissions";
import {ProgressSpinner} from "primereact/progressspinner";

type Props = {
    project: any,
    editable: boolean,
    workflow: any,
    form: any,
    fields: any[],
    inspection?: Inspection,
    inspectableElements?: any[],
    elementTypes?: ElementTypeItem[],
    inspectionTypeList?: any[]
}

export const InspectionEditFormWorkflow = (props: Props) => {
    const {t} = useTranslation(['common']);
    //
    // const elementChanges: any[] = useAppSelector((state: any) => state.elementChangesData.elementChanges);
    const dispatch = useAppDispatch();

    const usersQuery = useGetUsersQuery({projectId: props.project.id, roleId: 18});

    const [selectedElement, setSelectedElement] = useState<any>(undefined);

    const [form, setForm] = useState<any>(props.form);

    useEffect(() => {
        setForm(props.form);
    }, [props.form]);

    const selectedParentElementId = form.watch('element_id');

    const hasPermission = useMemo(() => {
        return checkPermission(permissions.isAdmin) || checkPermission(props.workflow.workflow_permission_id);
    }, [props.workflow])

    const dropDownOptionsInspectionBy = useMemo(() => {
        if (usersQuery.data) {
            return usersQuery.data.map((item: any) => {
                return {
                    label: item.first_name + ' ' + item.last_name,
                    value: item.id
                };
            });
        }
        return [];
    }, [usersQuery.data]);

    useEffect(() => {
        dispatch(resetElementChanges());
    }, []);


    useEffect(() => {
        if (selectedParentElementId) {
            let item = props.inspectableElements?.find((item: any) => item.id === selectedParentElementId);

            setSelectedElement(item);
        }
    }, [selectedParentElementId]);


    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return form.errors && form.errors[name] &&
            <small className="p-error custom">{form.errors[name].message}</small>;
    };

    return (
        <>
            {!hasPermission && <div>{t('no_permission_to_see_this_page')}</div>}
            {hasPermission && props.workflow.workflow_id === 1 &&
                <div className={'card'}>
                    <div className={'grid'}>
                        <div className={'col-6'}>
                            <Input edit={props.editable} name={'element_id'} type={'dropdown'}
                                   label={t('inspection_equipments_headline')}
                                   dropdownOptions={props.inspectableElements}
                                   options={{
                                       optionLabel: 'name',
                                       optionValue: 'id',
                                       virtualScrollerOptions: {itemSize: 29}
                                   }}
                                // onChange={(e: any) => {
                                //     setSelectedParentElementId(e.target.value);
                                //     dispatch(changeField({
                                //         inspection_id: props.inpsection ? props.inpsection.id : '',
                                //         key: 'element_id',
                                //         value: e.target.value
                                //     }));
                                // }}
                                   validationControl={form.control}
                                   validationErrorMessage={getFormErrorMessage}></Input>
                        </div>
                        <div className={'col-6'}>
                            <UnitSubtype key={'inspectionsubtype'} item={selectedElement}
                                         project={props.project}
                                         elementTypes={props.elementTypes || []} edit={false}/>
                        </div>
                        <div className={'col-12'}>
                            <Input edit={props.editable} name={'inspection_type_id'} type={'dropdown'}
                                   label={t('inspection_types_headline')}
                                   dropdownOptions={props.inspectionTypeList}
                                   options={{optionLabel: 'label', optionValue: 'id'}}
                                // onChange={(e: any) => {
                                //     setSelectedInspectionTypeId(e.target.value);
                                //     dispatch(changeField({
                                //         inspection_id: props.inpsection ? props.inpsection.id : '',
                                //         key: 'inspection_type_id',
                                //         value: e.target.value
                                //     }));
                                // }}
                                   validationControl={form.control}
                                   validationErrorMessage={getFormErrorMessage}></Input>
                        </div>
                        {/*<div className={'col-12'}>*/}
                        {/*    <Input edit={props.editable} name={'element_id'} type={'multiselect'}*/}
                        {/*           label={t('inspection_elements_headline') + ' (' + t('show_only') + ')'}*/}
                        {/*           dropdownOptions={elementList} value={selectedElementIds}*/}
                        {/*           options={{*/}
                        {/*               // optionLabel: 'name',*/}
                        {/*               // optionValue: 'id',*/}
                        {/*               virtualScrollerOptions: {itemSize: 29}*/}
                        {/*           }}></Input>*/}
                        {/*</div>*/}
                    </div>
                </div>}
            {hasPermission && <div className={'grid'}>
                <UnitFields project={props.project} fields={props.fields}
                            id={props.inspection ? props.inspection.id : ''}
                            type={UnitFieldType.inspection} className={'col-12 lg:col-4'}
                            form={form} validationErrorMessage={getFormErrorMessage} editable={props.editable}/>
            </div>}
        </>
    );
};
