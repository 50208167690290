import React, {useState, useEffect, useRef} from 'react';
import LayersMapOl from '../components/geoData/layer/LayersMapOl';
import MapPoiOl from '../components/geoData/map/MapPoiOl';
import {Point} from 'ol/geom';
import BaseLayers from '../components/geoData/layer/BaseLayers';
import OverlayLayer from '../components/geoData/layer/OverlayLayer';
import {useAppSelector, useAppDispatch} from '../redux/hooks';
import {
    resetData,
    setBaseLayer,
    setWorkspace,
    setBaseWorkspace
} from '../redux/slices/GeomonitoringSlice';
import geoSettings from '../config/geoSettings';
import {transformCoords} from '../services/SpatialService';
import PoiLayer from '../components/geoData/layer/PoiLayer';
import ClusterOverlayLayer from '../components/geoData/layer/ClusterOverlayLayer';
import PoiModifyLayer from '../components/geoData/layer/PoiModifyLayer';
import PolylineLayer from '../components/geoData/layer/PolylineLayer';
import PolylineModifyLayer from '../components/geoData/layer/PolylineModifyLayer';
import MapPolylineOl from "../components/geoData/map/MapPolylineOl";
import ClusterPolylineOverlayLayer from "../components/geoData/layer/ClusterPolylineOverlayLayer";
import ConfigLayers from '../components/geoData/layer/ConfigLayers';

type Props = {
    divheight:any,
    project: any,
    configActive: boolean,
    onConfigButtonClick: any
    onConfigClick: any
    alarm: any,
    show: boolean
    mapId?:any
    item?:any,
    allPois?:boolean;
    mapActive?:boolean;
    baseControlEnabled?:boolean
}

const GeoData = (props: Props): JSX.Element => {
    const itemId=props.item?props.item.id:null;
    const projectType=props.project.style_type?props.project.style_type:'poi';
    const [center, setCenter] = useState([0, 0]);
    const [zoom, setZoom] = useState(9);
    const [item, setItem] = useState(props.item);
    const projectId = props.project.id;
    const refZoom = useRef(zoom);
    const dispatch = useAppDispatch();
    let mapSet = false;

    const transformCoordsCp = (project: any) => {
        const location = project.location;
        const projectEpsg = project['location_epsg_code'] ? project['location_epsg_code'] : 4326;
        let transformCoordsCenter = location.coordinates;
        if (projectEpsg === 3587) {
            return transformCoordsCenter;
        }
        if (projectEpsg !== 4326) {
            transformCoordsCenter = transformCoords(location.coordinates, projectEpsg, 4326);
        }
        return transformCoords(transformCoordsCenter, 4326, 3857);
    };

    const getMapCenter = () => {
        const project = props.project;
        const location = project.location;

        if (location && location.coordinates) {
            const coords = transformCoordsCp(project);
            const centerPoint = new Point(coords);
            const zoomLevel = parseFloat(project.location_zoom) > 17 ? 17 : parseFloat(project.location_zoom);
            const centerCoords = centerPoint.getCoordinates();
            setZoom(zoomLevel);
            setCenter(centerCoords);
        }
    };

    useEffect(() => {
        if (!mapSet) {
            const baseWorkspace = props.project.baselayer_workspace !== null ? props.project.baselayer_workspace : geoSettings.wmsWorkspace;
            dispatch(resetData());
            dispatch(setWorkspace(props.project.geoserver_workspace));
            dispatch(setBaseLayer(props.project.base_layer));
            dispatch(setBaseWorkspace(baseWorkspace));
            getMapCenter();
            mapSet = true;
        }
    }, [props.project]);

    useEffect(() => {
        refZoom.current = zoom;
    }, [zoom]);

    useEffect(() => {
        setItem(props.item)
    }, [props.item]);

    const poiMap=(
        <MapPoiOl key={props.project.id + '_maps' + props.mapId} project={props.project} zoom={zoom} center={center}
            propElem={props} position={'relative'} divHeight={props.divheight} toolkitEnabled={props.configActive}
            mapActive={props.mapActive} baseControlEnabled={props.baseControlEnabled} >
            <LayersMapOl key={props.project.id + '_layerMaps'}>
                <BaseLayers projectId={projectId} mapDataWorkBench={props.project.geoserver_workspace}
                    baseLayer={props.project.base_layer}/>
                <OverlayLayer projectId={projectId}/>
                <ClusterOverlayLayer projectId={projectId} />
                <ConfigLayers projectId={projectId}/>
                {props.baseControlEnabled&&<PoiModifyLayer projectId={projectId} itemId={itemId} zoomMap={zoom}/>}
                {!props.baseControlEnabled&&<PoiLayer projectId={projectId} itemId={itemId}  zoomMap={zoom}/>}
            </LayersMapOl>
        </MapPoiOl>);

    const polylineMap= (
        <MapPolylineOl key={props.project.id + '_maps' + props.mapId} project={props.project} zoom={zoom} center={center}
            propElem={props} position={'relative'} divHeight={props.divheight} toolkitEnabled={props.configActive}
            mapActive={props.mapActive} baseControlEnabled={props.baseControlEnabled}>
            <LayersMapOl key={props.project.id + '_layerMaps'}>
                <BaseLayers projectId={projectId} mapDataWorkBench={props.project.geoserver_workspace}
                    baseLayer={props.project.base_layer}/>
                <OverlayLayer projectId={projectId}/>
                <ClusterOverlayLayer projectId={projectId} />
                <ClusterPolylineOverlayLayer projectId={projectId} projectType={projectType}/>
                <ConfigLayers projectId={projectId}/>
                {props.baseControlEnabled&&<PolylineModifyLayer projectId={projectId} zoomMap={zoom}  itemId={itemId} projectType={projectType} item={item}/>}
                {!props.baseControlEnabled&&<PolylineLayer projectId={projectId} zoomMap={zoom} projectType={projectType}/>}
            </LayersMapOl>
        </MapPolylineOl>);

    /* Überprüfen, ob props notwendig, wenn dispatch*/
    return (<div style={{display: props.show ? 'inline' : 'none'}}>
        {/* Geo Monitoring / Karten Darstellung*/}
        {projectType === 'poi' && poiMap}
        {projectType ==='polyline' && polylineMap}
        <div className="tooltip_info"></div>
    </div>);
};
export default GeoData;
