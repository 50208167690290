/*
 * Nextcloud.tsx
 * Author: lnappenfeld
 * Date: 21.11.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef, useState} from 'react';
import {Button} from 'primereact/button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMaximize, faUpRightFromSquare} from '@fortawesome/free-solid-svg-icons';
import settings from '../config/settings';
import {Nullable} from '../components/functions/Global';

type Props = {
    nextcloudFolderId: Nullable<string>
}

export const Nextcloud = (props: Props): JSX.Element => {

    const componentRef: any = useRef();
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    const nextcloudFolderUrl = settings.nextcloudUrl + '/index.php/f/' + props.nextcloudFolderId;

    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        setHeight(componentRef.current.offsetHeight);
        setWidth(componentRef.current.offsetWidth);
    });

    useEffect(() => {
        if (height > 0 && width > 0) {
            const iframeElement: any = document.querySelector('iframe');
            iframeElement.style.width = width + 'px';
            iframeElement.style.height = height + 'px';
        }
    }, [height, width]);

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noreferrer');
    };

    return (<>
        {
            <div className={'nextcloud-buttons'}>
                <Button
                    className={'button-fullscreen p-button m-1'}
                    onClick={() => {
                        // set iframe to fullscreen
                        if (iframeRef && iframeRef.current) {
                            const elem = iframeRef.current;
                            // @ts-ignore
                            elem.requestFullscreen().catch((e) => {
                                console.log('error: ', e);
                            });
                        }
                    }}
                >
                    <FontAwesomeIcon className='sidebar-icon' icon={faMaximize}/>
                </Button>
                <Button
                    className={'button-new-tab p-button m-1'}
                    onClick={() => {
                        // link to nextcloud
                        openInNewTab(nextcloudFolderUrl);
                    }}
                >
                    <FontAwesomeIcon className='sidebar-icon' icon={faUpRightFromSquare}/>
                </Button>
            </div>}
        <div ref={componentRef} className={'iframe-wrapper'}>
            <iframe
                ref={iframeRef}
                allowFullScreen={true}
                style={{'borderRadius': '6px', border: 0, display: 'inline-block'}}
                src={nextcloudFolderUrl}
                width={'100%'}
                height={'100%'}
                id={'nextcloud_iframe'}
                className={''}
            />
        </div>
    </>);
};