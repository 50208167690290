import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Checkbox} from 'primereact/checkbox';
import {dependPermissions} from '../../config/permissions';
import {Nullable} from '../functions/Global';
import './../../style/user-management.scss';

type Props = {
    allPermissions: any,
    selectedPermissions: any
    setSelectedPermissions: any
}

const RolePermissionList = (props: Props): JSX.Element  =>{
    const {t} = useTranslation(['common', 'roles']);
    const checkboxRef = useRef<any>(null);

    let previousGroup: Nullable<number> = null;

    const allPermissions = props.allPermissions;
    const selectedPermissions = JSON.parse(JSON.stringify(props.selectedPermissions));
    const setSelectedPermissions = props.setSelectedPermissions;
    const [all, setAll] = useState(false);

    const [hoveredPermissionKeys, setHoveredPermissionKeys] = useState<number[]>([]);

    const checkAll = (e:any) => {
        if (e.value===false) {
            setAll(true);
            setSelectedPermissions(allPermissions);
        } else {
            setAll(false);
            setSelectedPermissions([]);
        }
    };

    const onPermissionChange = (e: { value: any, checked: boolean }) => {
        const _selectedPermissions = [...selectedPermissions];
        // console.log('_selectedPermissions: ', _selectedPermissions);

        if (e.checked) {
            // Füge das gerade ausgewählte Recht dem Array hinzu
            _selectedPermissions.push(e.value);
            // @ts-ignore
            const arr = dependPermissions[e.value.key.toString()];
            // Füge die, zum gerade ausgewählten Recht, abhängigen Rechte dem Array hinzu, wenn es noch nicht bereits
            // exisitiert
            for (const i in arr) {
                for (const perm of allPermissions) {
                    if (perm.key === arr[i] && _selectedPermissions.findIndex(ele => ele.key === perm.key) === -1) {
                        _selectedPermissions.push(perm);
                        break;
                    }
                }
            }
        } else {
            // Lösche das Recht direkt, wenn es keine Abhängigkeiten hat, ansonsten löschen auch die Abhängigkeiten
            const unselectedKey = e.value.key;
            const permissionDependedKeys = [];
            for (let i = 0; i < _selectedPermissions.length; i++) {
                const currentSelectedPermission = _selectedPermissions[i];
                if (currentSelectedPermission.key === unselectedKey) {
                    // Lösche das Recht
                    _selectedPermissions.splice(i, 1);

                    // Fülle das Array mit allen Rechten, die ebenfalls gelöscht werden sollen
                    for (const [key, value] of Object.entries(dependPermissions)) {
                        for (const depPermission of value) {
                            if (unselectedKey === depPermission) {
                                permissionDependedKeys.push(parseInt(key));
                            }
                        }
                    }
                }
            }
            // Lösche alle Rechte, die ebenfalls gelöscht werden sollen
            for (const dependedKey of permissionDependedKeys) {
                for (let y = 0; y < _selectedPermissions.length; y++) {
                    if (_selectedPermissions[y].key === dependedKey) {
                        _selectedPermissions.splice(y, 1);
                    }
                }
            }
        }
        // console.log('_selectedPermissions: ', _selectedPermissions);

        setSelectedPermissions(_selectedPermissions);
    };

    const onHoverPermission = (permission: { key: any }) => {
        // console.log('permission: ', permission);

        const arr = [];

        // 1. Füge den aktuellen Key hinzu, also die aktuelle Zeile die gehovert wird
        arr.push(permission.key);

        // 2. Füge die Permissions hinzu, die ebenfalls angehakt werden würden
        // Beispiel-Richtung: Recht 'edit' -> Recht 'see'
        let dependedPermissions = null;
        // @ts-ignore
        dependedPermissions = dependPermissions[permission.key.toString()];
        if (dependedPermissions) {
            for (const dependedPer of dependedPermissions) {
                arr.push(dependedPer);
            }
        }

        // 3. Fülle das Array mit allen Rechten, die ebenfalls gelöscht werden würden
        // Beispiel-Richtungen: Recht 'see' -> Rechte 'edit', 'import', 'add', ...
        const permissionDependedKeys = [];
        for (const [key, value] of Object.entries(dependPermissions)) {
            // console.log('-------------------------------- ');
            // console.log('key: ', key);
            // console.log('value: ', value);
            // console.log('permission.key: ', permission.key);
            for (const depPermission of value) {
                if (permission.key === depPermission) {
                    // console.log('push key ----------->: ', parseInt(key));
                    permissionDependedKeys.push(parseInt(key));
                    break;
                }
            }
        }
        for (const dependedKey of permissionDependedKeys) {
            arr.push(dependedKey);
        }

        // console.log('arr: ', arr);
        // Setze den useState
        setHoveredPermissionKeys(arr);
    };

    /* Ermöglicht ein Klick auf das ganze Div */
    const onPermissionChangeDiv = (permission: { key: any }) => {
        // Bestimme ob das Recht aktuell ausgewählt ist oder nicht
        let checked = false;
        for (let i = 0; i < selectedPermissions.length; i++) {
            const currentSelectedPermission = selectedPermissions[i];
            if (currentSelectedPermission.key === permission.key) {
                checked = true;
                break;
            }
        }

        // Drehe den Wert um, weil das ja der neue Wert ist
        checked = !checked;

        // Erzeuge das Objekt für onPermissionChange
        const obj = {
            value: permission,
            checked: checked
        };

        // Führe die Methode zum setzen und entfernen der abhängingen Permissions aus
        onPermissionChange(obj);
    };

    return (<>
        <div key="checkAll" className="field-checkbox bg-gray-100 rowContainer role-permission-header-div" >
            <Checkbox
                inputId={'checkAll'}
                name={'checkAll'}
                value={all}
                onChange={checkAll}
                checked={all}
            />
            <label htmlFor={'checkAll'}><b>{t('userM_permissions_name')}</b></label>
        </div>
        {allPermissions.map((permission:any) => {
            const currentGroup = Math.floor(permission.count / 100);
            const element = (
                <div
                    key={'group' + permission.count}
                    onClick={() => onPermissionChangeDiv(permission)}
                    style={{ cursor: 'pointer', userSelect: 'none' /* Verhindert Textauswahl beim Klicken */ }}
                >
                    {/* Zeige eine Linie oberhalb der neuen Gruppe an */}
                    {(previousGroup !== null && currentGroup !== previousGroup) && <hr className="-mt-2"/>}
                    <div
                        key={permission.key} className="field-checkbox rowContainer role-permission-div -mt-2"
                        style={{backgroundColor: hoveredPermissionKeys.includes(permission.key) ? 'lightgray' : ''}}
                        onMouseEnter={() => onHoverPermission(permission)}
                        onMouseLeave={() => setHoveredPermissionKeys([])}
                    >
                        <Checkbox
                            inputId={permission.key}
                            name="category"
                            value={permission}
                            // onChange={onPermissionChange}
                            checked={selectedPermissions.some((item:any) => item.key === permission.key)}
                            disabled={permission.key === 'R'}
                            ref={checkboxRef}
                        />
                        <label style={{ cursor: 'pointer' }} >
                            <i className={'mr-2 ' + permission.icon} />
                            {permission.label}
                        </label>
                    </div>
                </div>
            );
            previousGroup = currentGroup;
            return element;
        })}
    </>);

};
export default RolePermissionList;