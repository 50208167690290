import React, {useState, useEffect, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import Input from '../global/Input';
import ProjectStyleType from './ProjectStyleType';
import {getProjectTypesColumns, getProjectTypesFilters, Project_ProjectType} from "../functions/TypeManagement";
import CustomDataTable from "../global/CustomDataTable";
import CustomDialog from "../global/CustomDialog";
import {
    useAddProjectTypeMutation,
    useGetProjectTypesQuery, useLazyGetProjectTypesQuery,
    useUpdateProjectTypeMutation
} from "../../redux/rtk/injectedProjectTypes";
import {showMessageOnSuccess} from "../global/CustomToast";

type Props = {
    showEdit: boolean,
    setShowEdit: (value: boolean) => void
};


export const ProjectTypeEditForm_New = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [selectedItem, setSelectedItem] = useState<Project_ProjectType | undefined>(undefined);
    const [showDialog, setShowDialog] = useState<boolean>(props.showEdit);

    const [triggerGetProjectTypes, projectTypesResult] = useLazyGetProjectTypesQuery({});

    const [addProjectType] = useAddProjectTypeMutation();
    const [updateProjectType] = useUpdateProjectTypeMutation();

    const defaultValues: Project_ProjectType = {
        id: 0,
        name: '',
        style_type: ''
    };

    const {control, formState: {errors}, handleSubmit, setValue, getValues, watch, reset} = useForm({defaultValues});

    useEffect(() => {
        triggerGetProjectTypes();
    }, []);

    useEffect(() => {
        setShowDialog(props.showEdit);
    }, [props.showEdit]);

    const getFormErrorMessage = (name: string) => {
        if (name.indexOf('.') >= 0) {
            const split = name.split('.');

            if (split.length === 3) {
                // @ts-ignore
                if (errors[split[0]] && errors[split[0]][split[1]] && errors[split[0]][split[1]][split[2]]) {
                    // @ts-ignore
                    const text = errors[split[0]][split[1]][split[2]].message;

                    return <small className="p-error custom">{text}</small>;
                }
            }
        } else {
            // @ts-ignore
            if (errors[name]) {
                // @ts-ignore
                const text = errors[name].message;

                return <small className="p-error custom">{text}</small>;
            }
        }
        return undefined;
    };


    const onEditClick = useCallback((data: Project_ProjectType) => {
        setSelectedItem(data);
        reset(data);
        setShowDialog(true);
    }, []);

    const onDeleteClick = useCallback(() => {

    }, []);

    const hideDialog = () => {
        setShowDialog(false);
        setTimeout(() => {
            setSelectedItem(undefined);
            reset(defaultValues);
            props.setShowEdit(false);
        }, 200);
    };

    const onSave = (data: Project_ProjectType) => {
        if (selectedItem) {
            updateProjectType(data).then((res: any) => {
                if (!res.error) {
                    showMessageOnSuccess(t('success'), t('saved'));
                    triggerGetProjectTypes({});
                    hideDialog();
                }
            });
        } else {
            addProjectType([data]).then((res: any) => {
                if (!res.error) {
                    showMessageOnSuccess(t('success'), t('saved'));
                    triggerGetProjectTypes({});
                    hideDialog();
                }
            });
        }
    };

    return (
        <>
            <CustomDataTable id={'project_types'} headerTitle={t('project_types')}
                             columns={getProjectTypesColumns(t)}
                             filters={getProjectTypesFilters()}
                             editable={true}
                             sortable={true}
                             paginator={true}
                             value={projectTypesResult.data} dataloaded={projectTypesResult.data == null}

                             ableToUpdate={true}
                             onEditClick={onEditClick}
                // ableToDelete={true}
                             onRemoveClick={onDeleteClick}/>
            <CustomDialog header={selectedItem ?
                <div className='flex'><i className='pi pi-pencil my-auto mr-2'/>{t('project_type_edit')}
                </div> : <div className='flex'><i className='pi pi-plus my-auto mr-2'/>{t('project_type_new')}
                </div>} visible={showDialog}
                          onCancel={hideDialog} formName={'projectForm_new'}
                          style={{maxWidth: '800px'}}>
                <form id="projectForm_new" style={{height: '100%'}} onSubmit={handleSubmit(onSave)}>
                    <div
                        className="card">
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <Input
                                    edit={true}
                                    label={t('projM_projectTypeName')}
                                    name={'name'}
                                    type={'text'}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('input_required')}}
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <ProjectStyleType
                                    label={t('style_type')}
                                    name={'style_type'}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </>
    );
};
