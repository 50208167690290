import React, {useState, useEffect, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {Button} from 'primereact/button';
import {ProgressSpinner} from 'primereact/progressspinner';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {buttonSaveProjectType, buttonCloseAllCards, buttonCloseDataTable} from '../global/viewButtonSelection';
import {
    useAddProjectTypeMutation,
    useUpdateProjectTypeMutation,
    useLazyGetAllElementTypesDataQuery,
    useAddElementTypeMutation,
    useUpdateElementTypeMutation,
    useLazyGetAllElementFieldTypesDataQuery,
    useAddElementFieldTypeMutation,
    useUpdateElementFieldTypeMutation
} from '../../redux/rtk/injectedProjectTypes';
import {
    useLazyGetAllInspectionTaskTypesQuery,
    useAddTaskTypeMutation,
    useUpdateTaskTypeMutation,
    useLazyGetAllInspectionTypesQuery,
    useAddInspectionTypeMutation,
    useUpdateInspectionTypeMutation,

} from '../../redux/rtk/injectedInspectionApi';
import ProjectElementTypeEditForm from './ProjectElementTypeEditForm';
import ProjectElementFieldTypeEditForm from './ProjectElementFieldTypeEditForm';
import CustomerTypeEditForm from './CustomerTypeEditForm';
import ProjectTypeEditForm from './ProjectTypeEditForm';
import InspectionTaskTypeEditForm from './InspectionTaskTypeEditForm';
import InspectionTypeEditForm from './InspectionTypeEditForm';
import {ProjectTypeEditForm_New} from "./ProjectTypeEditForm_New";
import {ProjectElementTypeEditForm_New} from "./ProjectElementTypeEditForm_New";
import {ProjectElementFieldTypeEditForm_New} from "./ProjectElementFieldTypeEditForm_New";
import {CustomerTypeEditForm_New} from "./CustomerTypeEditForm_New";
import {InspectionTaskTypeEditForm_New} from "./InspectionTaskTypeEditForm_New";

type Props = {
    viewType: string,
    showEdit: boolean,
    setShowEdit: (value: boolean) => void
}

type ElementType = {
    id: number;
    name: string;
    project_type_id: string;
    has_parent: boolean;
    icon: string;
    multi_parents: boolean;
    location_supported: boolean;
    show_as_tree: boolean;
    has_inspection: boolean;
    name_field_label: string;
    children_type_ids: any[];
    sibling_type_ids: any[]
};

type ElementFieldType = {
    id: number,
    element_type_id: string,
    name: string,
    data_type: string,
    object_type: string,
    api_call: string,
    count: number,
    check_value: string,
    element_subtype_id: string,
};

type TaskType = {
    id: number;
    name: string;
    project_type_id: string;
};

type InspectionType = {
    id: number;
    name: string;
    project_type_id: string;
    cycle_in_month: string;
    element_type_id: string;
};

const CreateProjectTypeForm = ({viewType, showEdit, setShowEdit}: Props): JSX.Element => {
    const {t} = useTranslation(['common']);
    const [activeIndex, setActiveIndex] = useState(0);
    const [cardsData, setCardsData] = useState<any[]>([]);
    const [existingProjectTypes, setExistingProjectTypes] = useState<{
        id: number,
        name: string,
        projectStyleTypes: string
    }[]>([]);
    const [existingElementTypes, setExistingElementTypes] = useState<{
        id: number;
        projectTypeId: string;
        element_type_id: string;
        checkboxes: {
            hasParent: boolean,
            showAsTree: boolean,
            multiParents: boolean,
            locationSupport: boolean,
            hasInspection: boolean
        },
        selectedIcon: string,
        elements: any[],
        elementList: any[],
        elementsSibling: any[],
        elementSiblingList: any[],
        nameFieldLabel: string
    }[]>([]);
    const [existingElementFieldTypes, setExistingElementFieldTypes] = useState<{
        id: number,
        projectTypeId: string,
        projectElementTypeId: string,
        elementFieldTypeName: string,
        elementFieldTypeCount: string,
        fieldTypeData: any,
        fieldTypeObject: any,
        checkValue: string,
        fieldSubtype: string
    }[]>([]);
    const [existingTaskTypes, setExistingTaskTypes] = useState<{
        id: number,
        name: string,
        projectTypeId: string
    }[]>([]);
    const [existingInspectionTypes, setExistingInspectionTypes] = useState<{
        id: number,
        name: string,
        projectTypeId: string,
        cycle_in_month: string;
        element_type_id: string;
    }[]>([]);
    const [triggerLoadProjectTypes, setTriggerLoadProjectTypes] = useState<boolean>(false);
    const [triggerLoadElementTypes, setTriggerLoadElementTypes] = useState<boolean>(false);
    const [triggerLoadElementFieldTypes, setTriggerLoadElementFieldTypes] = useState<boolean>(false);
    const [triggerLoadTaskTypes, setTriggerLoadTaskTypes] = useState<boolean>(false);
    const [triggerLoadInspectionTypes, setTriggerLoadInspectionTypes] = useState<boolean>(false);
    const [addProjectType] = useAddProjectTypeMutation();
    const [updateProjectType] = useUpdateProjectTypeMutation();
    const [getElementType] = useLazyGetAllElementTypesDataQuery();
    const [addElementType] = useAddElementTypeMutation();
    const [updateElementType] = useUpdateElementTypeMutation();
    const [getElementFieldType] = useLazyGetAllElementFieldTypesDataQuery();
    const [addElementFieldType] = useAddElementFieldTypeMutation();
    const [updateElementFieldType] = useUpdateElementFieldTypeMutation();
    const [getInspectionTaskType] = useLazyGetAllInspectionTaskTypesQuery();
    const [addTaskType] = useAddTaskTypeMutation();
    const [updateTaskType] = useUpdateTaskTypeMutation();
    const [getInspectionType] = useLazyGetAllInspectionTypesQuery();
    const [addInspectionType] = useAddInspectionTypeMutation();
    const [updateInspectionType] = useUpdateInspectionTypeMutation();
    const [clear, setClear] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false); // true = non-stop-loading
    const [projectsLoaded, setProjectsLoaded] = useState<boolean>(false);
    const [selectedProjectTypeId, setSelectedProjectTypeId] = useState<any>();
    const [selectedElementTypeId, setSelectedElementTypeId] = useState<any>();

    const [reload, setReload] = useState(false);
    const [selectedCustomerType, setSelectedCustomerType] = useState(null);
    const [showCustomerTypeDialog, setShowCustomerTypeDialog] = useState(false);
    const [showBottomButtons, setShowBottomButtons] = useState(false);

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        if (reload && window.location.href.includes('typeManagement_3')) {
            setShowButton(true);
        } else {
            setShowButton(false);
            setReload(false);
        }
    }, [reload, window.location.href]);

    const defaultValues = {
        'items': [],
    };

    const {control, handleSubmit, formState: {errors}, reset} = useForm({defaultValues});

    useEffect(() => {
        switch (viewType) {
            case 'projectTypes':
                setShowBottomButtons(false);
                break;
            case 'elementTypes':
                setShowBottomButtons(false);
                break;
            case 'elementFieldTypes':
                setShowBottomButtons(false);
                break;
            case 'customerTypes':
                setShowBottomButtons(false);
                break;
            case 'inspectionTaskTypes':
                setShowBottomButtons(false);
                break;
            case 'inspectionTypes':
                setShowBottomButtons(true);
                break;
        }
    }, [viewType]);

    const renderContent = useCallback(() => {
        switch (viewType) {
            case 'projectTypes':
                return <ProjectTypeEditForm_New showEdit={showEdit} setShowEdit={setShowEdit}/>;
            // return <ProjectTypeEditForm loadedProjectTypes={existingProjectTypes}
            //                             triggerLoadProjectTypes={triggerLoadProjectTypes}
            //                             setLoadedProjectTypes={setExistingProjectTypes}
            //                             setProjectsLoaded={setProjectsLoaded} setCardsData={setCardsData}
            //                             onCloseAllCards={closeAllCards} clear={clear} onClear={() => {
            //     setClear(false);
            // }} form={{control, handleSubmit, formState: {errors}}} project={''}/>;
            case 'elementTypes':
                return <ProjectElementTypeEditForm_New showEdit={showEdit} setShowEdit={setShowEdit}/>;
            // return <ProjectElementTypeEditForm setSelectedProjectTypeId={setSelectedProjectTypeId}
            //                                    triggerLoadElementTypes={triggerLoadElementTypes}
            //                                    setLoadedElementTypes={setExistingElementTypes}
            //                                    setProjectsLoaded={setProjectsLoaded} setCardsData={setCardsData}
            //                                    onCloseAllCards={closeAllCards} clear={clear} onClear={() => {
            //     setClear(false);
            // }} form={{control, handleSubmit, formState: {errors}}} project={''}/>;
            case 'elementFieldTypes':
                return <ProjectElementFieldTypeEditForm_New showEdit={showEdit} setShowEdit={setShowEdit}/>;
            // return <ProjectElementFieldTypeEditForm setSelectedProjectTypeId={setSelectedProjectTypeId}
            //                                         setSelectedElementTypeId={setSelectedElementTypeId}
            //                                         triggerLoadElementFieldTypes={triggerLoadElementFieldTypes}
            //                                         setLoadedElementFieldTypes={setExistingElementFieldTypes}
            //                                         setProjectsLoaded={setProjectsLoaded}
            //                                         setCardsData={setCardsData} onCloseAllCards={closeAllCards}
            //                                         clear={clear} onClear={() => {
            //     setClear(false);
            // }} form={{control, handleSubmit, formState: {errors}}} project={''}/>;
            case 'customerTypes':
                return <CustomerTypeEditForm_New showEdit={showEdit} setShowEdit={setShowEdit}/>
            // return <CustomerTypeEditForm reload={reload} setReload={setReload}
            //                              setSelectedCustomerType={setSelectedCustomerType}
            //                              setShowCustomerTypeDialog={setShowCustomerTypeDialog}
            //                              onCloseDataTable={closeDataTable} clear={clear} onClear={() => {
            //     setClear(false);
            // }}/>;
            case 'inspectionTaskTypes':
                return <InspectionTaskTypeEditForm_New showEdit={showEdit} setShowEdit={setShowEdit}/>;
            case 'inspectionTypes':
                return <InspectionTypeEditForm setSelectedProjectTypeId={setSelectedProjectTypeId}
                                               setSelectedElementTypeId={setSelectedElementTypeId}
                                               triggerLoadInspectionTypes={triggerLoadInspectionTypes}
                                               setLoadedInspectionTypes={setExistingInspectionTypes}
                                               setProjectsLoaded={setProjectsLoaded} setCardsData={setCardsData}
                                               onCloseAllCards={closeAllCards} clear={clear} onClear={() => {
                    setClear(false);
                }} form={{control, handleSubmit, formState: {errors}}} project={''}/>;
            default:
                return null;
        }
    }, [viewType, showEdit]);

    const saveData = async (viewType: string, selectedProjectTypeId: string, selectedElementTypeId: string) => {
        try {
            setIsLoading(true);
            switch (viewType) {
                case 'inspectionTypes': {
                    // [InspectionTypeEditForm]
                    const {data: result} = await getInspectionType({elementTypeId: selectedElementTypeId});
                    const existingNames = new Set(result.map((type: InspectionType) => type.name));

                    const existingInspectionTypes: InspectionType[] = result.map((type: InspectionType) => ({
                        id: type.id,
                        name: type.name,
                        projectTypeId: type.project_type_id,
                        cycleInMonth: type.cycle_in_month,
                        elementTypeId: type.element_type_id,
                    }));

                    const inspectionTypesToAdd: {
                        name: string,
                        project_type_id: string,
                        cycle_in_month: string,
                        element_type_id: string
                    }[] = [];
                    const inspectionTypesToUpdate: {
                        id: number,
                        name: string,
                        project_type_id: string,
                        cycle_in_month: string,
                        element_type_id: string
                    }[] = [];

                    cardsData.forEach(card => {
                        const existingType = existingInspectionTypes.find((type: InspectionType) => type.id === card.id);

                        if (existingType) {
                            inspectionTypesToUpdate.push({
                                id: existingType.id,
                                name: card.inspectionTypeName,
                                project_type_id: card.projectTypeId,
                                cycle_in_month: card.cycleInMonth,
                                element_type_id: card.projectElementTypeId,
                            });
                        } else if (!existingNames.has(card.inspectionTypeName)) {
                            inspectionTypesToAdd.push({
                                name: card.inspectionTypeName,
                                project_type_id: card.projectTypeId,
                                cycle_in_month: card.cycleInMonth,
                                element_type_id: card.projectElementTypeId,
                            });
                        }
                    });

                    const addPromises = inspectionTypesToAdd.length > 0 ? addInspectionType(inspectionTypesToAdd) : Promise.resolve();
                    const updatePromises = inspectionTypesToUpdate.length > 0
                        ? Promise.all(inspectionTypesToUpdate.map(update => updateInspectionType(update)))
                        : Promise.resolve();

                    await Promise.all([addPromises, updatePromises]);

                    if (inspectionTypesToAdd.length > 0) {
                        showMessageOnSuccess(t('success'), t('dialog_inspectionTypes_added'));
                    }

                    if (inspectionTypesToUpdate.length > 0) {
                        showMessageOnSuccess(t('success'), t('dialog_inspectionTypes_updated'));
                    }

                    setTriggerLoadInspectionTypes(!triggerLoadInspectionTypes);
                    break;
                }
            }
        } catch (error) {
            console.error('Error saving data:', error);
            showMessageOnError(t('error'), t('dialog_error_addingTypes'));
        } finally {
            setIsLoading(false);
        }
    };

    const closeAllCards = () => {
        setClear(true);
        setProjectsLoaded(false);
        reset({items: []});
        setCardsData([]);

        setExistingProjectTypes([]);
        setExistingElementTypes([]);
        setExistingElementFieldTypes([]);
    };

    const closeDataTable = () => {
        setReload(false);
        setClear((prevClear) => !prevClear);
    };

    useEffect(() => {
        // Tab wechsel = Formular zurücksetzen
        reset({items: []});
        setCardsData([]);
    }, [viewType, activeIndex, reset]);

    const mainContentCardStyles: React.CSSProperties = {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: '1rem',
        margin: '1rem',
        flexGrow: '1',
    };

    const stepperNavigationCardStyles: React.CSSProperties = {
        position: 'absolute',
        left: 7,
        right: 7,
        bottom: 0,
        margin: '1rem',
        minHeight: '4.5rem'
    };

    return (
        <>
            {isLoading && <ProgressSpinner className="absolute left-0 right-0 top-0 bottom-0 m-auto z-1"/>}
            <form id="projectForm" style={{height: '100%'}}
                  onSubmit={handleSubmit(() => saveData(viewType, selectedProjectTypeId, selectedElementTypeId))}>
                <div className="grid" style={{height: '100%'}}>
                    <div className="col-12">
                        {/* Card for stepper */}
                        {/* <div className="card">
                            <Steps model={interactiveItems} activeIndex={activeIndex}
                                onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
                        </div> */}
                        {/* Card for main content */}
                        <div style={mainContentCardStyles}>
                            {renderContent()}
                            {/*
                            {activeIndex === 0 && <ProjectTypeEditForm loadedProjectTypes={existingProjectTypes} triggerLoadProjectTypes={triggerLoadProjectTypes} setLoadedProjectTypes={setExistingProjectTypes} setCardsData={setCardsData} form={ {control, handleSubmit, formState: { errors } }} project="" />}
                            {activeIndex === 1 && <ProjectElementTypeEditForm triggerLoadElementTypes={triggerLoadElementTypes} setLoadedElementTypes={setExistingElementTypes} setCardsData={setCardsData} form={ {control, handleSubmit, formState: { errors } }} project="" />}
                            {activeIndex === 2 && <ProjectElementFieldTypeEditForm triggerLoadElementFieldTypes={triggerLoadElementFieldTypes} setLoadedElementFieldTypes={setExistingElementFieldTypes} setCardsData={setCardsData} form={ {control, handleSubmit, formState: { errors } }} project="" />}
                            */}
                        </div>
                        {/* Card for stepper navigation */}
                        {showBottomButtons && <div className="card" style={stepperNavigationCardStyles}>
                            <div className="flex justify-content-end">
                                {cardsData.length > 0 && (
                                    <>
                                        <Button type='button' className={buttonCloseAllCards.className}
                                                onClick={() => {
                                                    CustomConfirmDialog({
                                                        message: t('typeM_closeAllCards'),
                                                        header: t('typeM_closeAllCards_header'),
                                                        translation: t,
                                                        onConfirm: closeAllCards,
                                                    });
                                                }}
                                        >
                                            <FontAwesomeIcon icon={buttonCloseAllCards.icon} className="mr-2"/>
                                            {t(buttonCloseAllCards.label)}
                                        </Button>
                                        <Button className={buttonSaveProjectType.className}>
                                            <FontAwesomeIcon icon={buttonSaveProjectType.icon}
                                                             className="mr-2"/>
                                            {t(buttonSaveProjectType.label)}
                                        </Button>
                                    </>
                                )}
                                {showButton && (
                                    <Button type='button' className={buttonCloseDataTable.className}
                                            onClick={() => {
                                                CustomConfirmDialog({
                                                    message: t('close_dataTable_dialog'),
                                                    header: t('close_dataTable_dialogHeader'),
                                                    translation: t,
                                                    onConfirm: closeDataTable,
                                                });
                                            }}
                                    >
                                        <FontAwesomeIcon icon={buttonCloseDataTable.icon} className="mr-2"/>
                                        {t(buttonCloseDataTable.label)}
                                    </Button>
                                )}
                                {/* forward/back navigation */}
                                {/* <div>
                                    {activeIndex > 0 && <Button onClick={() => {
                                        setActiveIndex(activeIndex - 1);
                                    }}>{t('back')}</Button>}
                                    {activeIndex < steps.length - 1 && <Button onClick={() => {
                                        setActiveIndex(activeIndex + 1);
                                    }}>{t('next')}</Button>}
                                </div> */}
                            </div>
                        </div>}
                    </div>
                </div>
            </form>
        </>
    );
};

export default CreateProjectTypeForm;
