/*
 * SkeletonProjectOverview.tsx
 * Author: lnappenfeld
 * Date: 23.10.2024
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState} from 'react';
import {DataView, DataViewLayoutOptions, DataViewLayoutType} from 'primereact/dataview';
import {checkPermission} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import { Skeleton } from 'primereact/skeleton';


const SkeletonProjectOverview = (): JSX.Element => {

    // Projekte die nach der Filterung in der Liste angezeigt werden
    const [projects, setProjects] = useState([[],[],[],[],[],[],[],[]]);

    const [layout, setLayout] = useState<DataViewLayoutType>('grid');
    const ableToSeeAllProjects = checkPermission(permissions.seeAllProjects);

    const columnWidth = {
        number: '15%',
        name: '20%',
        comment: '40%',
        type: '20%'
    };

    const renderListItem = (project: any) => {
        return (
            <div className="col-12">
                <div
                    className="flex flex-column md:flex-row align-items-center p-2 w-full cursor-pointer"
                    style={{border: '3px solid var(--surface-border)', borderWidth: '0 0 1px 0'}}
                >
                    <table style={{width: '100%'}}>
                        <tr>
                            <td style={{width: columnWidth.number}}><Skeleton width="8.5rem" height="1.5rem"></Skeleton></td>
                            <td style={{width: columnWidth.name}}><Skeleton width="17rem" height="1.5rem"></Skeleton></td>
                            <td style={{width: columnWidth.comment}}><Skeleton width="25rem" height="1.5rem"></Skeleton></td>
                            <td style={{width: columnWidth.type}}><Skeleton width="17rem" height="1.5rem"></Skeleton></td>
                            {ableToSeeAllProjects &&
                                <td className={'flex sm:flex-column md:flex-column md:align-items-end'}><Skeleton width="2rem" height="1.5rem"></Skeleton></td>}
                        </tr>
                    </table>
                </div>
            </div>
        );
    };

    const renderGridItem = (project: any) => {
        return (
            <div className="col-12 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                <div
                    className={'card m-2 project-card border-1 surface-border'}
                    // style move into a file
                    style={{maxHeight: '250px', minHeight: '250px', overflow: 'hidden'}}>
                    <div className="flex align-items-center justify-content-between mb-3">
                        <div className="flex align-items-center">
                            <Skeleton width="1rem" height="1rem"></Skeleton>
                            <span className="project-type ml-2"><Skeleton width="10rem" height="1rem"></Skeleton></span>
                        </div>

                    </div>
                    <div className="product-grid-item-content align-items-center" style={{textAlign: 'center'}}>
                        <Skeleton width="27rem" height="5rem"></Skeleton>
                        <div className="flex align-items-center justify-content-center  font-bold text-white border-round m-3">
                            <Skeleton width="18rem" height="2.5rem"></Skeleton></div>
                        <div className="flex align-items-center justify-content-center ">
                            <Skeleton width="22rem" height="1rem"></Skeleton></div>
                    </div>
                    <div className="product-grid-item-bottom">
                        <span className="project-number"><Skeleton width="7.5rem" height="1rem"></Skeleton></span>
                    </div>
                </div>
            </div>

        );
    };

    const itemTemplate = (project: any, layout: any) => {
        if (!project) {
            return;
        }

        if (layout === 'list')
            return renderListItem(project);
        else if (layout === 'grid')
            return renderGridItem(project);
    };


    const renderHeader = () => {
        return (
            <div
                className="grid-nogutter pb-2"
                style={{border: '3px solid var(--surface-border)', borderWidth: '0 0 1px 0'}}
            >
                <div className="grid grid-nogutter pt-1">
                    <div className="col-10" style={{textAlign: 'left'}}>
                        <div className="grid">
                            <div className='search-input p-2'>
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search"/>
                                    <Skeleton width="14.2rem" height="2.5rem"></Skeleton>
                                </span>
                            </div>
                            {ableToSeeAllProjects &&
                                <>
                                    <div className="p-2">
                                        <Skeleton width="14.2rem" height="2.5rem"></Skeleton>
                                    </div>
                                    <div className="p-2" style={{width: '150px'}}>
                                        <Skeleton width="5rem" height="2.5rem"></Skeleton>
                                    </div>
                                </>}
                        </div>
                    </div>
                    <div className="col-2" style={{textAlign: 'right'}}>
                        <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)}/>
                    </div>
                </div>
                {layout === 'list' &&
                    <div
                        className="flex flex-column md:flex-row align-items-center p-2 w-full cursor-pointer"
                        style={{border: '3px solid var(--surface-border)', borderWidth: '1px 0 1px 0'}}
                    >
                        <table style={{width: '100%'}}>
                            <tr>
                                <td style={{width: columnWidth.number}}><Skeleton width="8.5rem" height="1.5rem"></Skeleton></td>
                                <td style={{width: columnWidth.name}}><Skeleton width="17rem" height="1.5rem"></Skeleton></td>
                                <td style={{width: columnWidth.comment}}><Skeleton width="25rem" height="1.5rem"></Skeleton></td>
                                <td style={{width: columnWidth.type}}><Skeleton width="17rem" height="1.5rem"></Skeleton></td>
                                {ableToSeeAllProjects &&
                                    <td className={'flex sm:flex-column md:flex-column md:align-items-end'}><Skeleton width="2rem" height="1.5rem"></Skeleton></td>}
                            </tr>
                        </table>
                    </div>}
            </div>
        );
    };

    return (
        <div className="project-overview card w-auto h-auto m-0">
            {renderHeader()}
            <div className={'relative w-full h-full'}>
                <DataView
                    value={projects}
                    layout={layout}
                    itemTemplate={itemTemplate}
                />
            </div>
        </div>
    );
};

export default SkeletonProjectOverview;
