import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {faCirclePlus, faListCheck, faPen, faTimes, faLanguage} from '@fortawesome/free-solid-svg-icons';
import {
    useLazyGetAllElementTypesDataQuery,
    useLazyGetElementTypesGlobalQuery,
    useGetProjectTypesQuery,
    useAddElementTypeMutation,
    useUpdateElementTypeMutation, useLazyGetAllElementTypesWithoutFieldsQuery
} from '../../redux/rtk/injectedProjectTypes';
import Input from '../global/Input';
import ProjectTypeSelectList from './ProjectTypeSelectList';
import CustomDataTable from '../global/CustomDataTable';
import {
    Project_ElementType,
    getProjectElementTypesColumns, getProjectTypesFilters, getElementTypesFilters
} from '../functions/TypeManagement';
import CustomDialog from '../global/CustomDialog';
import {showMessageOnSuccess} from '../global/CustomToast';
import {useLazyGetAllInspectionTaskTypesGlobalQuery} from "../../redux/rtk/injectedInspectionApi";

type Props = {
    showEdit: boolean,
    setShowEdit: (value: boolean) => void
}

export const ProjectElementTypeEditForm_New = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);
    const navigate = useNavigate();

    const [selectedItem, setSelectedItem] = useState<Project_ElementType | undefined>(undefined);
    const [showDialog, setShowDialog] = useState<boolean>(props.showEdit);
    const [icons, setIcons] = useState<any>([]);
    const [globalElementTypes, setGlobalElementTypes] = useState<any[]>([]);

    const projectTypes = useGetProjectTypesQuery({});
    const [triggerGlobalElementTypes, globalElementTypesResult] = useLazyGetElementTypesGlobalQuery();
    const [triggerGetElementTypes, elementTypesResult] = useLazyGetAllElementTypesDataQuery();
    const [triggerGetInspectionTaskTypes, inspectionTaskTypesResult] = useLazyGetAllInspectionTaskTypesGlobalQuery();

    const [addProjectElementType] = useAddElementTypeMutation();
    const [updateProjectElementType] = useUpdateElementTypeMutation();

    const defaultValues: Project_ElementType = {
        has_inspection: false,
        has_parent: false,
        icon: '',
        id: 0,
        location_supported: false,
        multi_parents: false,
        name: '',
        name_field_label: '',
        project_type_id: 0,
        show_as_tree: false,
        children_type_ids: [],
        sibling_type_ids: [],
        inspection_task_type_ids: [],
    };

    const {control, formState: {errors}, handleSubmit, setValue, getValues, watch, reset} = useForm({defaultValues});

    const selectedProjectTypeId = watch('project_type_id');

    useEffect(() => {
        if (globalElementTypesResult?.data) {
            setGlobalElementTypes(globalElementTypesResult.data.map((item: any) => {
                return {value: item.element_type_id, label: item.name};
            }));
        }
    }, [globalElementTypesResult]);

    useEffect(() => {
        triggerGetElementTypes({});
        (async () => {
            const iconResult: any[] = [];

            await fetch('/fontawesome5/metadata/icons.json').then(r => r.json()).then(result => {
                    for (let i = 0; i < Object.keys(result).length; i++) {
                        const key = Object.keys(result)[i];
                        const icon = result[key];
                        if (icon.styles.indexOf('brands') < 0) {
                            iconResult.push('fa fa-' + key);
                        }
                    }
                }
            );
            await fetch('/icons/fonts/icons.json').then(r => r.json()).then(result => {
                    for (let i = 0; i < Object.keys(result).length; i++) {
                        const key = Object.keys(result)[i];
                        iconResult.push('icon-' + key);
                    }
                }
            );
            setIcons(iconResult);
        })();
    }, []);

    useEffect(() => {
        setShowDialog(props.showEdit);
    }, [props.showEdit]);

    useEffect(() => {
        setGlobalElementTypes([]);
        if (selectedProjectTypeId) {
            triggerGlobalElementTypes({
                locale: 'de',
                projectTypeId: selectedProjectTypeId
            });
            triggerGetInspectionTaskTypes({
                projectTypeId: selectedProjectTypeId
            });
        }
    }, [selectedProjectTypeId]);


    const getFormErrorMessage = (name: string) => {
        if (name.indexOf('.') >= 0) {
            const split = name.split('.');

            if (split.length === 3) {
                // @ts-ignore
                if (errors[split[0]] && errors[split[0]][split[1]] && errors[split[0]][split[1]][split[2]]) {
                    // @ts-ignore
                    const text = errors[split[0]][split[1]][split[2]].message;

                    return <small className="p-error custom">{text}</small>;
                }
            }
        } else {
            // @ts-ignore
            if (errors[name]) {
                // @ts-ignore
                const text = errors[name].message;

                return <small className="p-error custom">{text}</small>;
            }
        }
        return undefined;
    };

    const nameFieldLabelValidation = (t: any) => {
        return {
            pattern: {
                value: /^[a-zA-ZäöüÄÖÜ_]*$/,
                message: t('input:invalidNameFieldLabel')
            }
        };
    };

    const onEditClick = useCallback((data: Project_ElementType) => {
        setSelectedItem(data);
        reset(data);
        setShowDialog(true);
    }, []);

    const onDeleteClick = useCallback(() => {

    }, []);

    const hideDialog = () => {
        setShowDialog(false);
        setTimeout(() => {
            setSelectedItem(undefined);
            reset(defaultValues);
            props.setShowEdit(false);
        }, 200);
    };

    const onSave = (data: Project_ElementType) => {
        if (selectedItem) {
            updateProjectElementType(data).then((res: any) => {
                if (!res.error) {
                    showMessageOnSuccess(t('success'), t('saved'));
                    triggerGetElementTypes({});
                    hideDialog();
                }
            });
        } else {
            addProjectElementType([data]).then((res: any) => {
                if (!res.error) {
                    showMessageOnSuccess(t('success'), t('saved'));
                    triggerGetElementTypes({});
                    hideDialog();
                }
            });
        }
    };

    return (
        <>
            <CustomDataTable id={'project_element_types'} headerTitle={t('element_types')}
                             columns={getProjectElementTypesColumns(t, {projectTypes: projectTypes.data})}
                             filters={getElementTypesFilters()}
                             editable={true}
                             sortable={true}
                             paginator={true}
                             value={elementTypesResult.data} dataloaded={elementTypesResult.data == null}
                             ableToUpdate={true}
                             onEditClick={onEditClick}
                // ableToDelete={true}
                             onRemoveClick={onDeleteClick}/>
            <CustomDialog header={selectedItem ?
                <div className='flex'><i className='pi pi-pencil my-auto mr-2'/>{t('element_type_edit')}
                </div> : <div className='flex'><i className='pi pi-plus my-auto mr-2'/>{t('element_type_new')}
                </div>}
                          visible={showDialog}
                          onCancel={hideDialog} formName={'elementTypeForm_New'} style={{maxWidth: '800px'}}>
                <form id='elementTypeForm_New' onSubmit={handleSubmit(onSave)}>
                    <div className="card">
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <ProjectTypeSelectList
                                    disabled={selectedItem != null}
                                    label={t('projM_projectTypeName')}
                                    name={'project_type_id'}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('input_required')}}
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <Input
                                    edit={true}
                                    label={t('name_field_label')}
                                    name={'name_field_label'}
                                    type={'text'}
                                    tooltip={t('projM_tooltip_translation')}
                                    labelIcon={<FontAwesomeIcon className="sidebar-icon" icon={faLanguage}
                                                                onClick={() => {
                                                                    CustomConfirmDialog({
                                                                        message: t('typeM_goToTranslationTool'),
                                                                        header: t('typeM_goToTranslationTool_header'),
                                                                        translation: t,
                                                                        onConfirm: () => {
                                                                            navigate('/#languageManagement');
                                                                        },
                                                                    });
                                                                }}/>}
                                    labelIconTooltip={t('translation_icon_tooltip')}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{...{required: t('input_required'), ...nameFieldLabelValidation(t)}}}
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <Input
                                    edit={true}
                                    label={t('elementTypeName')}
                                    name={'name'}
                                    type={'text'}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('input_required')}}
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <Input
                                    options={{
                                        valueTemplate: (item: any) => {
                                            if (item) {
                                                return (<div className={'flex'}><i
                                                    className={'mr-2 mt-1 ' + item?.value}/>{item?.value}
                                                </div>);
                                            }
                                            return 0;
                                        }, itemTemplate: (item: any) => {
                                            return (
                                                <div className={'flex'}><i
                                                    className={'mr-2 mt-1 ' + item.value}/>{item.value}
                                                </div>);
                                        },
                                    }}
                                    dropdownOptions={icons.map((item: any) => {
                                        return {value: item, label: item};
                                    })}
                                    edit={true}
                                    type={'dropdown'}
                                    showClear={true}
                                    name={'icon'}
                                    label={t('icon')}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                            </div>

                            <div className="col-12 md:col-6">
                                <Input
                                    dropdownOptions={globalElementTypes}
                                    edit={true}
                                    label={t('children_type_ids')}
                                    name={'children_type_ids'}
                                    type="multiselect"
                                    options={{display: 'comma'}}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <Input
                                    dropdownOptions={globalElementTypes}
                                    edit={true}
                                    label={t('sibling_type_ids')}
                                    name={'sibling_type_ids'}
                                    type="multiselect"
                                    options={{display: 'comma'}}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <Input
                                    dropdownOptions={inspectionTaskTypesResult.data ? inspectionTaskTypesResult.data.map((item: any) => {
                                        return {value: item.id, label: item.name}
                                    }) : []}
                                    edit={true}
                                    label={t('inspection_task_type_ids')}
                                    name={'inspection_task_type_ids'}
                                    type="multiselect"
                                    // options={{
                                    //     selectedItemTemplate: (rowData: any) => {
                                    //         console.log('rowData', rowData);
                                    //         return <div>{rowData}</div>;
                                    //     }
                                    // }}
                                    options={{display: 'comma'}}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                            </div>
                        </div>

                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <Input edit={true} name={'has_parent'}
                                       label={t('elementType_tree_view')}
                                       type={'checkbox'}
                                       validationControl={control}
                                       validationErrorMessage={getFormErrorMessage}
                                       validationRules={{required: false}}/>
                            </div>

                            <div className="col-12 md:col-6">
                                <Input edit={true} name={'show_as_tree'}
                                       label={t('show_as_tree')}
                                       type={'checkbox'}
                                       validationControl={control}
                                       validationErrorMessage={getFormErrorMessage}
                                       validationRules={{required: false}}/>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <Input edit={true} name={'multi_parents'}
                                       label={t('multi_parents')}
                                       type={'checkbox'}
                                       validationControl={control}
                                       validationErrorMessage={getFormErrorMessage}
                                       validationRules={{required: false}}/>
                            </div>

                            <div className="col-12 md:col-6">
                                <Input edit={true} name={'location_supported'}
                                       label={t('location_supported')}
                                       type={'checkbox'}
                                       validationControl={control}
                                       validationErrorMessage={getFormErrorMessage}
                                       validationRules={{required: false}}/>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <Input edit={true} name={'has_inspection'}
                                       label={t('has_inspection')}
                                       type={'checkbox'}
                                       validationControl={control}
                                       validationErrorMessage={getFormErrorMessage}
                                       validationRules={{required: false}}/>
                            </div>
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </>
    );
};
