import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {getInspectionColumns, getInspectionFilters, Inspection, InspectionTaskType} from '../functions/Inspection';
import {
    useDeleteInspectionMutation,
    useGetInspectionsQuery
} from '../../redux/rtk/injectedInspectionApi';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faList} from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from 'react-router-dom';
import {InspectionData} from './InspectionData';
import {checkPermission} from "../../functions/functionLibrary";
import {permissions} from "../../config/permissions";
import {CustomConfirmDialog} from "../global/CustomConfirmDialog";
import CustomDialog from "../global/CustomDialog";
import {InspectionEditForm} from "./InspectionEditForm";
import {resetElementChanges} from "../../redux/slices/ElementChangesSlice";
import {useAppDispatch} from "../../redux/hooks";

type Props = {
    project: any
}

export const InspectionOverview = (props: Props) => {
    const {t} = useTranslation(['common']);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const [values, setValues] = useState<any>(undefined);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [expandedRows, setExpandedRows] = useState<any[]>([]);
    const [selectedInspectionId, setSelectedInspectionId] = useState<string>('');
    const [inspectionTypes, setInspectionTypes] = useState<any[]>([]);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [editInspection, setEditInspection] = useState<Inspection | undefined>(undefined);

    const [deleteInspection, resultDeleteInspection] = useDeleteInspectionMutation();

    const inspectionsQuery = useGetInspectionsQuery({projectId: props.project.id});

    // const inspectionTypesQuery = useGetInspectionTypeQuery({projectId: props.project.id});

    useEffect(() => {
        const hash = window.location.hash.split('_');

        if (hash.length === 2) {
            setSelectedInspectionId(hash[1]);
        }
    }, [window.location.hash]);

    // useEffect(() => {
    //     if (inspectionTypesQuery.data) {
    //         setInspectionTypes(inspectionTypesQuery.data);
    //     }
    // }, [inspectionTypesQuery.data]);

    useEffect(() => {
        if (inspectionsQuery && inspectionsQuery.data) {
            const temp = [...inspectionsQuery.data];
            for (let i = 0; i < temp.length; i++) {
                const item = {...temp[i]};

                item['expandableElement'] = <InspectionData project={props.project} inspectionId={item.id}/>;

                temp[i] = item;
            }

            setValues(temp);

            // if (temp.length) {
            //     setExpandedRows([temp[2]])
            // }
            setDataLoaded(true);
        }
    }, [inspectionsQuery]);

    useEffect(() => {
        setDataLoaded(values != null);

        if (values && selectedInspectionId) {
            const expandedValue = values.find((item: any) => item.id === selectedInspectionId);
            if (expandedValue) {
                setExpandedRows([expandedValue]);
            }
        }
    }, [values]);


    const onRowExpand = useCallback((e) => {
        setExpandedRows([e.data]);
        window.location.hash = window.location.hash.split('_')[0] + '_' + e.data.id;
    }, []);

    const onRowCollapse = useCallback((e) => {
        window.location.hash = window.location.hash.split('_')[0];
    }, []);

    const expandedRowsLength = useMemo(() => {
        return expandedRows.length > 0;
    }, [expandedRows]);

    const customButtons = useMemo(() => {

        let jumpToElementButton = {
            icons: [{key: null, value: null, icon: <FontAwesomeIcon icon={faList}/>}],
            tooltip: t('inspection_show_tree'),
            tooltipOptions: {position: 'top'},
            onClick: (data: any) => {
                const url = `/project/${props.project.id}/#unittree_${data['element_id']}`;

                navigate(url);
                // window.location.reload();
            },
            permitted: true,
        };

        return checkPermission(permissions.seeElements) ? [jumpToElementButton] : [];
    }, []);

    // Zeigt neben den beiden Spalten mit den customButton 'inspection_show_tree' und zum Aufklappen der Zeile,
    // zumindest auch das Skeletonloading für die Spalte 'Status'. Danach werden abhängig von den Daten die Spalten
    // mit den geladenen Daten angezeigt, die zum Zeitpunkt des Skeletonloadings aber noch nicht da sind
    const randomSkeletonColumn: ColumnObject[] = [
        {field: 'inspection_status', header: t('inspection_columns_inspection_status')}
    ];

    const hideDialog = () => {
        setEditInspection(undefined);
        setShowEditDialog(false);
        dispatch(resetElementChanges());
    }

    const editClick = (dataRow: any) => {
        setEditInspection(dataRow);
        setShowEditDialog(true);
    }

    const removeClick = (dataRow: any) => {
        CustomConfirmDialog({
            header: t('dialogs_removeInspectionHeader'),
            message: t('dialogs_removeInspectionMessage'),
            translation: t,
            onConfirm: () => {
                deleteInspection({inspection_id: dataRow['id']});
            }
        });
    };

    return (<>
            <div className={'h-full'}>
                <CustomDataTable
                    id={'inspectinos_datatable'}
                    columns={dataLoaded ? getInspectionColumns(t, values, inspectionTypes) : randomSkeletonColumn}
                    filters={getInspectionFilters()}
                    editable={true}
                    headerTitle={t('inspections_overview')}
                    displayColumnFilter={true}
                    dataloaded={dataLoaded}
                    sortable={true}
                    value={values}
                    expandable={true}
                    expandedRows={expandedRows}
                    scrollToFirstExpanded={expandedRowsLength}
                    scrollToFirstExpandedTimeout={500}
                    onRowExpand={onRowExpand}
                    onRowCollapse={onRowCollapse}
                    ableToUpdate={true}
                    onEditClick={editClick}
                    ableToDelete={true}
                    onRemoveClick={removeClick}
                    customButtons={customButtons}/>
            </div>
            <CustomDialog onCancel={hideDialog} visible={showEditDialog}
                          header={<><i className={'pi pi-pencil mr-2'}></i><span>{t('inspection_edit')}</span></>}
                          style={{width: '800px'}}
                          maximized={false} showFooter={true} formName={'inspection_edit_form'}>
                <InspectionEditForm project={props.project} inspection={editInspection} onSaved={() => {
                    setShowEditDialog(false)
                }}/>
            </CustomDialog>
        </>
    );
};


