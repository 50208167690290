const permissions = {
    "isAdmin": 0,
    "seeUM": 1,
    "editUM": 2,
    "seeAllUsers": 3,
    "editProject": 4,
    "createProject": 5,
    "seeAllProjects": 6,
    "addElement": 7,
    "moveElement": 8,
    "deleteElement": 9,
    "importInspections": 10,
    "saveProjectBookmark": 11,
    "seeElements": 12,
    "editElements": 13,
    "seeInspections": 14,
    "editInspections": 15,
    "editLocation": 16,
    "accessNextcloud": 17,
    "isCoordinator": 18,
    "isManager": 19,
    "isExpert": 20,
    "seeDocuments": 21,
    "editDocuments": 22,
    "seeCustomerDocuments": 23,
    "editCustomerDocuments": 24,
    "seeGrafanaDashboards": 25,
    "editGrafanaDashboards": 26,
    "seeInfoBoard": 27,
    "editTypes": 28,
    "editLocals": 29
};

const permissionsIcon = {
    "0": 'pi pi-shield',    // isAdmin
    "1": 'pi pi-user',      // seeUM
    "2": 'pi pi-user-edit', // editUM
    "3": 'pi pi-users',     // seeAllUsers
    "4": 'pi pi-pencil',    // editProject
    "5": 'pi pi-plus',      // createProject
    "6": 'pi pi-eye',       // seeAllProjects
    "7": 'pi pi-plus',      // addElement
    "8": 'pi pi-arrows-alt',// moveElement
    "9": 'pi pi-trash',     // deleteElement
    "10": 'pi pi-file-import',   // importInspections
    "11": 'pi pi-bookmark', // saveProjectBookmark
    "12": 'pi pi-eye',      // seeElements
    "13": 'pi pi-pencil',   // editElements
    "14": 'pi pi-eye',      // seeInspections
    "15": 'pi pi-pencil',   // editInspections
    "16": 'pi pi-pencil',   // editLocation
    "17": 'pi pi-cloud',    // accessNextcloud
    "18": 'pi pi-shield',   // isCoordinator
    "19": 'pi pi-shield',   // isManager
    "20": 'pi pi-shield',   // isExpert
    "21": 'pi pi-file',     // seeDocuments
    "22": 'pi pi-file-edit',// editDocuments
    "23": 'pi pi-file',     // seeCustomerDocuments
    "24": 'pi pi-file-edit',// editCustomerDocuments
    "25": 'pi pi-eye',      // seeGrafanaDashboards
    "26": 'pi pi-pencil',   // editGrafanaDashboards
    "27": 'pi pi-eye',      // seeInfoBoard
    "28": 'pi pi-wrench',   // editTypes
    "29": 'pi pi-wrench',   // editLocals
};

// dependencies:
// Ein Benutzer der Dokumente editieren kann, muss auch Dokumente sehen können
// Ein Benutzer der Benutzermanagement editieren kann, muss auch Benutzermanagement sehen können
// ...
const dependPermissions = {
    '0': [],
    '1': [],
    '2': [1],               // editUM
    '3': [1,2],             // seeAllUsers
    '7': [12],              // addElement
    '8': [12],              // deleteElement
    '9': [12],              // moveElement
    '10': [14,15],          // importInspections
    '13': [12],             // editElements
    '15': [14],             // editInspections
    '17': [21,22,23,24],    // accessNextcloud
    '22': [21],             // editDocuments
    '24': [23],             // editCustomerDocuments
    '26': [25],             // editGrafanaDashboards
};

export {
    permissions,
    permissionsIcon,
    dependPermissions
}
