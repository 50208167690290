/*
 * InspectionManagement.tsx
 * Author: fwunderlich
 * Date: 02.08.2024
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import LayoutMainContentTabMenu from "../components/global/LayoutMainContentTabMenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlasses} from "@fortawesome/free-solid-svg-icons";
import ViewButtons from "../components/global/ViewButtons";
import {buttonAddNewInspection, buttonAddNewPlant, makeButtonItem} from "../components/global/viewButtonSelection";
import {useTranslation} from "react-i18next";
import {InspectionOverview} from "../components/inspectionManagement/InspectionOverview";
import CustomDialog from "../components/global/CustomDialog";
import {InspectionEditForm} from "../components/inspectionManagement/InspectionEditForm";
import {resetElementChanges} from "../redux/slices/ElementChangesSlice";
import {useAppDispatch} from "../redux/hooks";

type Props = {
    project: any
}

export const InspectionManagement = (props: Props) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [viewButtonsRerender, setViewButtonsRerender] = useState<boolean>(true);
    const [showNewInspection, setShowNewInspection] = useState<boolean>(false);

    const menuItems = [
        {
            id: 'inspectionoverview',
            label: t('inspections_overview'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faGlasses}/>,
            component: <InspectionOverview project={props.project}/>,
            visible: props.project !== null,
            buttons: (
                <ViewButtons title={''} items={[
                    makeButtonItem(buttonAddNewInspection, t, () => {
                        setShowNewInspection(true);
                    }, true, false),
                ]} rerender={viewButtonsRerender}
                />
            )
        }
    ];

    const hideDialogs = () => {
        setShowNewInspection(false);
        dispatch(resetElementChanges());
    };

    return (<div><LayoutMainContentTabMenu items={menuItems} activeIndex={0} onTabChange={() => {
        setViewButtonsRerender(!viewButtonsRerender);
    }}/>
        <CustomDialog onCancel={hideDialogs} visible={showNewInspection}
                      header={<><i className={'pi pi-plus mr-2'}></i><span>{t('inspection_new')}</span></>}
                      style={{width: '800px'}}
                      maximized={false} showFooter={true} formName={'inspection_edit_form'}>
            <InspectionEditForm project={props.project} onSaved={() => {
                setShowNewInspection(false)
            }}/>
        </CustomDialog>
    </div>)
}
