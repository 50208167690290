import React, {useEffect, useState} from 'react';
import {ElementTypeItem, getElementType, UnitItem} from '../functions/Units';
import Input from '../global/Input';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {addApiCalls} from '../../redux/slices/ApiCallsSlice';
import {changeField} from '../../redux/slices/ElementChangesSlice';
import {useTranslation} from 'react-i18next';
import {useLazyGetCustomerTypesQuery} from '../../redux/rtk/injectedUnitsApi';
import {checkPermission} from "../../functions/functionLibrary";
import {permissions} from "../../config/permissions";

type Props = {
    item: UnitItem;
    elementTypes: ElementTypeItem[],
}

export const UnitName = (props: Props) => {
    const {t} = useTranslation(['common']);

    const apiCalls: any = useAppSelector((state: any) => state.apiCallsData.apiCalls);
    const dispatch = useAppDispatch();
    const [triggerGetCustomerTypes, resultCustomerTypes, lastPromiseCustomerTypes] = useLazyGetCustomerTypesQuery();

    const [item, setItem] = useState<UnitItem>(props.item);
    const [result, setResult] = useState<any[]>([]);
    const [inputError, setInputError] = useState<string>('');

    useEffect(() => {
        console.log('result', result);
    }, [result]);

    useEffect(() => {
        if (item && item.fields) {
            (async () => {
                const n = await getName();

                Promise.all(n).then((result) => {
                    setResult(result);
                });
            })();
        }
    }, [item]);

    // useEffect(() => {
    //     if (values) {
    //         setResult((prevState) => {
    //             let temp = [...prevState];
    //
    //             for (let i = 0; i < temp.length; i++) {
    //                 let tempItem = temp[i];
    //
    //                 tempItem.value = values[tempItem.name];
    //             }
    //
    //             return temp;
    //         });
    //     }
    // }, [values]);

    const getName = async () => {
        const result: any[] = [];

        if (item) {
            const elementType = getElementType(item.element_type_id, props.elementTypes);

            if (elementType) {
                if (elementType.api_call_get_name) {
                    await addApiCall(elementType.api_call_get_name, 'name', item.name, t(elementType.name_field_label), result);
                } else {
                    const temp = {
                        value: item.name ? item.name : '',
                        label: t(elementType.name_field_label),
                        name: 'name'
                    };
                    result.push(temp);
                }
            }
        }
        return result;
    };

    const addApiCall = async (apiCall: string, key: string, value: any, label: string, result: any[]) => {
        const type = 'dropdown';
        let dropdownOptions: any[] = [];
        if (apiCalls[apiCall]) {
            dropdownOptions = apiCalls[apiCall];
        } else {
            dropdownOptions = await triggerGetCustomerTypes({
                customerType: apiCall
            }).unwrap()
                .then((result: any) => {
                    const returnResult = result.map((item: any) => {
                        return {
                            value: key === 'name' ? item.name : item.id,
                            label: item.name,
                            icon: item.icon
                            // label: item.name
                        };
                    });

                    if (apiCall) {
                        if (!apiCalls[apiCall]) {
                            dispatch(addApiCalls({key: apiCall, value: returnResult}));
                        }
                    }

                    return returnResult;
                });
        }

        const temp = {
            dropdownOptions: dropdownOptions,
            id: item.id,
            value: value ? value : '',
            label: t(label),
            name: key,
            type: type
        };

        result.push(temp);
    };

    const onChangeField = (value: string, id: string, fieldName: string) => {
        dispatch(changeField({
            element_id: id,
            key: fieldName,
            value: value
        }));
        setResult((prevState) => {
            const temp = [...prevState];

            for (let i = 0; i < temp.length; i++) {
                const tempItem = temp[i];

                tempItem.value = value;
            }

            return temp;
        });
        // setValues((prevState: any) => {
        //     // const temp = JSON.parse(JSON.stringify(prevState));
        //     return {...prevState, [fieldName]: value};
        // });
    };

    return (<div> {result && result.map((itemResult: any) => {
        if (itemResult.dropdownOptions) {
            return (<Input edit={checkPermission(permissions.editElements)} name={itemResult.name}
                           inputClassName={'h-full'}
                           value={itemResult.value}
                           label={itemResult.label} type={itemResult.type}
                           dropdownOptions={itemResult.dropdownOptions}
                           options={{
                               itemTemplate: (item: any) => {
                                   if (item) {
                                       if (item.icon) {
                                           return <div><i className={'mr-2 ' + item.icon}/>{item.label}</div>;
                                       } else {
                                           return item.label;
                                       }
                                   }
                                   return '';
                               },
                               valueTemplate: (item: any) => {
                                   if (item) {
                                       if (item.icon) {
                                           return <div><i className={'mr-2 ' + item.icon}/>{item.label}</div>;
                                       } else {
                                           return item.label;
                                       }
                                   }
                                   return 0;
                               }
                           }}
                           onChange={(e) => {
                               if (itemResult) {
                                   onChangeField(e.target.value || '', item.id, 'name');
                               }
                           }}/>);
        } else {
            return (<><Input key={'unitname'} edit={checkPermission(permissions.editElements)} label={itemResult.label} name={'name'}
                             value={itemResult.value}
                             className={inputError ? 'no-field-margin' : ''}
                             onChange={(e) => {
                                 setInputError('');
                                 if (itemResult) {
                                     let value = e.target.value;
                                     if (!value) {
                                         setInputError('unitname-no-empty-string');
                                     } else if (!value.match(/^[a-zA-Z0-9-_. ÄÖÜäöü]*$/)) {
                                         setInputError('unitname-wrong-regex');
                                     }

                                     onChangeField(value, item.id, 'name');
                                 }
                             }}/>{inputError &&
                <span style={{color: 'red'}}>{t(inputError)}</span>}</>);
        }
    })}</div>);
};
