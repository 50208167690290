import {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import MapContext from '../map/MapContext';
import {Vector as VectorSource} from 'ol/source';
import Cluster from 'ol/source/Cluster';
import VectorLayer from 'ol/layer/Vector';
import geoSettings from '../../../config/geoSettings';
import {useLazyGetElementGeoDataQuery, useLazyGetPolylineDataQuery} from '../../../redux/rtk/injectedGeoApi';
import {
    createFeatures,
    createPoiFeatures, createPolylineFeatures,
    getPolyLineBottomStyle, getPolylineGreyStyle,
    getPolyLinePointsStyle,
    getPolyLineTopStyle,
    getPolyLineUpperStyle, getScaleValue,
    pinImageStyle,
} from '../../functions/Geo';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {getVectorLayerObject, scaleElem} from '../../../functions/functionsOpenlayer';
import {addLayerToCounterArray, setDataLoaded, setLayerArray} from '../../../redux/slices/GeomonitoringSlice';
import {Stroke, Style} from 'ol/style';

type poiProps = {
    projectId: number,
    itemId?: any,
    zoomMap: number,
    projectType: string,
    item: any
}

const PolylineModifyLayer: FunctionComponent<poiProps> = ({projectId, itemId, zoomMap, projectType, item}) => {
    // @ts-ignore

    const {map, setMap} = useContext(MapContext);
    const [zoom, setZoom] = useState(zoomMap);
    const dispatch = useAppDispatch();
    const modifyState = useAppSelector((state: any) => state.configLayer.layerData.modifyState);
    const [vLayer, setVlayer] = useState(null);
    const [triggerPolyline, {
        data: resultPolylineData,
        isPolylineLoading,
        isPolylineError,
        isSuccess: isSuccessPoly
    }] = useLazyGetPolylineDataQuery();
    const [triggerElements, {
        data: resultPoiData,
        isLoading,
        isError,
        isSuccess: isSuccessPoint
    }] = useLazyGetElementGeoDataQuery();
    const [nSource, setNSource] = useState({
        removeFeature(feature: any) {
        },
        addFeature(mp: any) {
        },
        clear() {
        },
        addFeatures(features: any[]) {
        },
        getFeatures() {

        }
    });
    const refVLayer = useRef(vLayer);
    const refNSource = useRef(nSource);

    const getLineStyles = (feature: any, resolution: number) => {
        const scaleValue = getScaleValue(map.getView().getZoom());
        return [getPolylineGreyStyle(scaleValue), getPolyLinePointsStyle(scaleValue * 2, false, feature)];
    };

    const polylineDispatches = (layerId: string, toogleVisibility: boolean) => {
        dispatch(setLayerArray({
            name: layerId,
            mainLayerId: layerId,
            id: layerId,
            type: 'vector',
            parentLayer: null,
            toogleHidden: toogleVisibility,
            visible: true,
            styleId: null,
            simpleLayer: true,
            layerParts: null,
            layerConfig: null,
            styles: null,
            title: 'Polyline'
        }));
        dispatch(addLayerToCounterArray(projectId));
    };

    const createLayer = async (map: any) => {
        const layerId = 'polylineModifyLayer';
        const source = new VectorSource({});
        const vectorLayer = getVectorLayerObject(source, layerId, true, 'vector', geoSettings.poiLayerKey, getLineStyles);
        map.addLayer(vectorLayer);
        polylineDispatches(layerId, true);
        setNSource(source);
        // @ts-ignore
        setVlayer(vectorLayer);
        setMap(map);
    };

    useEffect(() => {
        //  console.log(isSuccessPoly);
        if (!map || Object.keys(map).length === 0) return;
        // @ts-ignore
        if (vLayer !== null) {
            refVLayer.current = vLayer;
            refNSource.current = nSource;


            if (isSuccessPoly) {

                /*  if (resultPolylineData && resultPolylineData.length >0) {
                       createPolylineFeatures(resultPolylineData).then(result => {

                           if (result.features.length > 0) {

                               refNSource.current.addFeatures(result.features);
                               // @ts-ignore

                               const zoomMap=map.getView().getZoom();
                               map.getView().setZoom(zoomMap + 0.1);
                               //   }
                           }
                       });
                   }*/
            }
            if (isSuccessPoint && resultPoiData) {

                createFeatures(resultPoiData, null).then(result => {
                    if (result.features.length > 0) {
                        if (itemId) {
                            const poi = result.features[0];
                            refNSource.current.addFeature(poi);
                            map.getView().setCenter(poi.getGeometry().getCoordinates());
                            map.getView().setZoom(17);
                            // console.log('add', poi)
                            dispatch(setDataLoaded(true));
                        }
                    } else {
                        dispatch(setDataLoaded(true));
                    }
                });
            }
        }
    }, [isSuccessPoly, isSuccessPoint, resultPolylineData, vLayer]);


    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        // eslint-disable-next-line no-console

        createLayer(map).then(r => console.log('PolylineModifyLayer erstellt'));

    }, [map]);

    useEffect(() => {

        if (!modifyState.state && modifyState.coords && resultPolylineData && resultPolylineData.length > 0) {

            /*  const index= resultPolylineData[0].items.findIndex((x: { id: any; }) => x.id === itemId);
              const polylineCoords=resultPolylineData[0].polyline.coordinates;
              const newCoordinates={...[], ...polylineCoords};
              newCoordinates[index]=modifyState.coords;
              console.log(polylineCoords,newCoordinates, index)
              let features=[];
              // @ts-ignore
              features=refNSource.current.getFeatures();
              const polylineFeature=features.find(x => x.get('layerKind')==='polyline');
              if (polylineFeature) {
                  polylineFeature.getGeometry().setCoordinates(Object.values(newCoordinates));

              }*/
            // itemid muss auch noch mitgegeben werden
        }
    }, [modifyState]);

    useEffect(() => {
        const features = refNSource.current.getFeatures();
        // @ts-ignore
        if (features && features.length > 0) {
            // @ts-ignore
            features[0].set('status', item.inspection_task_status);
        }
    }, [item]);

    useEffect(() => {
        if (itemId) {
            if (projectType === 'polyline') {
                // eslint-disable-next-line camelcase
                triggerPolyline({project_id: projectId, mapping: {element_id: itemId}});
                if (itemId) {
                    // eslint-disable-next-line camelcase
                    triggerElements({project_id: projectId, mapping: {element_id: itemId, replace_coords: false}});
                }
            }
        }
    }, []);
    return null;
};

export default PolylineModifyLayer;
