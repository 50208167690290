import React, {useState, useEffect, createRef} from 'react';
import {
    Routes,
    Route
} from 'react-router-dom';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './style/custom-layout.scss';
import './style/custom-openlayers.scss';

import {useTranslation} from 'react-i18next';

import {addLocale, locale} from 'primereact/api';

import settings from './config/settings';
import keycloakfetch from './functions/keycloakfetch';
import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import {ConfirmDialog} from 'primereact/confirmdialog';
import {KeycloakService} from './services/KeycloakService';

import {Access} from './views/pages/Access';
import {NotFound} from './views/pages/NotFound';
import Main from './views/Main';
import {updateToastRef} from './components/global/CustomToast';
import {ProgressSpinner} from 'primereact/progressspinner';
import {hideWaitAnimation, showWaitAnimation, updateWaitAnimationRef} from './components/global/CustomWaitAnimation';
import store from './redux/store';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBug} from "@fortawesome/free-solid-svg-icons";
import {useAppDispatch, useAppSelector} from "./redux/hooks";
import {checkUnsavedChangesAndGoOn, getUserFromLocalStorage} from "./components/functions/Global";
import {resetElementChanges} from "./redux/slices/ElementChangesSlice";
import {UnderConstruction} from "./views/pages/UnderConstruction";

// internationalization support


const App = () => {

    const {t, i18n} = useTranslation(['common']);

    const isDirty = useAppSelector((state: any) => {
        return state.elementChangesData.isDirty;
    });

    const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);
    const [keycloakToken, setKeycloakToken] = useState<string>('');
    const [user, setUser] = useState(null);
    const toast: any = createRef();
    const waitAnimation: any = createRef();
    useEffect(() => {
        updateToastRef(toast);
    });

    useEffect(() => {
        updateWaitAnimationRef(waitAnimation);
    });

    // useEffect(() => {
    //     let user = getUserFromLocalStorage();
    //     if (user && user.id !== '1356c625-9590-4e7b-ae78-0f0eb44add90') {
    //         window.location.pathname = '/underconstruction';
    //     }
    // }, []);

    useEffect(() => {
        const onBeforeUnload = (e: any) => {
            if (isDirty) {
                e.preventDefault();
                e.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, [isDirty]);

    useEffect(() => {
        if (keycloakToken !== '') {
            getLoggedInUserFetch().then();
        }
    }, [keycloakToken]);

    const logout = () => {
        showWaitAnimation();
        const currentUrl = window.location.href;
        const state = store.getState();
        const keycloakIdToken = state.keycloakData.keycloak.keycloakIdToken;
        window.location.href = settings.logoutUrl
            + '?id_token_hint=' + keycloakIdToken
            + '&post_logout_redirect_uri=' + currentUrl;
        hideWaitAnimation();
    };

    const getLoggedInUserFetch = async () => {
        await keycloakfetch.get(settings.apiUrl + '/getMe').then(result => {
            if (result.length > 0) {
                result = result[0];
            }
            // Set i18next language
            i18n.changeLanguage(result.language_id).then(ret => {
                setUser(result);
                localStorage.setItem('user', JSON.stringify(result));
            });

            switch (result.language_id) {
                case 'de': {
                    addLocale('de', {
                        'startsWith': 'Beginnt mit',
                        'contains': 'Enthält',
                        'notContains': 'Enthält nicht',
                        'endsWith': 'Endet mit',
                        'equals': 'Ist gleich',
                        'notEquals': 'Ist ungleich',
                        'noFilter': 'Kein Filter',
                        'filter': 'Filtern',
                        'lt': 'Kleiner als',
                        'lte': 'Kleiner oder gleich',
                        'gt': 'Größer als',
                        'gte': 'Größer oder gleich',
                        'dateIs': 'Datum ist',
                        'dateIsNot': 'Datum ist nicht',
                        'dateBefore': 'Datum ist vor',
                        'dateAfter': 'Datum ist nach',
                        'custom': 'Benutzerdefiniert',
                        'clear': 'Löschen',
                        'apply': 'Übernehmen',
                        'matchAll': 'Passt auf alle',
                        'matchAny': 'Passt auf einige',
                        'addRule': 'Regel hinzufügen',
                        'removeRule': 'Regel entfernen',
                        'accept': 'Ja',
                        'reject': 'Nein',
                        'choose': 'Auswählen',
                        'upload': 'Hochladen',
                        'cancel': 'Abbrechen',
                        'completed': 'Abgeschlossen',
                        'pending': 'Ausstehend',
                        'fileSizeTypes': ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                        'dayNames': ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                        'dayNamesShort': ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
                        'dayNamesMin': ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                        'monthNames': ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                        'monthNamesShort': ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                        'chooseYear': 'Jahr wählen',
                        'chooseMonth': 'Monat wählen',
                        'chooseDate': 'Datum wählen',
                        'prevDecade': 'Vorheriges Jahrzehnt',
                        'nextDecade': 'Nächstes Jahrzehnt',
                        'prevYear': 'Vorheriges Jahr',
                        'nextYear': 'Nächstes Jahr',
                        'prevMonth': 'Vorheriger Monat',
                        'nextMonth': 'Nächster Monat',
                        'prevHour': 'Vorherige Stunde',
                        'nextHour': 'Nächste Stunde',
                        'prevMinute': 'Vorherige Minute',
                        'nextMinute': 'Nächste Minute',
                        'prevSecond': 'Vorherige Sekunde',
                        'nextSecond': 'Nächste Sekunde',
                        'am': 'am',
                        'pm': 'pm',
                        'today': 'Heute',
                        'now': 'Jetzt',
                        'weekHeader': 'KW',
                        'firstDayOfWeek': 1,
                        'showMonthAfterYear': false,
                        'dateFormat': 'dd.mm.yy',
                        'weak': 'Schwach',
                        'medium': 'Mittel',
                        'strong': 'Stark',
                        'passwordPrompt': 'Passwort eingeben',
                        'emptyFilterMessage': 'Keine Ergebnisse gefunden',
                        'searchMessage': '{0} Ergebnisse verfügbar',
                        'selectionMessage': '{0} Elemente ausgewählt',
                        'emptySelectionMessage': 'Kein ausgewähltes Element',
                        'emptySearchMessage': 'Keine Ergebnisse gefunden',
                        'emptyMessage': 'Keine Einträge gefunden',
                        'aria': {
                            'trueLabel': 'Wahr',
                            'falseLabel': 'Falsch',
                            'nullLabel': 'Nicht ausgewählt',
                            'star': '1 Stern',
                            'stars': '{star} Sterne',
                            'selectAll': 'Alle Elemente ausgewählt',
                            'unselectAll': 'Alle Elemente abgewählt',
                            'close': 'Schließen',
                            'previous': 'Vorherige',
                            'next': 'Nächste',
                            'navigation': 'Navigation',
                            'scrollTop': 'Nach oben scrollen',
                            'moveTop': 'Zum Anfang bewegen',
                            'moveUp': 'Nach oben bewegen',
                            'moveDown': 'Nach unten bewegen',
                            'moveBottom': 'Zum Ende bewegen',
                            'moveToTarget': 'Zum Ziel bewegen',
                            'moveToSource': 'Zur Quelle bewegen',
                            'moveAllToTarget': 'Alle zum Ziel bewegen',
                            'moveAllToSource': 'Alle zur Quelle bewegen',
                            'pageLabel': 'Seite {page}',
                            'firstPageLabel': 'Erste Seite',
                            'lastPageLabel': 'Letzte Seite',
                            'nextPageLabel': 'Nächste Seite',
                            'previousPageLabel': 'Vorherige Seite',
                            'rowsPerPageLabel': 'Zeilen pro Seite',
                            'jumpToPageDropdownLabel': 'Zum Dropdown-Menü springen',
                            'jumpToPageInputLabel': 'Zum Eingabefeld springen',
                            'selectRow': 'Zeile ausgewählt',
                            'unselectRow': 'Zeile abgewählt',
                            'expandRow': 'Zeile erweitert',
                            'collapseRow': 'Zeile reduziert',
                            'showFilterMenu': 'Filtermenü anzeigen',
                            'hideFilterMenu': 'Filtermenü ausblenden',
                            'filterOperator': 'Filteroperator',
                            'filterConstraint': 'Filterbeschränkung',
                            'editRow': 'Zeile bearbeiten',
                            'saveEdit': 'Änderungen speichern',
                            'cancelEdit': 'Änderungen abbrechen',
                            'listView': 'Listenansicht',
                            'gridView': 'Rasteransicht',
                            'slide': 'Folie',
                            'slideNumber': '{slideNumber}',
                            'zoomImage': 'Bild vergrößern',
                            'zoomIn': 'Vergrößern',
                            'zoomOut': 'Verkleinern',
                            'rotateRight': 'Nach rechts drehen',
                            'rotateLeft': 'Nach links drehen'
                        }
                    });
                    locale('de');
                }
                    break;
            }


        });
    };

    const onSessionExpired = () => {
        setConfirmDialogVisible(true);
    };

    const sessionExpiredDialogFooter = (
        <React.Fragment>
            <Button label={'Ok'} icon="pi pi-check" className="p-button-text" onClick={logout}/>
        </React.Fragment>
    );

    const isMobile = () => {
        return window.innerWidth <= 991;
    };

    return (
        <>
            {!isMobile() && <div className={'bug-report'}><a target={'_blank'}
                                                             aria-label={'Bug Report'}
                                                             href={'https://tuevnord.sharepoint.com/:x:/r/sites/DigitalInspectionTool/Freigegebene%20Dokumente/General/Bugreport.xlsx?d=w640c1a9e402c4f51aac25b8cc9757cee&csf=1&web=1&e=XVhYjY'}
                                                             rel="noreferrer">

                <span>Bug Report</span>
                <FontAwesomeIcon className="sidebar-icon ml-2" icon={faBug}/>
            </a></div>}
            {keycloakToken && user !== null && <Routes>
                <Route path={'/'} element={<Main keycloakToken={keycloakToken} currentUser={user}/>}/>
                <Route path={'/project/:projectId'} element={<Main keycloakToken={keycloakToken} currentUser={user}/>}/>
                <Route path={'/project/:projectId/:bookmarkId/'}
                       element={<Main keycloakToken={keycloakToken} currentUser={user}/>}/>
                <Route path='/accessdenied' element={<Access/>}/>
                <Route path='/underconstruction' element={<UnderConstruction/>}/>
                <Route path='*' element={<NotFound/>}/>
            </Routes>
            }
            <KeycloakService onAuth={setKeycloakToken} onSessionExpired={onSessionExpired}/>
            <ConfirmDialog
                className={'hide-x'}
                visible={confirmDialogVisible}
                message={t('sessionExpired_message')}
                header={t('sessionExpired_header')}
                icon="pi pi-exclamation-triangle"
                footer={sessionExpiredDialogFooter}
            />
            <Toast ref={toast}/>
            {<div ref={waitAnimation}
                  style={{
                      position: 'absolute',
                      height: '100%',
                      width: '100%',
                      zIndex: '9999999',
                      display: 'none',
                      top: 'calc(50% - 50px)',
                      left: 'calc(50% - 50px)'
                  }}>
                <ProgressSpinner style={{width: '100px', height: '100px'}} strokeWidth={'5px'}/></div>}
        </>
    );
};

export default App;
