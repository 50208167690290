import React, {useEffect, useRef, useState} from 'react';
import {
    ElementTypeItem,
    getElementType,
    getElementTypeIconClass,
    getElementTypeName,
    UnitItem
} from '../functions/Units';
import Input from '../global/Input';
import {Button} from 'primereact/button';
import {useTranslation} from 'react-i18next';
import {checkUnsavedChangesAndGoOn, Nullable} from '../functions/Global';
import {classNames} from 'primereact/utils';
import {confirmDialog} from 'primereact/confirmdialog';
import {PanelMenu} from 'primereact/panelmenu';
import {Project} from '../functions/Project';
import {useGetPossibleParentElementsQuery} from '../../redux/rtk/injectedUnitsApi';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {resetElementChanges, toggleNewElementOpened} from '../../redux/slices/ElementChangesSlice';
import {checkPermission} from "../../functions/functionLibrary";
import {permissions} from "../../config/permissions";
import {Menu} from "primereact/menu";
import {TieredMenu} from "primereact/tieredmenu";

type Props = {
    project: any,
    item: UnitItem | undefined,
    elementTypes: ElementTypeItem[],
    onClick: (parentId: string, object: ElementTypeItem | undefined, sibling: boolean) => void,
    onConnectClick: (startId: string, targetId: string) => void,
    // addOnSelect: boolean,
    className?: string,
    additionalItems?: any[],
    style?: any,
    children?: React.ReactNode,
}

export const UnitObjectInput = (props: Props) => {
    const {t} = useTranslation(['common']);

    const menu = useRef<any>(null);

    const isDirty = useAppSelector((state: any) => {
        return state.elementChangesData.isDirty;
    });

    const newElementOpened: any[] = useAppSelector((state: any) => state.elementChangesData.newElementOpened);
    const dispatch = useAppDispatch();

    const [value, setValue] = useState<any>(undefined);
    const [objectTypes, setObjectTypes] = useState<any[]>([]);

    const [panelMenuItems, setPanelMenuItems] = useState<any[]>([]);
    const [itemElementType, setItemElementType] = useState<Nullable<ElementTypeItem>>(null);

    const [childrenIds, setChildrenIds] = useState<number[]>([]);
    const [siblingIds, setSiblingIds] = useState<number[]>([]);

    const [possibleElements, setPossibleElements] = useState<any[]>([]);
    const [skipPossibleElement, setSkipPossibleElement] = useState<boolean>(true);

    const getPossibleParentElementsQuery = useGetPossibleParentElementsQuery({
        projectId: props.project.id,
        elementId: props.item ? props.item.id : ''
    }, {skip: skipPossibleElement});


    useEffect(() => {
        if (props.item && props.elementTypes) {

            const eleType = getElementType(props.item.element_type_id, props.elementTypes);

            if (eleType) {
                setSkipPossibleElement(eleType.show_as_tree !== false);
                setItemElementType(eleType);
            }
        }
    }, [props.item, props.elementTypes]);

    useEffect(() => {
        if (itemElementType) {
            setChildrenIds(itemElementType.children_type_ids);
            setSiblingIds(itemElementType.sibling_type_ids);
        }
    }, [itemElementType]);

    useEffect(() => {
        const pmi: any[] = [];

        if (props.elementTypes && props.elementTypes.length) {
            if (!props.item) {
                const topElements = getTopElements();
                if (topElements && topElements.length) {
                    pmi.push({
                        label: t('units_add_topElement'),
                        items: topElements,
                        expanded: newElementOpened,
                        visible: checkPermission(permissions.addElement),
                        command: () => {
                            dispatch(toggleNewElementOpened());
                        }
                    });
                }
            } else {
                if (childrenIds != null) {
                    const childrenElements = getChildrenElements();

                    if (childrenElements && childrenElements.length) {
                        pmi.push({
                            label: t('units_add_childrenElement'),
                            items: childrenElements,
                            expanded: newElementOpened,
                            visible: checkPermission(permissions.addElement),
                            command: () => {
                                dispatch(toggleNewElementOpened());
                            }
                        });
                    }
                }
                if (siblingIds != null) {
                    const siblingElements = getSiblingElements();

                    if (siblingElements && siblingElements.length) {
                        pmi.push({
                            label: t('units_add_siblingElement'),
                            items: siblingElements,
                            expanded: newElementOpened,
                            visible: checkPermission(permissions.addElement),
                            command: () => {
                                dispatch(toggleNewElementOpened());
                            }
                        });
                    }
                }
                if (possibleElements && possibleElements.length) {
                    pmi.push({
                        label: t('units_connect_elements'), items: possibleElements.map((item: any) => {
                            return {
                                value: item.id,
                                label: <div className={'flex'}>
                                    {/* <i className={item.icon + ' my-auto mr-2'}></i>*/}
                                    {item.name}
                                </div>,
                                visible: checkPermission(permissions.moveElement),
                                command: () => {
                                    checkUnsavedChangesAndGoOn(t, isDirty, dispatch, () => {
                                        if (props.item) {
                                            props.onConnectClick(props.item.id, item.id);
                                            setValue(undefined);
                                        }
                                    });
                                }
                            };
                        }
                        ),
                        expanded: newElementOpened,
                        command: () => {
                            dispatch(toggleNewElementOpened());
                        }
                    });
                }
            }
        }

        if (props.additionalItems) {
            pmi.push(...props.additionalItems);
        }

        setPanelMenuItems(pmi);
    },
    [props.elementTypes, childrenIds, siblingIds, possibleElements, newElementOpened, isDirty]
    );

    useEffect(() => {
        if (getPossibleParentElementsQuery.data) {
            setPossibleElements(getPossibleParentElementsQuery.data);
        }
    }, [getPossibleParentElementsQuery]);

    // useEffect(() => {
    //     setValue(undefined);
    //     if (props.elementTypes) {
    //         if (props.item && props.item.id === '') {
    //             setObjectTypes(props.elementTypes.filter((x) => x.has_parent === false).map((x) => {
    //                 return getDropDownContent(x);
    //             }));
    //         } else {
    //             if (childrenIds != null) {
    //                 setObjectTypes(props.elementTypes.filter((x) => x.has_parent === true && childrenIds.indexOf(x.element_type_id) >= 0).map((x) => {
    //                     return getDropDownContent(x);
    //                 }));
    //             }
    //         }
    //     }
    // }, [props.elementTypes, props.item, childrenIds, siblingIds]);

    const getTopElements = () => {
        return props.elementTypes.filter((x) => x.has_parent === false || x.show_as_tree === false).map((x) => {
            return getTopElementItemEntry(x);
        });
    };

    const getTopElementItemEntry = (item: ElementTypeItem) => {
        return {
            value: item.element_type_id,
            label: <div className={'flex'}>
                <i className={item.icon + ' my-auto mr-2'}></i>
                {item.name}
            </div>,
            command: () => {
                checkUnsavedChangesAndGoOn(t, isDirty, dispatch, () => {
                    props.onClick('', props.elementTypes.find((x) => x.element_type_id === item.element_type_id), false);
                    setValue(undefined);
                });
            }
        };
    };

    const getChildrenElements = () => {
        const result = [];

        if (props.item && childrenIds && itemElementType) {
            for (let i = 0; i < props.elementTypes.length; i++) {
                const eleType = props.elementTypes[i];

                if (itemElementType.element_type_id !== 8 || (!props.item.children || !props.item.children.length)) {
                    if (childrenIds.indexOf(eleType.element_type_id) >= 0 && eleType.has_parent === true) {
                        if (eleType.show_as_tree) {
                            if (itemElementType.show_as_tree) {
                                result.push(getChildrenElementItemEntry(eleType));
                            }
                        } else {
                            result.push(getChildrenElementItemEntry(eleType));
                        }
                    }
                }
            }
        }
        return result;

        // return props.elementTypes.filter((x) => x.has_parent === true && props.childrenIds != null && props.childrenIds.indexOf(x.element_type_id) >= 0).map((x) => {
        //     return getChildrenElementItemEntry(x);
        // });
    };

    const getChildrenElementItemEntry = (item: ElementTypeItem) => {
        return {
            value: item.element_type_id,
            label: <div className={'flex'}>
                <i className={item.icon + ' my-auto mr-2'}></i>
                {item.name}
            </div>,
            command: () => {
                checkUnsavedChangesAndGoOn(t, isDirty, dispatch, () => {
                    if (props.item) {
                        props.onClick(props.item.id, props.elementTypes.find((x) => x.element_type_id === item.element_type_id), false);
                        setValue(undefined);
                    }
                });
            }
        };
    };

    const getSiblingElements = () => {

        const result = [];

        if (props.item && (props.item.lastItem || ((!props.item.parent || !props.item.parent.length) && (!props.item.children || !props.item.children.length))) && siblingIds) {
            if (itemElementType?.show_as_tree === false) {
                for (let i = 0; i < props.elementTypes.length; i++) {
                    const eleType = props.elementTypes[i];

                    if (eleType.show_as_tree === false) {

                        let add = true;

                        if (siblingIds.indexOf(eleType.element_type_id) >= 0) {
                            if (props.item.children) {
                                for (let j = 0; j < props.item.children.length; j++) {
                                    const child = props.item.children[i];

                                    const childElementType = getElementType(child.element_type_id, props.elementTypes);

                                    if (childElementType?.show_as_tree === true) {
                                        add = false;
                                        break;
                                    }
                                }
                            }
                        } else {
                            add = false;
                        }

                        if (add) {
                            result.push(getSiblingElementItemEntry(eleType));
                        }
                    }
                }
            }
        }
        return result;

        // return props.elementTypes.filter((x) => x.has_parent === true && props.childrenIds != null && props.childrenIds.indexOf(x.element_type_id) >= 0).map((x) => {
        //     return getChildrenElementItemEntry(x);
        // });
    };

    const getSiblingElementItemEntry = (item: ElementTypeItem) => {
        return {
            value: item.element_type_id,
            label: <div className={'flex'}>
                <i className={item.icon + ' my-auto mr-2'}></i>
                {item.name}
            </div>,
            command: () => {
                checkUnsavedChangesAndGoOn(t, isDirty, dispatch, () => {
                    if (props.item) {
                        props.onClick(props.item.id, props.elementTypes.find((x) => x.element_type_id === item.element_type_id), true);
                        setValue(undefined);
                    }
                });
            }
        };
    };


    return (<div className={classNames('grid grid-nogutter')} style={props.style}>
        {/*{panelMenuItems && panelMenuItems.map((item: any) => {*/}
        {/*    return item.show ? <div className={'col'}><PanelMenu model={[item]} multiple={true}/>*/}
        {/*    </div> : undefined;*/}
        {/*})}*/}
        {props.children && <div className={'col flex justify-content-end'}>{props.children}</div>}
        {/* {!props.addOnSelect && <div className={'col mt-4'}>*/}
        {/*    <Button type={'button'} className={'p-button-success p-button-outlined'} icon={'pi pi-plus'}*/}
        {/*            disabled={!value} onClick={() => {*/}
        {/*        props.onClick(props.elementId, props.elementTypes.find((x) => x.element_type_id === value));*/}
        {/*        setValue(undefined);*/}
        {/*    }}/>*/}
        {/* </div>}*/}
        <TieredMenu model={panelMenuItems} popup
                    ref={menu} id="popup_menu"/>
        <Button icon='pi pi-bars'
                className='p-button p-component p-button-outlined p-button-rounded p-button-icon-only'
                aria-controls="popup_menu" aria-haspopup
                onClick={(event) => {
                    if (menu.current) {
                        menu.current.toggle(event);
                    }
                }}/>
    </div>);
};
