/*
 * TimeRangePicker.tsx
 * Author: fwunderlich
 * Date: 21.10.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useCallback, useEffect, useRef, useState} from 'react';
import Input from './Input';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

type Props = {
    from?: Date,
    to?: Date,
    validationControlFrom?: any,
    validationErrorMessageFrom?: (name: string) => void,
    validationRulesFrom?: any,
    validationControlTo?: any,
    validationErrorMessageTo?: (name: string) => void,
    validationRulesTo?: any,
    onChange?: (from: Date, to: Date) => void,
    noLabels?: boolean
}

const TimeRangePicker = (props: Props) => {
    const {t} = useTranslation(['common']);

    const [internal, setInternal] = useState<any>([]);
    const [showTime, setShowTime] = useState<boolean>(false);

    const [from, setFrom] = useState<Date | undefined>();
    const [to, setTo] = useState<Date | undefined>();

    const internalFrom = useRef<Date | undefined>();
    const internalTo = useRef<Date | undefined>();
    const changeTriggered = useRef<boolean>(false);

    // useEffect(() => {
    //     return unmount;
    // }, []);

    const unmount = useCallback(() => {
        if (internalFrom.current && internalTo.current) {
            if (!changeTriggered.current && props.onChange) {
                props.onChange(internalFrom.current, internalTo.current);
            }
        }
    }, [internalFrom.current, internalTo.current, changeTriggered.current]);

    useEffect(() => {
        setFrom(props.from);
        setTo(props.to);
        internalFrom.current = props.from;
        internalTo.current = props.to;
        if (props.from && props.to) {
            setInternal([props.from, props.to]);
        }
    }, [props.from, props.to]);

    useEffect(() => {
        // console.log(from, to);
        if (from && to) {
            if (props.onChange) {
                props.onChange(from, to);
                changeTriggered.current = true;
            }
        }
    }, [from, to]);

    useEffect(() => {
        return unmount;
    }, []);

    return (
        <Input
            className="w-full" type="date" name="datetimepicker" label={props.noLabels ? '' : t('timerange')}
            edit={true}
            options={{selectionMode: 'range', showTime: false, readOnlyInput: true}}
            // validationControl={props.validationControlFrom}
            // validationErrorMessage={props.validationErrorMessageFrom}
            // validationRules={props.validationRulesFrom}
            value={internal}
            onChange={(e) => {

                let f = new Date(e.value[0]);
                let t = e.value[1] ? new Date(e.value[1]) : new Date(e.value[0]);

                f.setHours(0);
                f.setMinutes(0);
                f.setSeconds(0);

                internalFrom.current = f;

                t.setHours(23);
                t.setMinutes(59);
                t.setSeconds(59);

                internalTo.current = t;

                setInternal(e.value);

                changeTriggered.current = false;
            }}
            onBlur={(e) => {
                setFrom(internalFrom.current);
                setTo(internalTo.current);
            }}
        />
    );
};

export default TimeRangePicker;
