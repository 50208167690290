import {Circle, Fill, Icon, RegularShape, Stroke, Style, Text} from 'ol/style';
import {Feature} from 'ol';
import {LineString, MultiPoint, Point} from 'ol/geom';
import {getDPI, mapScale, transformCoordinateArray} from '../../services/SpatialService';
import * as turf from '@turf/turf';
import {DEVICE_PIXEL_RATIO} from 'ol/has.js';
import {fromExtent} from 'ol/geom/Polygon.js';
import {Coordinate} from 'ol/coordinate';
import CircleStyle from 'ol/style/Circle';
import {scaleElem} from '../../functions/functionsOpenlayer';
import {types} from "sass";
import Color = types.Color;


export const styleText = (feature: { get: any; }, hover: boolean, usePin: boolean) => {
    const font = 'Bold 16px Arial';
    return new Text(
        {
            textAlign: 'center',
            textBaseline: 'bottom',
            font: font,
            text: feature.get('name'),
            fill: new Fill({color: '#ffffff'}),
            stroke: new Stroke({color: '#000000', width: 3}),
            offsetX: 0,
            offsetY: usePin ? 20 : 40,
            rotation: 0,
            placement: 'point'
        });
};

export const getLayerById = async (map: any, id: any) => {
    const layers = [...map.getLayers().getArray()];
    let counter = 0;
    let result = {found: false, layer: null};
    layers.forEach(function (layer) {
        counter++;
        const layerId = layer.get('id');

        if (layerId && layerId.toLowerCase() === id.toLowerCase()) {
            result = ({found: true, layer: layer});
        }
    });
    return result;

};
export const getFeatureView = (features: any, mainCoordinates: any, map: any, source: any) => {

    const shadowFeature = new Feature(fromExtent([-20000000, -20000000, 20000000, 20000000]));
    shadowFeature.setStyle(new Style({
        fill: new Fill({color: [0, 0, 0, 0.5]}),
        zIndex: 0,
    }));
    shadowFeature.setId('shadow-layer');
    source.addFeature(shadowFeature);
    /*  if (features.length === 1) {

          source.addFeatures(features);

      } else*/
    if (mainCoordinates === null) {

        source.addFeatures(features);

    } else {
        const circleFeatures = createCircleFeature(mainCoordinates, features.length, mapScale(getDPI(), map));
        mainCoordinates.reverse();
        for (const index in circleFeatures) {
            const feature = circleFeatures[index];
            if (feature && isNotUndefined(features[parseInt(index) - 1])) {
                createOverlayFeatures(features[parseInt(index) - 1], feature, mainCoordinates, index).then((result: {
                    line: any;
                    poi: any;
                }) => {
                    source.addFeature(result.line);
                    source.addFeature(result.poi);
                });
            }
        }
    }
};

export const createOverlayFeatures = async (featureMain: any, featureSub: any, mainCoordinates: any, index: string) => {

    const newFeature = featureMain.clone();
    const id = 'overlay_' + index;
    newFeature.setId(newFeature.get('id'));
    newFeature.set('overlay', true);
    newFeature.set('parentcoords', mainCoordinates);
    const transformedCoords = transformCoordinateArray([featureSub.geometry.coordinates], 'EPSG:4326', 'EPSG:3857', false);
    newFeature.getGeometry().setCoordinates([transformedCoords[0][1], transformedCoords[0][0]]);

    const line = new LineString([
        newFeature.getGeometry().getCoordinates(),
        mainCoordinates
    ]);

    const lineId = id + '_line';
    const newLine = new Feature({
        geometry: line,
        id: lineId
    });
    return ({line: newLine, poi: newFeature});
};

export const createCircleFeature = (coords: any, lenght: number, radius: any) => {
    const startCoords = transformCoordinateArray([coords], 'EPSG:3857', 'EPSG:4326', true);
    const options = {steps: lenght > 4 ? lenght : 4, units: 'meters', properties: {foo: 'bar'}};
    // @ts-ignore
    const circle = turf.circle(startCoords[0], radius, options);
    const circleFeatures = turf.explode(circle).features;
    delete (circleFeatures[0]);
    return circleFeatures;
};

export const createPoiPoint = async (mp: any, coords: any, cluster: boolean) => {
    let poiPoint;
    if (coords) {
        poiPoint = new Feature({
            geometry: new Point(coords),
            layerKind: 'poiLayer',
            id: mp.id,
            name: mp.name,
            status: mp.status ? mp.status : 0,
            // eslint-disable-next-line camelcase
            element_type_id: mp.element_type_id,
            icon: mp.icon,
            cluster: cluster
        });
        poiPoint.setId(mp.id);
    }
    // console.log(poiPoint)
    return poiPoint;
};
export const createPolyline = async (line: number[] | Coordinate[], id: string | number | undefined, items: any) => {
    const polyline = new Feature({
        geometry: new LineString(line),
        layerKind: 'polyline',
        id: id,
        items: items
    });
    polyline.setId(id);
    return polyline;
};

export const createFeatures = async (data: any[], mainCoords: any) => {

    let i: keyof typeof data;
    const pointFeatures: any[] = [];
    const clusterFeatures: any[] = [];
    for (i in data) {
        let location;
        const mp: any = data[i];
        const cluster = false;
        const array = pointFeatures;
        if ((mp.coords_3857 && typeof mp.coords !== undefined)) {
            location = mp.coords_3857.coordinates;
        }
        if (location && (location[0] > 0 || location[0] < 0)) {
            const point = await createPoiPoint(mp, location, cluster);
            array.push(point);
        }
    }
    return {features: pointFeatures, cluster: clusterFeatures};
};

export const createPoiFeatures = async (data: any[], mainCoords: any) => {

    let i: keyof typeof data;
    const pointArray = [];
    for (i in data) {
        const lineFeature: any = data[i];
        const itemArray = lineFeature.items;
        // console.log(lineFeature)
        if (lineFeature.polyline && lineFeature.polyline.coordinates) {
            const coordinateArray = lineFeature.polyline.coordinates;
            let j: keyof typeof itemArray;
            for (j in itemArray) {
                const idValue = [itemArray[j].previous_id, itemArray[j].id, itemArray[j].next_id];
                const coordinates = coordinateArray[j];
                const point = await createPoiPoint({
                    id: idValue,
                    name: itemArray[j].name,
                    status: itemArray[j].status,
                    element_type_id: itemArray[j].element_type_id
                }, coordinates, false);
                pointArray.push(point);

            }
        }
    }

    return {features: pointArray};
};

export const addOffset = (coords: number[]) => {


    // Earth’s radius, sphere
    const r = 6378137;

    // offsets in meters
    const dn = 100000;
    const de = 100000;

    // Coordinate offsets in radians
    const dLat = dn / r;
    const dLon = de / (r * Math.cos(Math.PI * coords[0] / 180));

    // OffsetPosition, decimal degrees
    const latO = coords[0] + dLat * 180 / Math.PI;
    const lonO = coords[1] + dLon * 180 / Math.PI;
    return ([latO, lonO]);
};

export const createPoiClusterFeatures = async (data: any, mainCoords: any) => {

    let i: keyof typeof data.originalData;
    const clusterArray: any[] = [];


    for (i in data.originalData) {
        const lineFeature: any = data.originalData[i];
        if (lineFeature.polyline && lineFeature.polyline.coordinates) {

            const noCoordsPois = lineFeature.items_no_coords;
            if (noCoordsPois && noCoordsPois.length > 0) {
                //  console.log(lineFeature.polyline.coordinates, lineFeature.polyline.coordinates.length);

                let k: keyof typeof noCoordsPois;
                const foundItem = data.centerCoords.find((obj: any) => obj.name === lineFeature.pipeline_part_name);
                let clusterCoords = foundItem.coords;
                if (lineFeature.polyline.coordinates.length === 1) {
                    //     console.log(foundItem.coords,addOffset(foundItem.coords))
                    clusterCoords = addOffset(foundItem.coords);
                }
                for (k in noCoordsPois) {
                    const cpoint = await createPoiPoint(noCoordsPois[k], clusterCoords, true);
                    clusterArray.push(cpoint);
                }
            }

        }
    }

    return {clusterFeatures: clusterArray};
};

export const createPolylineFeatures = async (data: any[]) => {

    let i: keyof typeof data;
    const polylineFeatures: any[] = [];
    const centerCoordsArray: any[] = [];
    for (i in data) {
        let lineCoordinates;
        const line: any = data[i].polyline;
        const array = polylineFeatures;
        if ((line && line.coordinates && typeof line.coordinates !== undefined)) {
            lineCoordinates = line.coordinates;
            if (lineCoordinates.length > 0) {
                const polyline = await createPolyline(lineCoordinates, data[i].pipeline_part_name, data[i].items);
                array.push(polyline);
                centerCoordsArray.push({
                    name: data[i].pipeline_part_name,
                    // @ts-ignore
                    coords: polyline.getGeometry().getCoordinateAt(0.5),
                    id: data[i].id
                });
            }
        }
    }
    return {features: polylineFeatures, centercoords: centerCoordsArray};
};


const iconUnicodeCache = {};
const getIconUnicode = (iconClass: any) => {
    // @ts-ignore
    if (iconUnicodeCache[iconClass]) return iconUnicodeCache[iconClass];

    const tempElement = document.createElement('i');
    tempElement.className = iconClass;

    document.body.appendChild(tempElement);
    const character = window.getComputedStyle(tempElement, ':before').getPropertyValue('content').replaceAll('"', '');
    tempElement.remove();

    if (character) {
        // @ts-ignore
        iconUnicodeCache[iconClass] = character;
    }
    return character;
};

const unicodeAndFonts = (iconElem: string) => {
    let unicode = '\uf04b';
    let font = '600 20px "Font Awesome 5 Free"';
    switch (iconElem) {
        case 'fa fa-toolbox':
            unicode = '\uf552';
            break;
        case 'fa fa-circle-notch':
            unicode = '\uf1ce';
            break;
        default:
            unicode = getIconUnicode(iconElem);
            font = '600 20px "Icons"';
            break;
    }
    return {unicode: unicode, font: font};
};

const getStyleByIdData = (elementType: any) => {

    const gradient = getGradientBackground(20);
    let color: any = null;
    let zIndex = 10;
    switch (elementType) {
        case 1:
            color = gradient;
            zIndex = 100;
            break;
        case 2:
            color = gradient;
            zIndex = 101;
            break;
        case 3:
            color = gradient;
            zIndex = 102;
            break;
        case 4:
            color = gradient;
            zIndex = 103;
            break;
        case 5:
            color = gradient;
            zIndex = 104;
            break;
        default:
            color = gradient;
            zIndex = 105;
            break;
    }
    return {color: color, zIndex: zIndex};
};

const getBaseStyle = (feature: any, hover: boolean, usePin: boolean) => {
    const styleData = getStyleByIdData(feature.get('element_type_id'));
    const status = feature.get('status') > 0 ? '_' + feature.get('status') : '';
    //  console.log(feature, status, '/MapMarkerPin_Big'+status+'.svg')

    const textStyle = new Style({
        text: styleText(feature, hover, usePin),
    });

    const flatCoords = feature ? feature.get('geometry').flatCoordinates : [];
    const altitude = flatCoords.length === 3 ? flatCoords[2].toString() + ' m' : '';

    const getUnicodeAndFont = unicodeAndFonts(feature.get('icon'));
    const style = [new Style({
        zIndex: styleData.zIndex,
        text: new Text({
            text: getUnicodeAndFont.unicode !== 'none' ? getUnicodeAndFont.unicode : '',
            scale: 1.2,
            textAlign: 'center',
            font: getUnicodeAndFont.font,
            fill: new Fill({color: '#ffffff'}),
            stroke: new Stroke({color: [0, 0, 0, 0.5], width: 3}),
            //   backgroundFill: new Fill({color: '#000'}),
            placement: 'line',
            repeat: 1,
            offsetY: usePin ? -28 : 0,
        }),
        image: usePin ? new Icon({
            src: '/MapMarkerPin_Big' + status + '.svg',
            displacement: [0, 25],
            scale: 0.9,
            color: 'white'
        }) : new Icon({
            src: '/MapMarkerCircle_Big' + status + '.svg',
            scale: 0.3,
            color: 'white'
        }),
        // image: new Circle({
        //     fill: new Fill({color: styleData.color}),
        //     stroke: new Stroke({color: [0, 0, 0, 0.5], width: 1}),
        //     radius: 18
        // }),
        stroke: new Stroke({
            color: '#ffffffaa',
            width: 5 / 4
        })
    }), new Style({
        zIndex: styleData.zIndex,
        text: new Text({
            text: altitude,
            offsetY: -60,
            fill: new Fill({
                color: 'white'
            }),
            stroke: new Stroke({color: 'black'}),
            scale: 1.5
        })
    })];
    return [...style, textStyle];
};

export const pointImageStyle = (feature: any, resolution: any) => {
    return getBaseStyle(feature, false, false);
};

export const pinImageStyle = (feature: any, resolution: any) => {
    return getBaseStyle(feature, false, true);
};

export const hoverStyle = (feature: any, resolution: any) => {
    return getBaseStyle(feature, true, true);
};

export const getElementStyles = (feature: any) => {

    const fill = new Fill({
        color: 'rgb(225,39,39)',
    });
    const stroke = new Stroke({
        color: '#0f1010',
        width: 1.25,
    });

    const styles = [
        new Style({
            image: new Circle({
                fill: fill,
                stroke: stroke,
                radius: 6,
            }),
            fill: fill,
            stroke: stroke,
            //       text: styleText(feature, false)
        }),
    ];
    return styles;
};


const getGradient = (map: any) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // @ts-ignore
    const gradient = context.createRadialGradient(0, 0, 0, 0, 0, mapScale(getDPI(), map));
    gradient.addColorStop(0, 'rgba(0,0,0,0.6)');
    gradient.addColorStop(0.5, 'rgba(0,0,0,0.3)');
    gradient.addColorStop(1, 'rgba(0,0,0,0)');
};

const getGradientBackground = (r1: number) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const gradient = context?.createRadialGradient(0, 0, 0, 0, 0, r1);
    gradient?.addColorStop(0, '#6b79d1');
    gradient?.addColorStop(0.5, '#6b79d1');
    gradient?.addColorStop(0.9, '#001ed2');
    gradient?.addColorStop(1, '#001ed2');

    return gradient;
};


export const clusterBaseStyle = (feature: any) => {
    const width = 6;
    return [new Style({
        zIndex: 100,
        image: new Icon({
            src: '/MapMarkerCircle.svg',
            scale: 1,
            color: 'white'
        }),

        text: new Text(
            {
                text: isNotUndefined(feature.get('features')) ? feature.get('features').length.toString() : '',
                scale: 1.5,
                fill: new Fill(
                    {
                        color: '#ffffff'
                    })
            })
    }),
        // new Style({
        //     zIndex: 0,
        //     image: new Circle({
        //         radius: mapScale(getDPI(), map),
        //         fill: new Fill({color: gradient}),
        //         // stroke: new Stroke({
        //         //     color: 'rgba(0,0,0,0.5)',
        //         //     width: 300
        //         // })
        //     }),
        // })
    ];
};
export const getClusterStyle = (feature: any, map: any) => {
    getGradient(map);
    return clusterBaseStyle(feature);
};

export const getPolylineGreyStyle = (scaleValue: number) => {
    return new Style({
        stroke: new Stroke({
            color: 'rgb(255,255,255)',
            width: 10 * scaleValue,
        }),
    });
};

export const getScaleValue = (zoomScale: number) => {
    const zoom = Math.trunc(zoomScale);
    const zoomIndex = zoom as keyof typeof scaleElem;
    return (scaleElem[zoomIndex] ? scaleElem[zoomIndex] : scaleElem.default);
};

export const getPolyLineBottomStyle = (scaleValue: number, feature: any) => {
    return new Style({
        stroke: new Stroke({
            color: '#ffffffaa',
            width: 5 / 4
        }),
        // text: new Text({
        //     text: feature.getId(),
        //     scale: 1.2,
        //     textAlign: 'center',
        //     font: 'Bold 16px Arial',
        //     fill: new Fill({color: '#ffffff'}),
        //     stroke: new Stroke({color: [0, 0, 0, 0.5], width: 3}),
        //     // placement: 'line',
        //     repeat: 1,
        //     offsetY: 10,
        // }),
        text: new Text(
            {
                textAlign: 'center',
                textBaseline: 'bottom',
                font: 'Bold 16px Arial',
                text: feature.get('id'),
                fill: new Fill({color: '#ffffff'}),
                stroke: new Stroke({color: '#000000', width: 3}),
                offsetX: 0,
                offsetY: 30,
                rotation: 0,
                placement: 'point'
            })
    });
};

export const getPolyLineUpperStyle = (scaleValue: number) => {
    return new Style({
        stroke: new Stroke({
            color: '#8d8d8d',
            width: 6 * scaleValue,
        }),
    });
};
export const getPolyLineTopStyle = (scaleValue: number) => {
    return new Style({
        stroke: new Stroke({
            color: '#c0bcbc',
            width: 3 * scaleValue,
        }),
    });
};

export const getPolyLineCornerStyle = (feature: any) => {
    return new Style({
        image: new CircleStyle({
            radius: 5,
            fill: new Fill({
                color: '#cbcbcb',
            }),
            stroke: new Stroke({
                color: '#7a7a7a',
                width: 2,
            })
        }),
        geometry: function (feature: any) {
            // return the coordinates of the first ring of the polygon
            const coordinates = feature.getGeometry().getCoordinates();
            return new MultiPoint(coordinates);
        },
    });
};

export const getColorByStatePolyLine = (state: any) => {
    let color = '#39dada';
    switch (state) {
        case 1:
            color = '#fa3746';
            break;
        case 2:
            color = '#ffeb00';
            break;
        case 3:
            color = '#8cf000';
            break;
    }
    return color;
};

export const getPolyLinePointsStyle = (scaleValue: number, hover: boolean, feature: {
    get: (arg0: string) => any;
} | undefined) => {
    // @ts-ignore
    const colorCode = getColorByStatePolyLine(feature.get('status'));

    const flatCoords = feature ? feature.get('geometry').flatCoordinates : [];
    const altitude = flatCoords.length === 3 ? flatCoords[2].toString() + ' m' : '';

    return new Style({
        image: new CircleStyle({
            radius: scaleValue * 5,
            // radius:5,
            fill: new Fill({
                color: colorCode,
                // color: '#989797',
            }),
            // scale:[1.8,0.7],
            stroke: new Stroke({
                // color: '#3d3d3d',
                color: hover ? 'black' : colorCode,
                width: 1,
            })
        }),
        text: new Text({
            text: altitude,
            offsetY: -25,
            fill: new Fill({
                color: 'white'
            }),
            stroke: new Stroke({color: 'black'}),
            scale: 1.5
        }),
    });
};

export const isNotUndefined = (val: string) => {
    if (typeof val === 'undefined') {
        return false;
    }
    return true;
};
