import {baseApi} from './baseApi';

export const injectedGlobalApi = baseApi.injectEndpoints({
    addTagTypes: ['Global'],
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: (args) => {
                const {projectId, roleId} = args;

                const url = `getUsers?project_id=${projectId}&role_id=${roleId}`;

                return {url: url};
            },
            providesTags: ['Global']
        }),
    }),
});

export const {useGetUsersQuery} = injectedGlobalApi;
