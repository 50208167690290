import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';
import {showMessageOnSuccess} from "../../components/global/CustomToast";
import {useTranslation} from "react-i18next";
import {confirmDialog} from "primereact/confirmdialog";


export type ElementChangesFile = {
    action: string,
    data: any
}

// Define a type for the slice state
interface ElementChangesData {
    elementChanges: any[],
    newElementOpened: boolean,
    mapReady4Saved: any,
    isDirty: boolean,
}

// Define the initial state using that type
const initialState: ElementChangesData = {
    elementChanges: [],
    newElementOpened: false,
    mapReady4Saved: false,
    isDirty: false,
};

export const elementChangesSlice: any = createSlice({
    name: 'elementChanges',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        mapReady: (state, action: PayloadAction): any => {
            state.mapReady4Saved = action.payload;
        },
        resetElementChanges: (state, action: PayloadAction<any>) => {
            //  console.log('reset mapping');
            state.isDirty = false;
            state.elementChanges = [];
            if (action.payload != null) {
                action.payload.mapReload();
            }
        },
        changeField: (state, action: PayloadAction<any>) => {
            let temp: any[] = JSON.parse(JSON.stringify(state.elementChanges));

            let found = false;

            for (let i = 0; i < temp.length; i++) {
                let item = temp[i];

                if (action.payload.inspection_id != null) {
                    if (item.inspection_id === action.payload.inspection_id) {
                        found = true;

                        item[action.payload.key] = action.payload.value;

                        break;
                    }
                } else if (action.payload.inspection_task_id != null) {
                    if (item.inspection_task_id === action.payload.inspection_task_id) {
                        found = true;

                        item[action.payload.key] = action.payload.value;

                        break;
                    }
                } else if (action.payload.element_id != null) {
                    if (item.element_id === action.payload.element_id) {
                        found = true;

                        item[action.payload.key] = action.payload.value;

                        break;
                    }
                }
            }

            if (!found) {
                temp.push({
                    element_id: action.payload.element_id,
                    inspection_id: action.payload.inspection_id,
                    inspection_task_id: action.payload.inspection_task_id,
                    order_id: action.payload.order_id,
                    [action.payload.key]: action.payload.value
                });
            }
            console.table(temp);

            state.isDirty = true;
            state.elementChanges = temp;
        },
        removeElement: (state, action: PayloadAction<any>) => {
            let temp: any[] = JSON.parse(JSON.stringify(state.elementChanges));

            for (let i = 0; i < temp.length; i++) {
                let item = temp[i];

                if (item.inspection_task_id === action.payload.inspection_task_type_id) {
                    temp.splice(i, 1);
                    break;
                }
            }

            state.elementChanges = temp;
        },
        addFile: (state, action: PayloadAction<any>) => {
            let temp: any[] = JSON.parse(JSON.stringify(state.elementChanges));

            let found = false;

            for (let i = 0; i < temp.length; i++) {
                let item = temp[i];

                if (item.element_id === action.payload.element_id) {
                    found = true;
                    if (!item.files) {
                        item.files = [];
                    }

                    item.files.push({action: 'add', ...action.payload.file});

                    break;
                }
            }

            if (!found) {
                temp.push({element_id: action.payload.element_id, files: [{action: 'add', ...action.payload.file}]});
            }
            state.elementChanges = temp;
        },
        removeFile: (state, action: PayloadAction<any>) => {
            let temp: any[] = JSON.parse(JSON.stringify(state.elementChanges));

            let found = false;

            for (let i = 0; i < temp.length; i++) {
                let item = temp[i];

                if (item.element_id === action.payload.element_id) {
                    found = true;
                    if (!item.files) {
                        item.files = [];
                    }

                    item.files.push({action: 'remove', ...action.payload.file});

                    break;
                }
            }

            if (!found) {
                temp.push({element_id: action.payload.element_id, files: [{action: 'remove', ...action.payload.file}]});
            }
            state.elementChanges = temp;
        },
        toggleNewElementOpened: (state, action: PayloadAction<any>) => {
            state.newElementOpened = !state.newElementOpened;
        },
        setIsDirty: (state, action: PayloadAction<any>) => {
            state.isDirty = action.payload;
        },
    },
});

export const {
    resetElementChanges,
    changeField,
    removeElement,
    addFile,
    removeFile,
    mapReady,
    toggleNewElementOpened,
    setIsDirty
} = elementChangesSlice.actions;

export default elementChangesSlice.reducer;
