import {FunctionComponent, useContext, useEffect, useState} from 'react';
import MapContext from '../map/MapContext';
import {Vector as VectorSource} from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {addLayerToCounterArray, setDataLoaded, setLayerArray} from '../../../redux/slices/GeomonitoringSlice';
import {
    clusterBaseStyle, createPoiClusterFeatures
} from '../../functions/Geo';
import geoSettings from '../../../config/geoSettings';
import Cluster from 'ol/source/Cluster';


type overlayProps = { projectId: number, projectType: string };
const ClusterPolylineOverlayLayer: FunctionComponent<overlayProps> = ({projectId, projectType}) => {
    // @ts-ignore
    const {map} = useContext(MapContext);
    const dispatch = useAppDispatch();
    const polyLineData=useAppSelector((state:any)=>state.configLayer.layerData.polylineCluster);
    const [overlayLayer, setOverlayLayer] = useState({
        getSource(): any {

        }
    });

    const [nSource, setNSource] = useState({
        removeFeature(feature: any) {
        },
        addFeature(mp: any) {
        },
        clear() {
        },
        addFeatures(features: any[]) {
        }
    });

    const [cSource, setCSource] = useState({
        clear() {
        },
        addFeature(feature: any) {
        },
        addFeatures(features: any[]) {
        },
        getFeatures() {

        }
    });

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        const source = new VectorSource({});
        setNSource(source);
        const clusterSource = new Cluster({
            distance: 50,
            minDistance: 30,
            source: source
        });

        setCSource(clusterSource);
        const clusterOverlayLayer = new VectorLayer({
            source: clusterSource,
            style: clusterBaseStyle,
            zIndex: geoSettings.poiLayerKey + 2
        });


        clusterOverlayLayer.set('id', 'clusterPolylineOverlayLayer');
        clusterOverlayLayer.set('toogleHidden', true);
        // @ts-ignore
        setOverlayLayer(clusterOverlayLayer);
        map.addLayer(clusterOverlayLayer);

        dispatch(setLayerArray({
            name: 'clusterPolylineOverlayLayer',
            mainLayerId: 'clusterPolylineOverlayLayer',
            id: 'clusterPolylineOverlayLayer',
            type: 'vector',
            toogleHidden: true,
            visible: true
        }));
       // dispatch(addLayerToCounterArray(projectId));
        //     console.log('add overlaylayers')
        return () => {
            if (map) {
                map.removeLayer(clusterOverlayLayer);
            }
        };
    }, [map]);

    useEffect(() => {
        // @ts-ignore
        if (overlayLayer ) {
            cSource.clear();
            nSource.clear();
            if (polyLineData&&polyLineData.originalData && polyLineData.originalData.length > 0) {
                createPoiClusterFeatures(polyLineData, null).then(result => {

                    if (result.clusterFeatures.length > 0) {
                        cSource.addFeatures(result.clusterFeatures);
                        nSource.addFeatures(result.clusterFeatures);
                    }
                    dispatch(setDataLoaded(true));
                });
            } else {
                dispatch(setDataLoaded(true));
            }
        }
    }, [polyLineData, overlayLayer]);

    return null;
};

export default ClusterPolylineOverlayLayer;
