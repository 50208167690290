import React, {useEffect} from 'react';
import Input from "../global/Input";
import {useTranslation} from "react-i18next";

export type UnitExplorerSettings = {
    showPath: boolean,
    showElementTopbar: boolean
    showElementsInElement: boolean,
}

export const initUnitExplorerSettings: UnitExplorerSettings =
    {
        showPath: true,
        showElementTopbar: true,
        showElementsInElement: true,
    }

type Props = {
    settings: UnitExplorerSettings;
    onChange: (settings: UnitExplorerSettings) => void
}

export const UnitExplorerSettings = (props: Props) => {
    const {t} = useTranslation(['common']);
    const [settings, setSettings] = React.useState<UnitExplorerSettings>(props.settings);

    const _setSettings = (key: string, value: any) => {
        setSettings((prevState: any) => {
            let temp = {...prevState};

            temp[key] = value;

            return temp;
        });
    };

    useEffect(() => {
        props.onChange(settings);
    }, [settings]);

    return <div>
        <Input edit={true} name={'showPath'} type={'checkbox'} label={t('ues_showPath')}
               value={settings.showPath} onChange={(e: any) => {
            _setSettings('showPath', e.target.value);
        }}/>
        <Input edit={true} name={'showElementTopbar'} type={'checkbox'} label={t('ues_showElementTopbar')}
               value={settings.showElementTopbar} onChange={(e: any) => {
            _setSettings('showElementTopbar', e.target.value);
        }}/>
        <Input edit={true} name={'showElementsInElement'} type={'checkbox'} label={t('ues_showElementsInElement')}
               value={settings.showElementsInElement} onChange={(e: any) => {
            _setSettings('showElementsInElement', e.target.value);
        }}/>
    </div>
}
