import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import MapContext from '../map/MapContext';
import {OverlayPanel} from 'primereact/overlaypanel';
import {Trans, useTranslation} from 'react-i18next';
import {getSpatialBookmarks} from '../../functions/Global';
import {transformCoords} from '../../../services/SpatialService';
import {ListBox} from 'primereact/listbox';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Controller, useForm} from 'react-hook-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import geoSettings from '../../../config/geoSettings';
import settings from '../../../config/settings';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {CustomConfirmDialog} from '../../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../../global/CustomToast';
import {checkPermission} from '../../../functions/functionLibrary';
import {permissions} from '../../../config/permissions';
import {SelectButton} from 'primereact/selectbutton';

interface propsObj {
    [key: string]: any
}

type controlProps = {
    projectId: any
}
const BookmarkPermaControl: FunctionComponent<controlProps> = (props) => {
    // @ts-ignore
    const {map} = useContext(MapContext);
    const keycloakToken = useAppSelector((state:any) => state.keycloakData.keycloak.keycloakToken);
    const {t} = useTranslation();
    const op = useRef(null);
    const [selectPosition, setSelectedPosition] = useState({data: null});
    const [repeatPosition, setRepeadedPosition] = useState(false);
    const [data, setData] = useState([]);
    const dataRef = useRef(data);
    const selectPositionRef = useRef(selectPosition);
    const [toogleAllowed, setToogleAllowed] = useState(false);
    const toogleRef = useRef(toogleAllowed);
    const checkedTrees = useAppSelector((state: any) => state.configLayer.layerData.layerTreeChecked);
    const checkedTreesRef = useRef(checkedTrees);
    const [visible, setVisible] = useState(false);
    const [formVisible, setFormVisible] = useState(true);
    const [permaLinkId, setPermaLinkId] = useState('');
    const [copyBttn, setCopyBttn] = useState(false);
    const copyBttnRef = useRef(copyBttn);
    const ref = useRef<any>(null);
    const isAllowedToEdit = checkPermission(permissions.saveProjectBookmark);
    const options = [{name: 'Nein', value: false}, {name: 'Ja', value: true}];
    const [personalBookmark, setPersonalBookmark] = useState(options[0].value);
    const [permaLinkBookmark, setPermalinkBookmark] = useState(options[0].value);
    const createBaseMapButton = () => {
        const buttonBookmarkMap = document.createElement('button');
        buttonBookmarkMap.id = 'buttonPermaBookmark';
        buttonBookmarkMap.title = t('geomon_bookmark');
        buttonBookmarkMap.innerHTML = '<span style="color: black"><i class="fas fa-star"/></span>';
        buttonBookmarkMap.addEventListener('click', openDropdown, false);
        return buttonBookmarkMap;
    };

    const openDropdown = (e: any) => {
        // @ts-ignore
        op.current.toggle(e);
        toogleFnc(true);
    };

    const toogleFnc = (val: boolean) => {
        setToogleAllowed(val);
        toogleRef.current = val;
    };

    const addControl = () => {

        const bookmarkElem = document.getElementById('buttonPermaBookmark');
        if (bookmarkElem === null) {
            const buttonBookmarkMap = createBaseMapButton();
            const elementLegende = document.getElementById('olControls');
            elementLegende?.appendChild(buttonBookmarkMap);
        }
        return [bookmarkElem];
    };

    // eslint-disable-next-line camelcase
    const prepareBookmark = (bookmark: { is_permalink: boolean; link: { name: any; }; id: any; }) => {
        if (!bookmark.is_permalink) {
            const bName = bookmark.link.name;
            const placeholderInt = 1212121212121;
            let int = 0;
            let string = bName;
            if (bName && bName.includes(':')) {
                const splitted = bName.split(':');
                int = splitted[0];
                string = splitted.pop();
            } else {
                int = placeholderInt;
            }
            return {
                id: bookmark.id,
                name: bName,
                data: bookmark,
                intSort: parseInt(String(int)),
                stringSort: string,
                numericalSort: int === placeholderInt ? false : true
            };
        }
        return null;
    };

    const sortBookmark = (bookmarkArray: any) => {
        const sortedData = bookmarkArray.sort((a: any, b: any) => a.numericalSort ? (a.intSort - b.intSort || a.stringSort.localeCompare(b.stringSort)) : a.stringSort.localeCompare(b.stringSort));
        const userBookmarks: any[] = [];
        const projectBookmarks: any[] = [];
        sortedData.forEach(function (val: any) {
            if (val.data.user_id !== null) {
                userBookmarks.push(val);
            } else {
                projectBookmarks.push(val);
            }
        });
        const newDataSet = [
            {
                name: t('geomon_projektbookmark'),
                items: projectBookmarks
            },

        ];
        if (userBookmarks.length > 0) {
            newDataSet.unshift(
                {
                    name: t('geomon_userbookmark'),
                    items: userBookmarks
                });
        }
        // @ts-ignore
        setData(newDataSet);
        // @ts-ignore
        dataRef.current = newDataSet;
    };

    const getBookmarks = () => {
        getSpatialBookmarks(props.projectId).then((bookmarks: any) => {
            const bookmarkArray: any = [];
            bookmarks.forEach((bookmark: any) => {
                const adjustedBM = prepareBookmark(bookmark);
                if (adjustedBM !== null)
                    bookmarkArray.push(adjustedBM);
            });

            sortBookmark(bookmarkArray);
        });
    };

    const contextMenuListener = () => {
        map.getViewport().addEventListener('contextmenu', function () {
            if (toogleRef.current) {
                // @ts-ignore
                op.current.toggle({currentTarget: null});
                toogleFnc(false);
            }
        });
    };


    const changeSelected = (e: { value: any; }) => {
        if (e.value === null) {
            setRepeadedPosition(true);
        } else {
            setSelectedPosition(e.value);
        }

    };

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] ? <small className="p-error">{errors[name].message}</small> :
            <small className="p-error">&nbsp;</small>;
    };


    const openDialog = () => {
        setFormVisible(true);
        setVisible(true);
    };
    const defaultValues = {
        permalinkName: '',
        isPermaLink: false,
        isUserBookmark: false
    };

    const {
        control,
        formState: {errors},
        handleSubmit,
        reset
    } = useForm({defaultValues});

    const onSubmit = (data: any) => {
        sortData(data);
        reset();
    };

    const sortData = async (data: {
        isUserBookmark: any;
        // eslint-disable-next-line @typescript-eslint/require-await
        isPermaLink: any; permalinkName: any;
    }) => {
        const layerArray = map.getLayers().getArray();
        const centerMap = map.getView().getCenter();
        const zoomLevel = map.getView().getZoom();
        const visibleLayerArray: { id: any; visible: any; }[] = [];
        layerArray.forEach(function (layer: { get: (arg0: string) => any; }) {
            if (layer.get('visible') === true || layer.get('visible') === 'true') {
                visibleLayerArray.push(layer.get('id'));
            }
        });

        const objToSave = {
            // eslint-disable-next-line camelcase
            project_id: props.projectId,
            link: JSON.stringify({name: data.permalinkName, visibleLayerIds: visibleLayerArray}),
            // eslint-disable-next-line camelcase
            position_x: centerMap[0],
            // eslint-disable-next-line camelcase
            position_y: centerMap[1],
            zoom: Math.round(zoomLevel),
            // eslint-disable-next-line camelcase
            is_permalink: false,
            // eslint-disable-next-line camelcase
            is_userBookmark: !isAllowedToEdit?true:personalBookmark,
            // eslint-disable-next-line camelcase
            epsg_code: 3857
        };

        savePermaLink(objToSave).then((res: any) => {
            if (res.success === true) {

                if (permaLinkBookmark) {
                    setCopyBttn(true);
                } else {
                    setCopyBttn(false);
                }
                const url = geoSettings.redirectUrlPermalink + 'project/' + props.projectId + '/' + res.data.id + '/#geomonitoring';
                setPermaLinkId(url);
                setFormVisible(false);
                getBookmarks();

            }

        });

        // setVisible(false);

    };

    const copyUrl = () => {
        navigator.clipboard.writeText(permaLinkId);
        // console.log(permaLinkId);
    };

    const savePermaLink = (data: any) => {
        const url = settings.apiUrl + '/createSpatialBookmarks';
        const requestOptions = {
            method: 'POST',
            headers: {
                authorization: 'Bearer ' + keycloakToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        };


        return new Promise((resolve, reject) => {
            fetch(url, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        resolve({
                            success: true,
                            data: result
                        });
                    },
                    (error) => {
                        reject({
                            success: false,
                            info: 'GET failed: ' + error
                        });
                    });
        });
    };
    const deleteBookmark = (data: any) => {
        const url = settings.apiUrl + '/deleteBookmark';
        const requestOptions = {
            method: 'DELETE',
            headers: {
                authorization: 'Bearer ' + keycloakToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        };


        return new Promise((resolve, reject) => {
            fetch(url, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        resolve({
                            success: true,
                            data: result
                        });
                    },
                    (error) => {
                        reject({
                            success: false,
                            info: 'GET failed: ' + error
                        });
                    });
        });
    };

    const deleteBookmarkAction = (option: { name: any; id: any; data: { user_id: null; }; }) => {
        const isPersonalBookMark = (option.data.user_id !== null);
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('geomon_deleteBookmarkMessage', {name: option.name}),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const data = {
                    // eslint-disable-next-line camelcase
                    project_id: props.projectId,
                    // eslint-disable-next-line camelcase
                    bookmark_id: option.id,
                    personal_bookmark: isPersonalBookMark
                };
                deleteBookmark(data).then((resp: any) => {
                    if (resp['error']) {
                        showMessageOnError(t('error'), t('geomon_errorDelete'));
                    } else {
                        showMessageOnSuccess(
                            t('geomon_successfull'),
                            t('geomon_bookmarkDeleted')
                        );
                        getBookmarks();
                        //  getAllPlants(plantId);
                    }
                });
                getBookmarks();
            }
        });
    };

    const setIsPersonalBookmark = (value: boolean) => {
        setPersonalBookmark(value);
        if (permaLinkBookmark&&value) {
            console.log('change permalink', value, permaLinkBookmark);
            setPermalinkBookmark(!value);
        }
    };

    const setIsPermalinkBookmark = (value: boolean) => {
        setPermalinkBookmark(value);
        if (value && personalBookmark) {
            console.log('change bookmark', value, personalBookmark);
            setPersonalBookmark(!value);
        }

    };


    useEffect(() => {
        checkedTreesRef.current = checkedTrees;
    }, [checkedTrees]);

    useEffect(() => {
        copyBttnRef.current = copyBttn;
    }, [copyBttn]);

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        const permaControl = addControl();
        return () => map.controls.remove(permaControl);
    }, [map]);


    useEffect(() => {

        if ((selectPosition && selectPosition.data) || repeatPosition) {
            const bookmarkData: any = selectPosition.data;
            let coords = [bookmarkData.position_x, bookmarkData.position_y];
            const epsg = bookmarkData.epsg_code;
            if (epsg !== 3857) {
                coords = transformCoords(coords, epsg, 3857);
            }
            map.getView().setZoom(bookmarkData.zoom);
            map.getView().setCenter(coords);
            selectPositionRef.current = selectPosition;
            setRepeadedPosition(false);
        }

    }, [selectPosition, repeatPosition]);

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        const bookmarkControl = addControl();
        getBookmarks();
        contextMenuListener();
        return () => map.controls.remove(bookmarkControl);
    }, [map]);

    const bookmarkTemplate = (option: {
        // eslint-disable-next-line camelcase
        data: any;
        user_id: any;
        id: any;
        // eslint-disable-next-line @typescript-eslint/ban-types
        name: {} | null | undefined; code: string;
    }) => {
        return (
            <div className="flex align-items-center">
                <div className="bookmarkTitle">{option.name}</div>
                {(option.data.user_id !== null || isAllowedToEdit) && <Button icon="pi pi-trash" aria-label="Cancel"
                    className="bookmarkBttn p-button-rounded p-button-secondary p-button-text filter-icon"
                    onClick={() => {
                        // setDeleteDialogVisible(true)
                        deleteBookmarkAction(option);
                    }}/>}
            </div>
        );
    };


    // @ts-ignore
    return (
        <>
            <OverlayPanel ref={op}>
                <div className="flex justify-content-center my-3">
                    <Button label={t('geomon_permalinkHeader') } onClick={openDialog}/>
                </div>
                <ListBox value={selectPosition} onChange={changeSelected} options={data} optionLabel="name"
                    itemTemplate={bookmarkTemplate} optionGroupLabel="name"
                    optionGroupChildren="items" placeholder={t('bookmarkPlaceholder')}
                    className="w-full md:w-14rem" listStyle={{maxHeight: '250px'}}/>
            </OverlayPanel>
            <Dialog header={formVisible ? t('geomon_permalinkHeader') : t('permalinkSuccess')} visible={visible}
                style={{width: '20vw'}}
                onHide={() => setVisible(false)}>
                {formVisible &&
                    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2">
                        <Controller
                            name="permalinkName"
                            control={control}
                            rules={{required: t('permalinkNameError')}}
                            render={({field, fieldState}) => (
                                <>
                                    <label htmlFor={field.name}
                                        className={classNames({'p-error': errors.permalinkName})}></label>
                                    <span className="p-float-label">
                                        <InputText id={field.name} value={field.value}
                                            className={classNames({'p-invalid': fieldState.error})}
                                            onChange={(e) => field.onChange(e.target.value)}/>
                                        <label htmlFor={field.name}>{t('geomon_permaLinkName')}</label>
                                    </span>
                                    {getFormErrorMessage(field.name)}
                                </>
                            )}
                        />

                        {(isAllowedToEdit && !permaLinkBookmark) &&
                            <div className=" justify-content-center m-2">
                                <label htmlFor={'isPersonalBookmark'}>{t('geomon_saveUserBookmark')}</label>
                                <SelectButton id={'isPersonalBookmark'} value={personalBookmark}  onChange={(e) => setIsPersonalBookmark(e.value)} optionLabel="name"
                                    options={options}/></div>}
                        <Button label={t('geomon_permalinkSave')} type="submit" icon="pi pi-check"/>
                    </form>}
                {(!formVisible && copyBttnRef.current) &&
                    <div><Trans i18nKey="permalinkSuccessMessage">Ihre Ansicht können Sie unter <a href={permaLinkId}>{permaLinkId}</a> aufrufen</Trans>
                        <Button icon={'fa fa-copy'} className="btn clipboard p-button-sm" onClick={copyUrl} title={t('geomon_copyToClipboard')}></Button>
                    </div>}
                {(!formVisible && !copyBttnRef.current) &&
                    <div>{t('geomon_sucessfullSavedBookmark')}</div>}
            </Dialog></>
    );
};

export default BookmarkPermaControl;
