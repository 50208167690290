/*
 * functionLibrary.ts
 * Author: lnappenfeld
 * Date: 29.04.2022
 *
 * Copyright: DMT GmbH & Co. KG
 *
 * global functions
 */

import {Nullable} from '../components/functions/Global';

export const emailValidation = (t: any) => {
    return {
        required: 'Email ' + t('input_required'),
        pattern: {
            value: /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            message: t('input_invalidEmailFormat')
        }
    };
};

export const smsValidation = (t: any, required: boolean = true) => {
    return {
        required: required ? ('SMS ' + t('input:required')) : false,
        pattern: {
            // value: /^\+?[1-9][0-9]{7,14}$/,
            value: /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
            message: t('input_invalidSMSFormat')
        }
    };
};

export const urlValidation = (t: any) => {
    // In vielen Foren wird gesagt, dass die aktuelle URL-Syntax zu komplex ist, um eine URL auf Richtigkeit zu
    // validieren (https://stackoverflow.com/questions/1410311/regular-expression-for-url-validation-in-javascript).
    // Man sollte stattdessen auf Libraries setzen, die das übernehmen. Für unsere Fälle reicht das aber erstmal aus,
    // auch weil bei dieser Validierung der Anfangstext 'https://' benötigt wird.
    return {
        pattern: {
            // http:// oder https:// ist erforderlich
            value: /(http|https):\/\/([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
            message: t('input_invalidURLFormat')
        }
    };
};

export const ipValidation = (t: any) => {
    return {
        pattern: {
            value: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            message: t('input_invalidIpFormat')
        }
    };
};

export const integerValidation = (t: any) => {
    return {
        pattern: {
            value: /^[0-9]*$/,
            message: t('input_invalidIntegerFormat')
        }
    };
};

/**
 * Prüft ob der vom Nutzer eingetragene Wert größer oder gleich ( >= ) ist, wie der Schwellwert minNumber.
 * @param t
 * @param minNumber -   Schwellwert der kleiner oder gleich sein soll als der Input-Wert
 */
export const minValidation = (t: any, minNumber: number | null) => {
    return {
        min: {
            value: minNumber,
            message: t('input_numberTooSmall', { number: minNumber })
        }
    };
};

/**
 * Prüft ob der vom Nutzer eingetragene Wert kleiner oder gleich ( <= ) ist, wie der Schwellwert maxNumber.
 * @param t
 * @param maxNumber -   Schwellwert der größer oder gleich sein soll als der Input-Wert
 */
export const maxValidation = (t: any, maxNumber: number | null) => {
    return {
        max: {
            value: maxNumber,
            message: t('input_numberTooLarge', { number: maxNumber })
        }
    };
};

/**
 * Prüft ob der vom Nutzer eingetragene Wert kleiner ( < ) ist als der Schwellwert lessThan.
 * @param t
 * @param lessThan  -  Schwellwert der größer sein soll als der Input-Wert
 */
export const lessValidation = (t: any, lessThan: number | null) => {
    return {
        validate: (event: any) => {
            if (event) {
                let value = event;
                if (typeof event !== 'number') value = event.value;
                // Wenn die Werte null sind, ignoriere die Validierung
                return ((lessThan === null || value === null)
                    ? true
                    : (value < lessThan) || t('input_lessValidation', { number: lessThan })
                );
            } else {
                return true;
            }
        }
    };
};

/**
 * Prüft ob der vom Nutzer eingetragene Wert größer ( > ) ist als der Schwellwert greaterThan.
 * @param t
 * @param greaterThan   -   Schwellwert der kleiner sein soll als der Input-Wert
 */
export const greaterValidation = (t: any, greaterThan: number | null) => {
    return {
        validate: (event: any) => {
            if (event) {
                let value = event;
                if (typeof event !== 'number') value = event.value;
                // Wenn die Werte null sind, ignoriere die Validierung
                return ((greaterThan === null || value === null)
                    ? true
                    : (value > greaterThan) || t('input_greaterValidation', { number: greaterThan })
                );
            } else {
                return true;
            }
        }
    };
};

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

const getIndexOfValueWhichIsLessThanValue = (value: number, numbersToCompare: number[]) => {
    for (let i=0; i<numbersToCompare.length; i++) {
        if (value >= numbersToCompare[i]) {
            return i;
        }
    }
    return -1;
};

const getIndexOfValueWhichIsGreaterThanValue = (value: number, numbersToCompare: number[]) => {
    for (let i=0; i<numbersToCompare.length; i++) {
        if (value <= numbersToCompare[i]) {
            return i;
        }
    }
    return -1;
};

/**
 * Prüft ob der vom Nutzer eingetragene Wert kleiner ist als alle im Array lessThanNumbers gelisteteten Werte.
 * @param t
 * @param lessThanNumbers   -   Array mit den Werten die größer sein müssen als der Input-Wert
 */
export const lessArrayValidation = (t: any, lessThanNumbers: Nullable<number>[]) => {
    // Lösche die Vergleichswerte die Null sind und ignoriere diese
    const filteredArray: number[] = lessThanNumbers.filter(notEmpty);
    return {
        validate: (event: any) => {
            if (event) {
                let value = event;
                if (typeof event !== 'number') value = event.value;
                // Gebe den Index des Werts zurück, dass die Validierung verletzt. Bei -1 ist die Benutereingabe ok
                const index = getIndexOfValueWhichIsLessThanValue(value, filteredArray);
                // Wenn der Wert null ist, ignoriere die Validierung
                return (value === null
                    ? true
                    // Wenn index = -1 wird true zurückgegeben und die Validierung wahr erfolgreich, da alle Werte
                    // größer sind als value.
                    : (index < 0) || t('input_lessValidation', { number: filteredArray[index] })
                );
            } else {
                return true;
            }
        }
    };
};

/**
 *  Prüft ob der vom Nutzer eingetragene Wert größer ist als alle im Array greaterThanNumbers gelisteteten Werte.
 * @param t
 * @param greaterThanNumbers    -   Array mit den Werten die kleiner sein müssen als der Input-Wert
 */
export const greaterArrayValidation = (t: any, greaterThanNumbers: Nullable<number>[]) => {
    // Lösche die Vergleichswerte die Null sind und ignoriere diese
    const filteredArray: number[] = greaterThanNumbers.filter(notEmpty);
    return {
        validate: (event: any) => {
            if (event) {
                let value = event;
                if (typeof event !== 'number') value = event.value;
                // Gebe den Index des Werts zurück, dass die Validierung verletzt. Bei -1 ist die Benutereingabe ok
                const index = getIndexOfValueWhichIsGreaterThanValue(value, filteredArray);
                // Wenn der Wert null ist, ignoriere die Validierung
                return (value === null
                    ? true
                    // Wenn index = -1 wird true zurückgegeben und die Validierung wahr erfolgreich, da alle Werte
                    // kleiner sind als value.
                    : (index < 0) || t('input_greaterValidation', { number: filteredArray[index] })
                );
            } else {
                return true;
            }
        }
    };
};

/**
 * Prüft ob der vom Nutzer eingetragene String einen anderen Wert hat, als der übergebene compareStr.
 * @param t
 * @param errorMessageKey   -   Schlüssel der Fehlermeldung die angezeigt werden soll
 * @param compareStr        -   Text, mit dem die Eingabe des Benutzer verglichen wird
 */
export const otherStringValidation = (t: any, errorMessageKey: string, compareStr: string | null) => {
    return {
        validate: (event: any) => {
            if (event) {
                let value = event;
                if (typeof event !== 'string') value = event.value;
                // Wenn die Werte null sind, ignoriere die Validierung
                return ((compareStr === null || value === null)
                    ? true
                    : (value !== compareStr) || t(errorMessageKey, { compareStr: compareStr })
                );
            } else {
                return true;
            }
        }
    };
};

export const getSumOf1ToN = (n: number) => {
    let sum = 0;
    for (let i = 0; i <= n; i++) {
        sum += i;
    }
    return sum;
};
/**
 * Group by id for filter dropdown values. Find equals rows in values and filter them
 * @param values        - value Array e.g. channels, notification_action_map, ...
 * @param idString      - idString in values e.g. 'sensor_id'
 * @param nameString    - nameString in values e.g. 'sensor_name'
 */
export const getGroupedItems = (values: any[], idString: string, nameString: string) => {

    const groupedValues = [] as any;
    //values.sort();
    for (const value of values) {
        const item: any = {
            value: value[idString],
            name: value[nameString]
        };
        let itemIsAlreadyIncluded = false;
        for (const groupedValue of groupedValues) {
            if (groupedValue.value === item.value) {
                itemIsAlreadyIncluded = true;
            }
        }
        if (!itemIsAlreadyIncluded) {
            groupedValues.push(item);
        }
    }
    groupedValues.sort((a:any, b:any) => {
        const fa = a.value;
        const fb = b.value;
        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    });
    //console.log(groupedValues);
    return groupedValues;
};
export function checkPermission(permissionsToCheck:number){
    const currentPermission: number[] = JSON.parse(localStorage.getItem('permissions') as string);
    // console.log("current Permissions: ", currentPermission);
    // console.log(permissionsToCheck)
    // console.log(currentPermission !== null && currentPermission.includes(permissionsToCheck))
    if (currentPermission !== null && currentPermission.includes(permissionsToCheck))
        return true;
    else
        return false;
}
export enum ProjectType {
    GeoMonitoring = 1,
    ConditionMonitoring = 2,
    MonitoringEverything = 3
}
export function checkProjectType(projectType: ProjectType) {
    const currentProject: any = JSON.parse(localStorage.getItem('project') as string);
    // console.log('currentProject: ', currentProject);
    // console.log(projectType);
    // Wenn es MonitoringEverything ist, wird immer true zurück gegeben, weil dann alles angezeigt werden soll
    if (currentProject !== null && currentProject.project_type_id &&
        (currentProject.project_type_id === ProjectType.MonitoringEverything || currentProject.project_type_id === projectType))
        return true;
    else
        return false;
}
