import React, {useCallback, useEffect, useRef, useState} from 'react';
import Input from '../global/Input';
import {Button} from 'primereact/button';
import {useTranslation} from 'react-i18next';
import {
    ElementTypeItem,
    getElementType,
    getElementTypeIconClass,
    getElementTypeName,
    UnitItem
} from '../functions/Units';
import {useGetDocumentsQuery,} from '../../redux/rtk/injectedUnitsApi';
import {checkUnsavedChangesAndGoOn, Nullable} from '../functions/Global';
import {TabPanel, TabView} from 'primereact/tabview';
import {ElementFileManagement} from './ElementFileManagement';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {ProgressSpinner} from 'primereact/progressspinner';
import settings from '../../config/settings';
import {Galleria} from 'primereact/galleria';
import {classNames} from 'primereact/utils';
import GeoData from '../../views/GeoData';
import {useGetInspectionsQuery} from '../../redux/rtk/injectedInspectionApi';
import StatusLight from '../global/StatusLight';
import moment from 'moment';
import {UnitObjectInput} from './UnitObjectInput';
import {UnitName} from './UnitName';
import {UnitFields, UnitFieldType} from './UnitFields';
import {UnitSubtype} from './UnitSubtype';
import {confirmDialog} from 'primereact/confirmdialog';
import {InspectionElementTasks} from '../inspectionManagement/InspectionElementTasks';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy, faGlasses, faPaste, faScissors} from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from 'react-router-dom';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {Tooltip} from 'primereact/tooltip';
import {VirtualScroller} from 'primereact/virtualscroller';
import {changeField} from "../../redux/slices/ElementChangesSlice";
import {checkPermission} from "../../functions/functionLibrary";
import {permissions} from "../../config/permissions";

type Props = {
    project: any,
    item: UnitItem | undefined,
    elementTypes: ElementTypeItem[],
    topElements: any[],
    editMode: boolean,
    isPipeline: boolean,
    showTopbar: boolean,
    showElements: boolean,
    showLoading?: boolean,
    geoData?: any,
    onClick: (id: string, scrollIntoView: boolean) => void,
    onAddClick: (parentId: string, object: ElementTypeItem | undefined, sibling: boolean) => void,
    onConnectClick: (startId: string, targetId: string) => void,
    onDeleteClick?: (id: string) => void,
    onInactiveClick?: (id: string) => void,
    copiedElement?: UnitItem,
    copiedElementOperation?: string,
    onCopyClick?: (element: UnitItem) => void,
    onCutClick?: (element: UnitItem) => void,
    onPasteClick?: (id: string) => void,
    saveData: () => void,
    onClickTogglePipelineOrder: (id: string) => void,
    // onChangeInspectionStatus: (status: number) => void,
}

export const UnitExplorerContent = (props: Props) => {
    const {t} = useTranslation(['common']);

    const itemSize = 73;

    const elementsRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    const isDirty = useAppSelector((state: any) => {
        return state.elementChangesData.isDirty;
    });
    const elementChanges: any[] = useAppSelector((state: any) => state.elementChangesData.elementChanges);
    const mapReady: any = useAppSelector((state: any) => state.elementChangesData.mapReady4Saved);
    const apiCalls: any = useAppSelector((state: any) => state.apiCallsData.apiCalls);
    const dispatch = useAppDispatch();

    const documentsQuery = useGetDocumentsQuery({
        projectId: props.project.id,
        elementId: props.item ? props.item.id : undefined
    }, {skip: !props.item});

    const inspectionQuery = useGetInspectionsQuery({
        projectId: props.project.id,
        elementId: props.item ? props.item.id : undefined
    }, {skip: !props.item});

    const [inspections, setInspections] = useState<any[]>([]);

    const [item, setItem] = useState<UnitItem | undefined>(props.item);
    const [elementType, setElementType] = useState<Nullable<ElementTypeItem>>(null);
    const [filesCount, setFilesCount] = useState<number>(0);

    const [belongsTo, setBelongsTo] = useState<string>(props.item?.belong_to || '');

    const [previewImages, setPreviewImages] = useState<any[]>([]);

    const [disableSubmit, setDisableSubmit] = useState<any>(true);

    const [sortedElements, setSortedElements] = useState<any[]>([]);
    const [sortElement, setSortElement] = useState<string>('unit_sortelement_name');
    const [elementsHeight, setElementsHeight] = useState<number>(330);

    const sortElements = ['unit_sortelement_name', 'unit_sortelement_status'];
    const [editMode, setEditMode] = useState<boolean>(props.editMode);

    useEffect(() => {
        // setItem(null);
        setItem(undefined);
    }, []);

    useEffect(() => {
        setEditMode(props.editMode);
    }, [props.editMode]);

    // useEffect(() => {
    //     let result = sortedElements.length * itemSize;
    //     if (result > 500) {
    //         result = 500;
    //     }
    //     setElementsHeight(result);
    // }, [sortedElements]);

    useEffect(() => {
        if (item) {
            console.log('item', item);
            setElementType(getElementType(item.element_type_id, props.elementTypes));
        }
    }, [item]);

    useEffect(() => {
        if (props.item) {
            if (inspectionQuery && inspectionQuery.data) {
                // setInspections(inspectionQuery.data.filter((item: any) => {
                //     if (props.item) {
                //         return item.element_id === props.item.id;
                //     }
                // }));

                setInspections(inspectionQuery.data);
            }
        }
    }, [inspectionQuery]);

    useEffect(() => {
        if (props.item) {
            setItem((prevState) => {
                let temp = prevState ? JSON.parse(JSON.stringify(prevState)) : undefined;

                temp = {...temp, ...props.item};

                return temp;
            });
        }
    }, [props.item]);

    useEffect(() => {
        if (item && item.children) {
            const temp = [...item.children].sort((a: UnitItem, b: UnitItem) => {
                switch (sortElement) {
                    case 'unit_sortelement_name':
                        return 1;
                    case 'unit_sortelement_status':
                        const s1 = a.inspection_task_status || 999;
                        const s2 = b.inspection_task_status || 999;

                        return s1 > s2 ? 1 : s1 < s2 ? -1 : 0;
                }
                return 1;
            });
            setSortedElements(temp);
        }
    }, [sortElement]);

    const onClickOpenImage = useCallback((src: string) => {
            window.open(src);
        },
        []);

    const itemTemplate = (item: any) => {
        return (<div className={'relative'}><img src={item.src}
            // onError={(e: any) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                                                 alt={item.alt} style={{
            width: 'auto',
            maxWidth: '100%',
            maxHeight: '250px'
        }}/>
            <div className={'mt-2 my-auto flex'}><label className={'my-auto'}>{item.alt}</label>
                <div className={'p-button p-button-text ml-2 pi pi-external-link'} onClick={() => {
                    onClickOpenImage(item.src);
                }}></div>
            </div>
        </div>);
    };


    const thumbnailTemplate = (item: any) => {
        return (<img src={item.src}
            // onError={(e: any) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                     alt={item.alt} style={{maxHeight: '40px'}}/>);
    };

    const getOptionItems = () => {
        let result: any[] = [];

        let separatorPushed = false;

        if (checkPermission(permissions.editElements) && props.item && props.item.element_type_id !== 8 && props.isPipeline && !props.item?.parent.length) {
            result.push({
                label: t('togglePipelineOrder'),
                icon: 'pi pi-sort-alt',
                command: () => {
                    props.onClickTogglePipelineOrder(props.item ? props.item.id : '');
                }
            });
        }

        if (props.copiedElement && props.item && props.copiedElement.id !== props.item.id &&
            (elementType && elementType.children_type_ids && elementType.children_type_ids.includes(props.copiedElement.element_type_id)) &&
            props.item.is_active && props.onPasteClick) {

            if (!separatorPushed && result.length) {
                result.push({separator: true});
                separatorPushed = true;
            }

            result.push({
                label: t('units_paste_element'),
                icon: <FontAwesomeIcon className={'mr-2'} icon={faPaste}/>,
                command: () => {
                    if (props.copiedElement) {
                        confirmDialog({
                            header: t('dialogs_pasteElementHeader'),
                            message: t('dialogs_pasteElementMessage'),
                            icon: 'pi pi-exclamation-triangle',
                            acceptLabel: t('yes'),
                            rejectLabel: t('no'),
                            accept: () => {
                                if (props.onPasteClick && item != null) {
                                    props.onPasteClick(item.id);
                                }
                            }
                        });
                    }
                }
            });
        }

        // if (checkPermission(permissions.editElements) && !props.isPipeline && props.item && elementType && elementType.has_parent && props.item.is_active && props.onCopyClick) {
        //
        //     if (!separatorPushed && result.length) {
        //         result.push({separator: true});
        //         separatorPushed = true;
        //     }
        //
        //     result.push({
        //         label: t('units_copy_element'),
        //         icon: <FontAwesomeIcon className={'mr-2'} icon={faCopy}/>,
        //         command: () => {
        //             if (props.onCopyClick && item != null) {
        //                 props.onCopyClick(item);
        //             }
        //         }
        //     });
        // }

        if (checkPermission(permissions.moveElement) && !props.isPipeline && props.item && elementType && elementType.has_parent && props.item.is_active && props.onCutClick) {

            if (!separatorPushed && result.length) {
                result.push({separator: true});
                separatorPushed = true;
            }

            result.push({
                label: t('units_cut_element'),
                icon: <FontAwesomeIcon className={'mr-2'} icon={faScissors}/>,
                command: () => {
                    if (props.onCutClick && item != null) {
                        props.onCutClick(item);
                    }
                }
            });
        }

        separatorPushed = false;

        if (checkPermission(permissions.deleteElement) && props.item && props.item.is_active && !!props.item?.parent.length && props.onInactiveClick) {
            if (!separatorPushed && result.length) {
                result.push({separator: true});
                separatorPushed = true;
            }

            result.push({
                label: t('units_set_element_inactive'),
                className: 'color-danger',
                icon: 'pi pi-ban',
                command: () => {
                    confirmDialog({
                        header: t('dialogs_setElementInactiveHeader'),
                        message: t('dialogs_setElementInactiveMessage'),
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: t('yes'),
                        rejectLabel: t('no'),
                        accept: () => {
                            if (props.onInactiveClick && item != null) {
                                props.onInactiveClick(item.id);
                            }
                        }
                    });
                }
            });
        }

        if (checkPermission(permissions.deleteElement) && item && !item.inspection_is_active && !item.inspection_is_active_passive) {

            if (!separatorPushed && result.length) {
                result.push({separator: true});
                separatorPushed = true;
            }

            result.push({
                label: t('units_remove_this_element'),
                className: 'color-danger',
                icon: 'pi pi-times',
                command: () => {
                    confirmDialog({
                        header: t('dialogs_removeElementHeader'),
                        message: t('dialogs_removeElementMessage'),
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: t('yes'),
                        rejectLabel: t('no'),
                        accept: () => {
                            if (props.onDeleteClick && item != null) {
                                props.onDeleteClick(item.id);
                            }
                        }
                    });
                }
            });
        }

        return result;
    }

    // const changeInspectionStatus = useCallback((inspectionId: string | undefined, status: number) => {
    //     let result = 0;
    //
    //     if (inspections && inspections.length) {
    //         result = 3;
    //
    //         for (let i = 0; i < inspections.length; i++) {
    //             const insp = inspections[i];
    //
    //             let inspStatus = insp.inspection_status;
    //
    //             if (insp.id === inspectionId) {
    //                 inspStatus = status;
    //             }
    //
    //             if (result > inspStatus) {
    //                 result = inspStatus;
    //             }
    //         }
    //     }
    //
    //     props.onChangeInspectionStatus(result);
    // }, [props.onChangeInspectionStatus, inspections]);

    const onChangeTopElements = useCallback((e: any) => {
        checkUnsavedChangesAndGoOn(t, isDirty, dispatch, () => {
            props.onClick(e.target.value, true);
        });
    }, [isDirty, props.onClick]);

    useEffect(() => {
        let disabled = true;
        switch (true) {
            case mapReady:
                disabled = false;
                break;
        }
        setDisableSubmit(disabled);

        //  console.log((!elementChanges || !elementChanges.length) , mapReady);
    }, [mapReady]);

    useEffect(() => {
        let disabled = false;
        if (!elementChanges || !elementChanges.length) {
            disabled = true;
        }

        if (!disabled) {
            let field = elementChanges.find((x: any) => Object.keys(x).includes('name'));

            if (field) {
                if (!field.name || field.name === '') {
                    disabled = true;
                } else if (!field.name.match(/^[a-zA-Z0-9-_. ÄÖÜäöü]*$/)) {
                    disabled = true;
                }
            }
        }
        setDisableSubmit(disabled);
    }, [elementChanges]);

    return (
        <>
            {editMode && <div className={'mb-3 justify-content-end w-full flex ue_options-button'}>
                <UnitObjectInput
                    project={props.project}
                    item={item}
                    elementTypes={props.elementTypes}
                    onClick={props.onAddClick}
                    onConnectClick={props.onConnectClick}
                    additionalItems={getOptionItems()}>
                </UnitObjectInput></div>}
            {props.showTopbar && <div>
                <div className={'grid'}>
                    <div className={'col-6 ue_back-button mb-4'}>
                        <Button
                            icon={(item && item.parent && item.parent.length ? 'pi pi-arrow-left' : 'pi pi-home')}
                            style={!item ? {visibility: 'hidden'} : undefined}
                            className={'p-button-outlined p-button-rounded'}
                            onClick={() => {
                                if (item && item.parent && item.parent.length) {
                                    props.onClick(item.parent[item.parent.length - 1].id, true);
                                } else {
                                    props.onClick('', false);
                                    const hash = window.location.hash.split('_');

                                    if (hash.length) {
                                        window.location.hash = hash[0];
                                    }
                                }
                            }}/>
                    </div>
                    <div className={'col-6 pl-2 ue_top-elements'}>
                        {props.topElements && <Input edit={true} name={'top_element'} type={'dropdown'}
                                                     dropdownOptions={props.topElements}
                                                     placeholder={t('units_elements')}
                                                     value={item && item.parent && item.parent.length ? item.parent[0].id : item ? item.id : undefined}
                                                     onChange={onChangeTopElements}/>}
                    </div>
                </div>
            </div>}
            {props.showLoading &&
                <div className={'unit_explorer-content-loading flex justify-content-center w-full'}><ProgressSpinner
                    className={'m-auto'}/>
                </div>}
            <div className={'grid col'}>
                {item && <TabView renderActiveOnly={false} className={'w-full mt-2'}>
                    <TabPanel
                        header={<div className={'flex'}><i className={'mr-2 ' + item.icon}/>{t('units_tabs_info')}
                        </div>}>
                        <br/>
                        {<div className={'grid'}>
                            {elementType && elementType.location_supported &&
                                <div
                                    className={classNames('mb-3 col-12 overflow-hidden', previewImages.length > 0 ? 'lg:col-6' : '')}
                                    style={{height: '450px'}}>
                                    {/* <h5 className="input-label">{t('units_position')}</h5>*/}
                                    {/* <h5 className="input-label">{t('units:position')}</h5>*/}
                                    <div id={'map'} className={'map h-full w-full'}>
                                        <GeoData divheight={'436px'} item={item} alarm={false}
                                                 configActive={false} onConfigButtonClick={null}
                                                 onConfigClick={null}
                                                 baseControlEnabled={true}
                                                 project={props.project} show={true} mapId={item.id}
                                                 mapActive={false}/>
                                    </div>
                                </div>}
                            {previewImages.length > 0 &&
                                <div
                                    className={classNames('mb-3 col-12', elementType && elementType.location_supported ? 'lg:col-6' : '')}
                                    style={{height: '450px'}}>
                                    {/* <h5 className="input-label">{t('units_pictures')}</h5>*/}
                                    <Galleria value={previewImages}
                                              className={'h-full'}
                                              thumbnailsPosition={'top'}
                                              showThumbnails={previewImages.length > 1}
                                              item={itemTemplate} thumbnail={thumbnailTemplate}
                                    />
                                </div>}
                            <div className={'col-12'}>
                                <div className={'grid'}>
                                    <div className={'col-12 lg:col-4'}>
                                        <UnitName key={'unitname'} item={item}
                                                  elementTypes={props.elementTypes}/>
                                    </div>
                                    <div className={'col-12 lg:col-4'}>
                                        <UnitSubtype key={'unitsubtype'} item={item}
                                                     edit={true}
                                                     project={props.project}
                                                     elementTypes={props.elementTypes}/>
                                    </div>
                                    {props.item && !props.item.parent.length && props.item.element_type_id !== 8 &&
                                        <div className={'col-12 lg:col-4'}>
                                            <Input label={t('unit_strand_belongs_to')}
                                                   edit={checkPermission(permissions.editElements)} name={'belongs_to'}
                                                   type={'dropdown'}
                                                   value={belongsTo}
                                                   dropdownOptions={props.topElements.filter((item: any) => {
                                                       return item.belongsTo;
                                                   })} onChange={(e: any) => {
                                                setBelongsTo(e.target.value);
                                                dispatch(changeField({
                                                    element_id: props.item?.id,
                                                    key: 'belong_to',
                                                    value: e.target.value
                                                }));
                                            }}/>
                                        </div>}
                                </div>
                            </div>
                            <div className={'col-12'}>
                                <div className={'grid'}>
                                    <UnitFields project={props.project} fields={item.fields} id={item.id}
                                                type={UnitFieldType.element} className={'col-12 lg:col-4'}/>
                                    {checkPermission(permissions.editElements) &&
                                        <div className={'col-12 flex justify-content-end'}>
                                            <Button className={'p-button p-button-outlined p-button-success'}
                                                    icon={'pi pi-check my-auto mr-2'} onClick={props.saveData}
                                                    disabled={disableSubmit}>{t('save')}</Button>
                                        </div>}
                                </div>
                            </div>

                            {checkPermission(permissions.seeInspections) && item && item.inspection_task_status > 0 && inspections.length > 0 &&
                                <div className={'col-12'}>
                                    <h2>{t('unit_inspections')}</h2>
                                    <Accordion activeIndex={0}>
                                        {inspections.map((inspection, index) => {
                                            return (
                                                <AccordionTab header={<div className={'grid ml-2'}>
                                                    <div className={'col'}>
                                                        <h5>{t('name')}</h5>
                                                        <p>{inspection.name}</p>
                                                    </div>
                                                    <div className={'col'}>
                                                        <h5>{t('inspection_columns_timestamp')}</h5>
                                                        <p>{moment(inspection.timestamp).format(t('datetimeFormat'))}</p>
                                                    </div>
                                                    <div className={'col-6'}>
                                                        <h5>{t('inspection_columns_inspection_type_name')}</h5>
                                                        <p>{inspection.inspection_type_name}</p>
                                                    </div>
                                                    <div className={'col justify-content-end'}>
                                                        <div className={'flex justify-content-center'}>
                                                            <StatusLight id={'inspection_task_status'}
                                                                         value={inspection.inspection_status}
                                                                         info={''} light={false}
                                                                         horizontal={true}/>
                                                        </div>
                                                        <div className={'flex justify-content-center mt-3'}>
                                                            <Button
                                                                className={'p-button p-button-outlined p-button-text'}
                                                                onClick={() => {
                                                                    const url = `/project/${props.project.id}/#inspections_${inspection.id}`;

                                                                    navigate(url);
                                                                }}>
                                                                <Tooltip target={'#JumpToInspections'}
                                                                         content={t('jump_to_inspections')}
                                                                         position={'top'}/>
                                                                <FontAwesomeIcon id='JumpToInspections'
                                                                                 className="sidebar-icon"
                                                                                 icon={faGlasses}/>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    {/* <div className={'col'}>*/}
                                                    {/*    <h5>{t('inspection_columns_auf_tuev')}</h5>*/}
                                                    {/*    <p>{inspection.auf_tuev}</p>*/}
                                                    {/* </div>*/}
                                                </div>}>
                                                    {props.item &&
                                                        <InspectionElementTasks project={props.project}
                                                                                inspectionId={inspection.id}
                                                                                elementId={props.item.id}
                                                                                elementName={props.item.name}
                                                                                showAddButton={false}
                                                            // onStatusChanged={changeInspectionStatus}
                                                        />}
                                                </AccordionTab>);
                                        })}
                                    </Accordion>
                                </div>}

                            {props.showElements && item && item.children && item.children.length > 0 &&
                                <div className={'col-12'}>
                                    <div className={'grid'}>
                                        <div className={'col'}>
                                            <h2>{t('units_elements') + (item.children ? ' (' + item.children.length + ')' : '')}</h2>
                                        </div>
                                        <div className={'col-fixed'}><Input edit={true} name={'sortelement'}
                                                                            type={'dropdown'}
                                                                            dropdownOptions={sortElements.map((item: string) => {
                                                                                return {label: t(item), value: item};
                                                                            })}
                                                                            value={sortElement} onChange={(e: any) => {
                                            setSortElement(e.target.value);
                                        }}/></div>
                                    </div>

                                    <div className={'ue_content-elements'}>
                                        {item && <div className={'grid'}>
                                            {/*{sortedElements.map((item: any, index: number) => {*/}
                                            {/*    return (<div className={'col-12 md:col h-auto'}>*/}
                                            {/*        <div*/}
                                            {/*            className={'ue_container-item grid text-center p-2 card inspection-status-' + item.inspection_task_status}*/}
                                            {/*            onClick={() => {*/}
                                            {/*                props.onClick(item.id, true);*/}
                                            {/*            }}>*/}
                                            {/*            <div className={'w-full'}><i*/}
                                            {/*                className={'ue_container-item-icon ' + getElementTypeIconClass(item.element_type_id, props.elementTypes)}/>*/}
                                            {/*            </div>*/}
                                            {/*            <div className={'col p-1'}>*/}
                                            {/*                <p>{`${item.name} (${getElementTypeName(item.element_type_id, props.elementTypes)})`}</p>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    </div>);*/}
                                            {/*})}*/}

                                            <VirtualScroller items={sortedElements} itemSize={itemSize}
                                                             style={{width: '100%', height: elementsHeight + 'px'}}
                                                             itemTemplate={(item) => {
                                                                 return (<div className={'col-12 md:col h-auto'}>
                                                                     <div
                                                                         className={'ue_container-item grid text-center p-2 card inspection-status-' + item.inspection_task_status}
                                                                         onClick={() => {
                                                                             props.onClick(item.id, true);
                                                                         }}>
                                                                         <div className={'w-full'}><i
                                                                             className={'ue_container-item-icon ' + getElementTypeIconClass(item.element_type_id, props.elementTypes)}/>
                                                                         </div>
                                                                         <div className={'col p-1'}>
                                                                             <p>{`${item.name} (${getElementTypeName(item.element_type_id, props.elementTypes)})`}</p>
                                                                         </div>
                                                                     </div>
                                                                 </div>);
                                                             }}/>
                                        </div>}
                                    </div>
                                </div>}
                        </div>}
                    </TabPanel>
                    {checkPermission(permissions.seeDocuments) && <TabPanel header={<div className={'flex'}><i
                        className={'mr-2 pi pi-file'}/>{t('units_tabs_files') + (filesCount > 0 ? ` (${filesCount})` : '')}
                    </div>}>
                        <br/>
                        <ElementFileManagement project={props.project} id={item.id} type={UnitFieldType.element}
                                               editable={true}
                                               setFilesCount={setFilesCount}
                                               setPreviewImages={setPreviewImages}
                                               showPreview={true}/>
                    </TabPanel>}
                </TabView>}
            </div>
        </>
    );
};
