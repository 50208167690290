import React, {useEffect, useState} from 'react';
import {ElementTypeItem, getElementType, UnitItem} from "../functions/Units";
import Input from "../global/Input";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {addApiCalls} from "../../redux/slices/ApiCallsSlice";
import {changeField} from "../../redux/slices/ElementChangesSlice";
import {useTranslation} from "react-i18next";
import {useLazyGetCustomerTypesQuery} from "../../redux/rtk/injectedUnitsApi";
import {checkPermission} from "../../functions/functionLibrary";
import {permissions} from "../../config/permissions";

type Props = {
    project: any,
    item: UnitItem,
    elementTypes: ElementTypeItem[],
    edit: boolean
}

export const UnitSubtype = (props: Props) => {
    const {t} = useTranslation(['common']);

    const apiCalls: any = useAppSelector((state: any) => state.apiCallsData.apiCalls);
    const dispatch = useAppDispatch();
    const [triggerGetCustomerTypes, resultCustomerTypes, lastPromiseCustomerTypes] = useLazyGetCustomerTypesQuery();

    const [item, setItem] = useState<UnitItem>(props.item);
    const [values, setValues] = useState<any>({});
    const [result, setResult] = useState<any[]>([]);

    useEffect(() => {
        setItem(props.item);
    }, [props.item]);

    useEffect(() => {
        if (item && item.fields) {
            console.log('triggerCalls', values, item);
            (async () => {
                const n = await getSubtype();

                Promise.all(n).then((result) => {
                    setResult(result);
                });
            })();
        }
    }, [item]);

    useEffect(() => {
        if (values) {
            setResult((prevState) => {
                let temp = [...prevState];

                for (let i = 0; i < temp.length; i++) {
                    let tempItem = temp[i];

                    tempItem.value = values[tempItem.name];
                }

                return temp;
            });
        }
    }, [values]);

    const getSubtype = async () => {
        const result: any[] = [];

        if (item) {
            const elementType = getElementType(item.element_type_id, props.elementTypes);
            if (elementType) {
                if (elementType.api_call_get_subtype) {
                    await addApiCall(elementType.api_call_get_subtype, 'element_subtype_id', item.element_subtype_id, result);
                }
            }
        }

        return result;
    };
    const addApiCall = async (apiCall: string, key: string, value: any, result: any[]) => {
        const type = 'dropdown';
        let dropdownOptions: any[] = [];
        if (apiCalls[apiCall]) {
            dropdownOptions = apiCalls[apiCall];
        } else {
            dropdownOptions = await triggerGetCustomerTypes({
                customerType: apiCall,
                projectId: props.project.id
            }).unwrap()
                .then((result: any) => {
                    const returnResult = result.map((item: any) => {
                        return {
                            value: key === 'name' ? item.name : item.id,
                            label: item.name,
                            icon: item.icon
                            // label: item.name
                        };
                    });

                    if (apiCall) {
                        if (!apiCalls[apiCall]) {
                            dispatch(addApiCalls({key: apiCall, value: returnResult}));
                        }
                    }

                    return returnResult;
                });
        }

        let temp = {
            dropdownOptions: dropdownOptions,
            id: item.id,
            value: values[key] !== undefined ? values[key] : value ? value : '',
            label: t(key),
            name: key,
            type: type
        }

        result.push(temp);
    };

    const onChangeField = (value: string, id: string, fieldName: string) => {
        dispatch(changeField({
            element_id: id,
            key: fieldName,
            value: value
        }));

        setValues((prevState: any) => {
            const temp = JSON.parse(JSON.stringify(prevState));
            return {...temp, [fieldName]: value};
        });
    };

    return <div> {result && result.map((itemResult: any) => {
        return <Input edit={checkPermission(permissions.editElements) && props.edit} name={itemResult.name}
                      inputClassName={'h-full'}
                      value={itemResult.value}
                      label={itemResult.label} type={itemResult.type}
                      dropdownOptions={itemResult.dropdownOptions}
                      options={{
                          itemTemplate: (item: any) => {
                              if (item) {
                                  if (item.icon) {
                                      return <div><i className={'mr-2 ' + item.icon}/>{item.label}</div>;
                                  } else {
                                      return item.label;
                                  }
                              }
                              return '';
                          },
                          valueTemplate: (item: any) => {
                              if (item) {
                                  if (item.icon) {
                                      return <div><i className={'mr-2 ' + item.icon}/>{item.label}</div>;
                                  } else {
                                      return item.label;
                                  }
                              }
                              return 0;
                          }
                      }}
                      onChange={(e) => {
                          if (itemResult) {
                              onChangeField(e.target.value || '', item.id, itemResult.name);
                          }
                      }}/>

    })}</div>
}
