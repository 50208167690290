import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {faCirclePlus, faListCheck, faPen, faTimes, faLanguage} from '@fortawesome/free-solid-svg-icons';
import {
    useGetProjectTypesQuery,
    useAddElementFieldTypeMutation,
    useUpdateElementFieldTypeMutation,
    useLazyGetElementTypesGlobalQuery,
    useLazyGetAllElementFieldTypesDataQuery,
    useGetAllElementTypesWithoutFieldsQuery
} from '../../redux/rtk/injectedProjectTypes';
import Input from '../global/Input';
import ProjectTypeSelectList from './ProjectTypeSelectList';
import CustomDataTable from '../global/CustomDataTable';
import {
    Project_ElementType,
    getProjectElementTypesColumns,
    getProjectElementFieldTypesColumns,
    Project_ElementFieldType,
    getElementFieldTypesFilters, Project_CustomerType, getProjectCustomertypesColumns, getCustomerTypesFilters
} from '../functions/TypeManagement';
import CustomDialog from '../global/CustomDialog';
import {showMessageOnSuccess} from '../global/CustomToast';
import {Tooltip} from "primereact/tooltip";
import ProjectElementTypeSelectList from "./ProjectElementTypeSelectList";
import ProjectElementFieldTypeData from "./ProjectElementFieldTypeData";
import ProjectElementFieldTypeObject from "./ProjectElementFieldTypeObject";
import ProjectElementFieldSubtype from "./ProjectElementFieldSubtype";
import {getUserFromLocalStorage} from "../functions/Global";
import {
    useCreateCustomerTypeMutation, useDeleteCustomerTypeMutation,
    useGetAllCustomerTypesQuery, useLazyGetAllCustomerTypesQuery, useLazyGetSingleTypeNamesQuery,
    useUpdateCustomerTypeMutation
} from "../../redux/rtk/injectedCustomerTypesApi";

type Props = {
    showEdit: boolean,
    setShowEdit: (value: boolean) => void
}

export const CustomerTypeEditForm_New = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    let user = getUserFromLocalStorage();

    const navigate = useNavigate();

    const [selectedItem, setSelectedItem] = useState<Project_CustomerType | undefined>(undefined);
    const [showDialog, setShowDialog] = useState<boolean>(props.showEdit);

    const [getCustomerTypes, customerTypesResult] = useLazyGetAllCustomerTypesQuery();
    const [getCustomerTypeNames, customerTypeNamesResult] = useLazyGetSingleTypeNamesQuery();

    const [addCustomerType] = useCreateCustomerTypeMutation();
    const [updateCustomerType] = useUpdateCustomerTypeMutation();
    const [deleteCustomerType] = useDeleteCustomerTypeMutation();

    const defaultValues: Project_CustomerType = {
        id: null,
        name: '',
        type_name: ''
    };

    const {control, formState: {errors}, handleSubmit, setValue, getValues, watch, reset} = useForm({defaultValues});

    useEffect(() => {
        getCustomerTypes({});
        getCustomerTypeNames({});
    }, []);

    useEffect(() => {
        setShowDialog(props.showEdit);
    }, [props.showEdit]);


    const getFormErrorMessage = (name: string) => {
        if (name.indexOf('.') >= 0) {
            const split = name.split('.');

            if (split.length === 3) {
                // @ts-ignore
                if (errors[split[0]] && errors[split[0]][split[1]] && errors[split[0]][split[1]][split[2]]) {
                    // @ts-ignore
                    const text = errors[split[0]][split[1]][split[2]].message;

                    return <small className="p-error custom">{text}</small>;
                }
            }
        } else {
            // @ts-ignore
            if (errors[name]) {
                // @ts-ignore
                const text = errors[name].message;

                return <small className="p-error custom">{text}</small>;
            }
        }
        return undefined;
    };

    const onEditClick = useCallback((data: Project_CustomerType) => {
        setSelectedItem(data);
        reset(data);
        setShowDialog(true);
    }, []);

    const onDeleteClick = useCallback((dataRow) => {
        CustomConfirmDialog({
            header: t('dialogs_removeCustomerTypeHeader'),
            message: t('dialogs_removeCustomerTypeMessage'),
            translation: t,
            onConfirm: () => {
                deleteCustomerType({id: dataRow['id'], name: dataRow['name']});
            }
        });
    }, []);

    const hideDialog = () => {
        setShowDialog(false);
        setTimeout(() => {
            setSelectedItem(undefined);
            reset(defaultValues);
            props.setShowEdit(false);
        }, 200);
    };

    const onSave = (data: Project_CustomerType) => {
        if (selectedItem) {
            updateCustomerType(data).then((res: any) => {
                if (!res.error) {
                    showMessageOnSuccess(t('success'), t('saved'));
                    getCustomerTypes({});
                    getCustomerTypeNames({});
                    hideDialog();
                }
            });
        } else {
            addCustomerType(data).then((res: any) => {
                if (!res.error) {
                    showMessageOnSuccess(t('success'), t('saved'));
                    getCustomerTypes({});
                    getCustomerTypeNames({});
                    hideDialog();
                }
            });
        }
    };

    return (
        <>
            <CustomDataTable id={'project_customer_types'} headerTitle={t('header_costumerTypes')}
                             columns={getProjectCustomertypesColumns(t)}
                             filters={getCustomerTypesFilters()}
                             editable={true}
                             sortable={true}
                             paginator={true}
                             value={customerTypesResult.data} dataloaded={customerTypesResult.data == null}
                             ableToUpdate={true}
                             onEditClick={onEditClick}
                             ableToDelete={true}
                             onRemoveClick={onDeleteClick}/>
            <CustomDialog
                header={selectedItem ?
                    <div className='flex'><i className='pi pi-pencil my-auto mr-2'/>{t('customer_types_edit')}
                    </div> : <div className='flex'><i className='pi pi-plus my-auto mr-2'/>{t('customer_types_new')}
                    </div>}
                visible={showDialog}
                onCancel={hideDialog} formName={'customerTypeForm_New'} style={{maxWidth: '800px'}}>
                <form id='customerTypeForm_New' onSubmit={handleSubmit(onSave)}>
                    <div className="card">
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <Input
                                    edit={true}
                                    label={t('type_name')}
                                    name={`type_name`}
                                    disabled={selectedItem != null}
                                    type={'dropdown'}
                                    dropdownOptions={customerTypeNamesResult.data}
                                    options={{optionLabel: 'type_name', optionValue: 'type_name', editable: true}}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('input_required')}}

                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <Input
                                    edit={true}
                                    label={t('name')}
                                    name={`name`}
                                    type={'text'}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('input_required')}}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </>
    );
};
