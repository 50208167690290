import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    useGetInspectionFieldsQuery,
    useGetInspectionTypeQuery,
    useLazyGetInspectionTaskTypeQuery,
} from '../../redux/rtk/injectedInspectionApi';
import {
    useGetElementTreeQuery,
    useGetElementTypesQuery,
    useLazyGetCustomerTypesQuery
} from '../../redux/rtk/injectedUnitsApi';
import {useForm} from "react-hook-form";
import {Inspection} from "../functions/Inspection";
import {getUserFromLocalStorage} from "../functions/Global";
import {useGetUsersQuery} from "../../redux/rtk/injectedGlobal";
import {useCreateInspectionMutation, useUpdateInspectionMutation} from "../../redux/rtk/injectedInspectionApi";
import {TabPanel, TabView} from "primereact/tabview";
import {InspectionEditFormWorkflow} from "./InspectionEditFormWorkflow";
import {CustomConfirmDialog} from "../global/CustomConfirmDialog";

type Props = {
    project: any,
    inspection?: Inspection,
    onSaved: () => void
}

export const InspectionEditForm = (props: Props) => {
    const {t} = useTranslation(['common']);

    const user = getUserFromLocalStorage();

    const [workflows, setWorkflows] = useState<any[]>([]);
    const [fields, setFields] = useState<any[]>([]);
    const [possibleParentElementIds, setPossibleParentElementIds] = useState<number[]>([]);
    const [inspectableElements, setInspectableElements] = useState<any[]>([]);
    const [inspectionTypeList, setInspectionTypeList] = useState<any[]>([]);

    const usersQuery = useGetUsersQuery({projectId: props.project.id, roleId: 18});
    const elementTreeQuery = useGetElementTreeQuery({projectId: props.project.id});
    const elementTypes = useGetElementTypesQuery({projectId: props.project.id});
    const inspectionTypes = useGetInspectionTypeQuery({projectId: props.project.id});
    const inspectionFields = useGetInspectionFieldsQuery({
        projectId: props.project.id,
        inspectionId: props.inspection?.id,
        locale: user ? user.language_id : 'de'
    });

    const [createInspection, resultCreateInspection] = useCreateInspectionMutation();
    const [updateInspection, resultUpdateInspection] = useUpdateInspectionMutation();

    const defaultValues: Inspection = {
        aanr_id: props.inspection ? props.inspection.aanr_id : "",
        auf_tuev: props.inspection ? props.inspection.auf_tuev : 0,
        created_by: props.inspection ? props.inspection.created_by : "",
        dispo_timestamp: props.inspection ? props.inspection.dispo_timestamp : 0,
        dispo_user: props.inspection ? props.inspection.dispo_user : undefined,
        einzelpruefungnachweis_timestamp: props.inspection ? props.inspection.einzelpruefungnachweis_timestamp : 0,
        einzelpruefungnachweis_user: props.inspection ? props.inspection.einzelpruefungnachweis_user : undefined,
        element_id: props.inspection ? props.inspection.element_id : "",
        element_name: props.inspection ? props.inspection.aanr_id : "",
        elements: props.inspection ? props.inspection.elements : [],
        erfassung_timestamp: props.inspection ? props.inspection.erfassung_timestamp : 0,
        erfassung_user: props.inspection ? props.inspection.erfassung_user : undefined,
        expandableElement: props.inspection ? props.inspection.expandableElement : undefined,
        has_children: props.inspection ? props.inspection.has_children : false,
        id: props.inspection ? props.inspection.id : "",
        inspection_element_id: props.inspection ? props.inspection.inspection_element_id : "",
        inspection_file_id: props.inspection ? props.inspection.inspection_file_id : null,
        inspection_type_id: props.inspection ? props.inspection.inspection_type_id : "",
        inspection_type_name: props.inspection ? props.inspection.inspection_type_name : "",
        mat_id: props.inspection ? props.inspection.mat_id : 0,
        name: props.inspection ? props.inspection.name : "",
        project_id: props.inspection ? props.inspection.project_id : "",
        timestamp: props.inspection ? props.inspection.timestamp : null,
        vgn_tuev: props.inspection ? props.inspection.vgn_tuev : 0,
        zuordnung_timestamp: props.inspection ? props.inspection.zuordnung_timestamp : 0,
        zuordnung_user: props.inspection ? props.inspection.zuordnung_user : undefined,
        current_workflow_type_id: props.inspection ? props.inspection.current_workflow_type_id : 0,
    };

    const form = useForm({defaultValues});
    const currentWorkflow = form.watch('current_workflow_type_id');
    useEffect(() => {

        const tempFields = [
            {
                "id": 2,
                "name": "name",
                "count": 1,
                "api_call": "dropdown_edit_list_inspection_name",
                "data_type": "string",
                "check_value": null,
                "object_type": "dropdown_edit",
                "display_name": t('inspection_context'),
                "element_subtype_id": null,
                "validationRules": {required: true},
                "value": '',
                "workflow_id": 1
            }
        ];

        let workflowsTemp = [];
        if (inspectionFields.data) {
            for (let i = 0; i < inspectionFields.data.length; i++) {
                let item = inspectionFields.data[i];
                workflowsTemp.push({
                    workflow_id: item.workflow_id,
                    workflow_name: item.workflow_name,
                    workflow_permission_id: item.workflow_permission_id
                });
                for (let j = 0; j < item.inspection_fields.length; j++) {
                    tempFields.push(item.inspection_fields[j]);
                }
            }
        }

        setWorkflows(workflowsTemp);
        setFields(tempFields);
    }, [props.inspection, inspectionFields.data]);

    useEffect(() => {
        if (elementTreeQuery.data) {
            let result: any[] = [];

            for (let i = 0; i < elementTreeQuery.data.length; i++) {
                fillParentList(result, elementTreeQuery.data[i]);
            }

            setInspectableElements(result);
        }
    }, [elementTreeQuery.data, possibleParentElementIds]);

    useEffect(() => {
        setInspectionTypeList([]);
        if (inspectionTypes.data && elementTypes.data) {

            let result: any[] = [];
            for (let i = 0; i < inspectionTypes.data.length; i++) {
                let item = inspectionTypes.data[i];

                result.push({
                    ...item,
                    label: item.name
                })
            }

            setInspectionTypeList(result);
            setPossibleParentElementIds(elementTypes.data.filter((item: any) => item.has_inspection).map((item: any) => item.element_type_id))
        }
    }, [inspectionTypes.data, elementTypes.data]);

    useEffect(() => {
        if (resultCreateInspection.status === 'fulfilled') {
            props.onSaved();
        }
    }, [resultCreateInspection]);

    useEffect(() => {
        if (resultUpdateInspection.status === 'fulfilled') {
            props.onSaved();
        }
    }, [resultUpdateInspection]);

    const dropDownOptionsInspectionBy = useMemo(() => {
        if (usersQuery.data) {
            return usersQuery.data.map((item: any) => {
                return {
                    label: item.first_name + ' ' + item.last_name,
                    value: item.id
                };
            });
        }
        return [];
    }, [usersQuery.data]);


    const fillParentList = (result: any[], item: any) => {
        if (possibleParentElementIds.includes(item.element_type_id)) {
            result.push({
                id: item.id,
                name: item.name,
                element_type_id: item.element_type_id,
                element_subtype_id: item.element_subtype_id,
                icon: item.icon,
                children: item.children || [],
                fields: [{}]
            });
        }

        if (item.children) {
            for (let i = 0; i < item.children.length; i++) {
                let child = item.children[i];

                fillParentList(result, child);
            }
        }
    };

    const onSubmit = (data: any) => {
        // console.table(elementChanges);
        // if (elementChanges.length) {
        //     if (!elementChanges[0].inspection_type_id) {
        //         showMessageOnWarn(t('error'), t('inspection_no_inspection_type_selected'), 2000);
        //     } else {
        //         if (elementChanges[0].inspection_id) {
        //             updateInspection(elementChanges[0]);
        //         } else {
        //             createInspection(elementChanges[0]);
        //         }
        //     }
        // }
        CustomConfirmDialog({
            header: t('dialogs_saveInspectionHeader'),
            message: t('dialogs_saveInspectionMessage'),
            translation: t,
            onConfirm: () => {
                let tempFields: any = {};

                for (let i = 0; i < Object.keys(data).length; i++) {
                    let key = Object.keys(data)[i];
                    if (!Object.keys(defaultValues).includes(key)) {
                        tempFields[key] = data[key];
                    }
                }

                if (props.inspection) {
                    updateInspection({...data, fields: tempFields});
                } else {
                    data.project_id = props.project.id;
                    createInspection({...data, fields: tempFields});
                }
            },
            onCancel: () => {
                return false
            }
        });
    };

    return (
        <div className={'card mb-0'}>
            <form id='inspection_edit_form' onSubmit={form.handleSubmit(onSubmit)}>
                {workflows && <TabView renderActiveOnly={false} activeIndex={currentWorkflow}>
                    {workflows.map((item: any) => {
                        return <TabPanel header={t(item.workflow_name)}
                                         disabled={item.workflow_id > currentWorkflow + 1}>
                            <div className={'pt-4'}>
                                <InspectionEditFormWorkflow project={props.project}
                                                            editable={currentWorkflow + 1 === item.workflow_id}
                                                            inspection={props.inspection}
                                                            form={form}
                                                            workflow={item}
                                                            fields={fields.filter((x: any) => {
                                                                return x.workflow_id === item.workflow_id;
                                                            })}
                                                            inspectableElements={inspectableElements}
                                                            inspectionTypeList={inspectionTypeList}
                                                            elementTypes={elementTypes.data}/>
                            </div>
                        </TabPanel>
                    })}
                    {/*{(checkPermission(permissions.isAdmin) || checkPermission(permissions.isExpert)) &&*/}
                    {/*    <TabPanel header={t('inspection_order_headline_1')}>*/}
                    {/*        <div className={'pt-4'}>*/}
                    {/*            <InspectionEditForm1 project={props.project} inspection={props.inspection}/>*/}
                    {/*        </div>*/}
                    {/*    </TabPanel>}*/}
                    {/*{(checkPermission(permissions.isAdmin) || checkPermission(permissions.isManager)) &&*/}
                    {/*    <TabPanel header={t('inspection_order_headline_2')}>*/}
                    {/*        <div className={'pt-4'}>*/}
                    {/*            <InspectionEditForm2 project={props.project} inspection={props.inspection}/>*/}
                    {/*        </div>*/}
                    {/*    </TabPanel>}*/}
                    {/*{(checkPermission(permissions.isAdmin) || checkPermission(permissions.isManager)) &&*/}
                    {/*    <TabPanel header={t('inspection_order_headline_3')}>*/}
                    {/*        <div className={'pt-4'}>*/}
                    {/*            <InspectionEditForm3 project={props.project} inspection={props.inspection}/>*/}
                    {/*        </div>*/}
                    {/*    </TabPanel>}*/}
                </TabView>}
            </form>
        </div>
    );
};
