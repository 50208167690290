import React from 'react';

type Props = {
    path: any[],
    onClick: (id: string, scrollIntoView: boolean) => void
}

export const UnitExplorerPath = (props: Props) => {
    return <div className={'flex h-full overflow-auto white-space-nowrap'}>
        <div className={'my-auto mx-2'}><i className={'pi pi-home'} style={{cursor: 'pointer'}} onClick={() => {
            props.onClick('', false);
            const hash = window.location.hash.split('_');

            if (hash.length) {
                window.location.hash = hash[0];
            }
        }}/>{props.path && props.path.length ? ' /' : ''}
        </div>
        {props.path && props.path.map((item: any, index: number) => {
            let result = [];
            if (index > 0) {
                result.push(<div className={'mx-2'}>/</div>);
            }

            result.push(<div>{item.name}</div>);
            return <div className={'ue_path-entry flex my-auto'} onClick={() => {
                props.onClick(item.id, true)
            }}>{result}</div>;
        })}
    </div>
}
