import React, {useState, useEffect} from 'react';
import {useLazyGetElementSubtypesQuery} from '../../redux/rtk/injectedProjectTypes';
import Input from '../global/Input';

type Props = {
    projectElementTypeId: string;
    name: string,
    label: string;
    value?: string;
    setValue?: (e: any) => void;
    validationControl?: any;
    validationErrorMessage?: (name: string) => void;
    validationRules?: any;
    disabled?: boolean;
    locale?: string;
}

const ProjectElementFieldSubtype = (props: Props): JSX.Element => {
    const [list, setList] = useState<{ value: string, label: string }[]>([]);
    const [trigger, {data, isLoading, isError}] = useLazyGetElementSubtypesQuery();

    useEffect(() => {
        if (props.projectElementTypeId) {
            trigger({locale: props.locale, element_type_id: props.projectElementTypeId});
        }
    }, [props.projectElementTypeId, props.locale, trigger]);

    useEffect(() => {
        if (data) {
            setList(data.map((item: any) => ({
                value: item.id,
                label: item.name,
            })));
        }
    }, [data]);


    return (
        <Input
            name={props.name}
            edit={true}
            disabled={props.disabled}
            label={props.label}
            value={props.value}
            dropdownOptions={list}
            scrollHeight={'350px'}
            type={'dropdown'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
            selectionMode={'checkbox'}
        />
    );
};

export default ProjectElementFieldSubtype;
