/*
 * Roles.tsx
 * Author: lnappenfeld
 * Date: 16.02.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect} from 'react';
import {Role} from '../functions/UserManagement';
import {RoleOverview} from './RoleOverview';


type Nullable<T> = T | null;

type Props = {
    project: any,
    mayEditUM: boolean,
    selectedRole: Nullable<Role>,
    setSelectedRole: (role: Nullable<Role>) => void,
    reloadRoles: boolean,
    setReloadRoles: (e: boolean) => void,
    showRoleDialog: boolean,
    setShowRoleDialog: (e: boolean) => void,
}

const Roles = (props: Props): JSX.Element => {

    useEffect(() => {
        props.setReloadRoles(true);
    }, []);

    return (
        <div className='h-full'>
            <div style={{height: '94%'}}>
                {props.project &&
                    <RoleOverview
                        projectId={props.project.id}
                        reload={props.reloadRoles}
                        setReload={props.setReloadRoles}
                        selectedRole={props.selectedRole}
                        setSelectedRole={props.setSelectedRole}
                        setShowRoleDialog={props.setShowRoleDialog}
                        ableToWriteRole={props.mayEditUM}
                    />
                }
            </div>
        </div>
    );
};

export default Roles;
