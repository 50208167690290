import React, {useState, useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import { Tooltip } from 'primereact/tooltip';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faListCheck, faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLazyGetAllElementTypesQuery, useLazyGetProjectTypesWithElementTypesQuery } from '../../redux/rtk/injectedProjectTypes';
import { useLazyGetInspectionElementTypesQuery, useLazyGetAllInspectionTypesQuery } from '../../redux/rtk/injectedInspectionApi';
import { getUserFromLocalStorage } from '../functions/Global';
import Input from '../global/Input';
import ProjectTypeSelectList from './ProjectTypeSelectList';
import ProjectElementTypeSelectList from './ProjectElementTypeSelectList';

type Props = {
    project: any,
    setCardsData: (data: any[]) => void;
    form: any;
    setLoadedInspectionTypes: (loadedInspectionTypes:any[]) => void;
    triggerLoadInspectionTypes: boolean;
    clear: boolean;
    onClear: () => void;
    setProjectsLoaded: (loaded: boolean) => void;
    onCloseAllCards?: () => void;
    setSelectedProjectTypeId: (id: string) => void;
    setSelectedElementTypeId: (id: string) => void;
}

const InspectionTypeEditForm = (props: Props): JSX.Element => {
    const { t } = useTranslation(['common']);
    const navigate = useNavigate();
    const op = useRef<any>(null);
    const op_manualCard = useRef<any>(null);
    const user = getUserFromLocalStorage();

    const [cards, setCards] = useState<number[]>([]);
    const [cardStates, setCardStates] = useState<{ id: number, projectTypeId: string, projectElementTypeId: string, inspectionTypeName: string, cycleInMonth: string, isManual?: boolean, disabled: boolean }[]>([]);
    // const [nextId, setNextId] = useState(0);
    const [projectsLoaded, setProjectsLoaded] = useState<boolean>(false);
    const [getInspectionTypes, resultInspectionTypes] = useLazyGetAllInspectionTypesQuery();
    const [getElementTypes, { data: elementTypes }] = useLazyGetAllElementTypesQuery();
    const [getInspectionElementTypes, { data: inspectionElementTypes }] = useLazyGetInspectionElementTypesQuery();
    const [getProjectTypesWithElementTypes, { data: projectTypesWithElementTypes }] = useLazyGetProjectTypesWithElementTypesQuery();
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedProjectTypeId, setSelectedProjectTypeId] = useState<any>();
    const [selectedElementTypeId, setSelectedElementTypeId] = useState<any>();

    const {control, formState: {errors}, handleSubmit, setValue, getValues, watch} = props.form;

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control: control,
        name: 'items',
    });

    const getFormErrorMessage = (name: string) => {
        if (name.indexOf('.') >= 0) {
            const split = name.split('.');

            if (split.length === 3) {
                // @ts-ignore
                if (errors[split[0]] && errors[split[0]][split[1]] && errors[split[0]][split[1]][split[2]]) {
                    // @ts-ignore
                    const text = errors[split[0]][split[1]][split[2]].message;

                    return <small className="p-error custom">{text}</small>;
                }
            }
        } else {
            // @ts-ignore
            if (errors[name]) {
                // @ts-ignore
                const text = errors[name].message;

                return <small className="p-error custom">{text}</small>;
            }
        }
        return undefined;
    };

    /* Lade nur Projekttypen mit vorhandenen Elementtypen */
    useEffect(() => {
        getProjectTypesWithElementTypes();
    }, [getProjectTypesWithElementTypes]);

    /* Lade Projekttypen mit dazugehörigen Elementtypen (Projekttyp + Elementtyp + Icon) */
    useEffect(() => {
        getInspectionElementTypes();
    }, [getInspectionElementTypes]);

    useEffect(() => {
        if (inspectionElementTypes) {
            const tmp = inspectionElementTypes.map((item: any) => ({
                value: item.element_type_id,
                projectTypeId: item.project_type_id,
                projectTypeName: item.project_type_name,
                elementTypeName: item.element_type_name,
                elementTypeIcon: item.element_type_icon,
            }));
            setList(tmp);
        }
    }, [inspectionElementTypes]);

    /* Lade basierend auf element_type_id, alle Elementfeldtyp-Daten  */
    useEffect(() => {
        if (resultInspectionTypes) {
            // console.log('inspectionTypesData', );
        }
    }, [resultInspectionTypes]);

    useEffect(() => {
        remove();
        setCards([]);
        setCardStates([]);

        if (resultInspectionTypes.data && Array.isArray(resultInspectionTypes.data)) {
            const loadedInspectionTypes = resultInspectionTypes.data.map((key: any) => {
                // console.log(result);

                return {
                    id: key.id,
                    projectTypeId: key.project_type_id,
                    projectElementTypeId: key.element_type_id,
                    inspectionTypeName: key.name,
                    cycleInMonth: key.cycle_in_month,
                    isManual: false,
                };});

            const cardsTemp:any[] = [];
            const cardStatesTemp:any[] = [];

            loadedInspectionTypes.forEach((type:any) => {
                append({
                    id: type.id,
                    projectTypeId: type.projectTypeId,
                    projectElementTypeId: type.projectElementTypeId,
                    inspectionTypeName: type.inspectionTypeName,
                    cycleInMonth: type.cycleInMonth,
                    isManual: false,
                });

                cardsTemp.push(type.id);
                cardStatesTemp.push({
                    id: type.id,
                    projectTypeId: type.projectTypeId,
                    projectElementTypeId: type.projectElementTypeId,
                    inspectionTypeName: type.inspectionTypeName,
                    cycleInMonth: type.cycleInMonth,
                    disabled: true});
                props.setLoadedInspectionTypes(loadedInspectionTypes);
            });
            setCards(cardsTemp);
            setCardStates(cardStatesTemp);
            // setSelectedElementTypeId(elementTypeId);
        } else {
            // console.error('No element types data available or data is not an array.');
        }
        setIsLoading(false);
    }, [resultInspectionTypes]);

    useEffect(() => {
        if (resultInspectionTypes.data && resultInspectionTypes.data.length) {
        }
    }, [resultInspectionTypes]);

    const loadInspectionTypes = async (elementTypeId: string) => {
        setIsLoading(true);

        getInspectionTypes({ elementTypeId });
    };

    const loadExistingElementFieldTypes = async (elementTypeId: string, projectTypeId: string) => {
        loadInspectionTypes(elementTypeId);

        setSelectedElementTypeId(elementTypeId);
        props.setSelectedElementTypeId(elementTypeId);

        setSelectedProjectTypeId(projectTypeId);
        props.setSelectedProjectTypeId(projectTypeId);

        setProjectsLoaded(true);
        if (op.current) {
            op.current.hide();
        }
    };

    useEffect(() => {
        if (projectsLoaded) {
            loadInspectionTypes(selectedElementTypeId);
        }
    }, [props.triggerLoadInspectionTypes]);

    useEffect(()=> {
        if (props.clear) {
            setCards([]);
            setCardStates([]);
            setSelectedProjectTypeId(null);
            setSelectedElementTypeId(null);
            if (props.onCloseAllCards) {
                props.onCloseAllCards();
            }
        }
    },
    [props.clear]);

    useEffect(()=> {
        props.onClear();
    },
    [cards]);

    const loadOverlayPanel = (e:any) => {
        const handleElement=document.getElementById('overlay_panel');
        // @ts-ignore
        op.current.show({currentTarget:handleElement});
    };

    /* Projekttypen im OverlayPanel */
    const renderProjectTypes = () => {
        return list.map((type: any, index: number) => (
            <div
                key={type.value}
                className="flex flex-column overflow-auto"
                onClick={() => loadExistingElementFieldTypes(type.value, type.projectTypeId)}
            >
                <div className="flex align-items-center cursor-pointer">
                    <i className={type.elementTypeIcon} style={{ marginRight: '8px', alignItems: 'center' }}></i>
                    <span className="font-medium ml-1 mr-1">{type.projectTypeName} - </span>
                    <span>{type.elementTypeName}</span>
                </div>
                {index < list.length - 1 && <hr className="m-1" />}
            </div>
        ));
    };

    const loadOverlayPanelManualCard = (e:any) => {
        const handleElement=document.getElementById('overlay_panel_manualCard');
        // @ts-ignore
        op_manualCard.current.show({currentTarget:handleElement});
    };

    /* Projekttypen für manuelle Karte im OverlayPanel */
    const renderBothTypesManualCard = () => {
        return list.map((type: any, index: number) => (
            <div
                key={type.value}
                className="flex flex-column overflow-auto"
                onClick={() => loadManualCardWithBothTypes(type.value, type.projectTypeId)}>
                <div className="flex align-items-center cursor-pointer">
                    <i className={type.elementTypeIcon} style={{ marginRight: '8px', alignItems: 'center' }}></i>
                    <span className="font-medium ml-1 mr-1">{type.projectTypeName} - </span>
                    <span>{type.elementTypeName}</span>
                </div>
                {index < list.length - 1 && <hr className="m-1" />}
            </div>
        ));
    };

    const loadManualCardWithBothTypes = async (elementTypeId: string, projectTypeId: string) => {
        addNewManualCard(elementTypeId, projectTypeId);

        setSelectedElementTypeId(elementTypeId);
        props.setSelectedElementTypeId(elementTypeId);

        setSelectedProjectTypeId(projectTypeId);
        props.setSelectedProjectTypeId(projectTypeId);

        setProjectsLoaded(true);

        if (op_manualCard.current) {
            op_manualCard.current.hide();
        }
    };

    const _addNewManualCard = () => {
        addNewManualCard(null, null);
    };

    const addNewManualCard = (elementTypeId: string | null, projectTypeId: string | null) => {
        const newId = cards.length > 0 ? Math.max(...cards) + 1 : 0;
        const elementTypeId_ = elementTypeId || selectedElementTypeId;
        const projectTypeId_ = projectTypeId || selectedProjectTypeId;

        // console.log(cards);

        setCards([...cards, newId]);
        setCardStates([...cardStates, {
            id: newId,
            projectTypeId: projectTypeId_,
            projectElementTypeId: elementTypeId_,
            inspectionTypeName: '',
            cycleInMonth: '',
            isManual: true,
            disabled: false
        }]);

        append({
            id: newId,
            projectTypeId: projectTypeId_,
            projectElementTypeId: elementTypeId_,
            inspectionTypeName: '',
            cycleInMonth: '',
        });
        // console.log('projectTypeId:' + projectTypeId_, 'elementTypeId: ' + elementTypeId_);
        // console.log(`Card added with ID: ${newId}`);
    };

    const removeManualCard = (id: number) => {
        const index = cards.findIndex(cardId => cardId === id);
        setCards(cards.filter(cardId => cardId !== id));
        setCardStates(cardStates.filter(cardState => cardState.id !== id));
        if (index >= 0) {
            remove(index);
        }
        // console.log(`Card removed with ID: ${id}`);
    };

    const handleProjectTypeChange = (id: number, newProjectTypeId: string) => {
        setCardStates(prevStates =>
            prevStates.map(cardState =>
                cardState.id === id
                    ? {...cardState, projectTypeId: newProjectTypeId}
                    : cardState
            )
        );
    };

    const handleElementTypeChange = (id: number, newProjectElementTypeId: string) => {
        setSelectedElementTypeId(newProjectElementTypeId);
        setCardStates(prevStates =>
            prevStates.map(cardState =>
                cardState.id === id
                    ? { ...cardState, projectElementTypeId: newProjectElementTypeId }
                    : cardState
            )
        );
    };

    const handleInspectionTypeNameChange = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setCardStates(prevStates =>
            prevStates.map(cardState =>
                cardState.id === id
                    ? { ...cardState, inspectionTypeName: newValue }
                    : cardState
            )
        );
    };

    const handleFieldChange = (id: number, field: string, value: string) => {
        setCardStates(prevStates =>
            prevStates.map(cardState =>
                cardState.id === id
                    ? { ...cardState, [field]: value }
                    : cardState
            )
        );
    };

    const enableCardEditing = (id: number) => {
        setCardStates(prevStates =>
            prevStates.map(cardState =>
                cardState.id === id
                    ? { ...cardState, disabled: !cardState.disabled }
                    : cardState
            )
        );
        // console.log(`Card with ID: ${id} is now editable`);
    };

    /*  useEffect(() => {
        console.log(cards);
    }, [cards]); */

    useEffect(() => {
        props.setCardsData(cardStates);
        // console.log(cardStates);
    }, [cardStates]);

    const renderCard = (cardState: any, index: number) => {
        // const cardState = cardStates.find(card => card.id === id);
        const id = cardState.id;
        return (
            <div key={id} className="card"
                 style={{flex: '1 1 45%', display: 'flex', flexDirection: 'column', position: 'relative'}}>
                {cardState?.isManual ? (
                    <>
                        {/* Icon für manuelle "Prüfart entfernen" */}
                        <Tooltip target=".timesIcon" mouseTrack mouseTrackLeft={10}/>
                        <FontAwesomeIcon className="timesIcon"
                                         icon={faTimes}
                                         data-pr-tooltip={t('removeInspectionType')}
                                         style={{
                                             position: 'absolute',
                                             top: '0.5rem',
                                             right: '0.5rem',
                                             cursor: 'pointer',
                                             fontSize: '1.2rem',
                                             color: '#FC6161'
                                         }}
                                         onClick={() => removeManualCard(id)}
                        />
                    </>
                ) : (
                    <>
                        {/* Icon für geladene "Prüfart bearbeiten" */}
                        <Tooltip target=".checkIcon" mouseTrack mouseTrackLeft={10}/>
                        <FontAwesomeIcon className="checkIcon"
                                         icon={faPen}
                                         data-pr-tooltip={t('editInspectionType')}
                                         style={{
                                             position: 'absolute',
                                             top: '0.5rem',
                                             right: '0.5rem',
                                             cursor: 'pointer',
                                             fontSize: '1rem',
                                             color: cardState?.disabled ? '#FC6161' : 'green'
                                         }}
                                         onClick={() => enableCardEditing(id)}
                        />
                    </>
                )}
                <div className="text-2xl font-bold mb-3">{t('inspectionType')}</div>
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <ProjectTypeSelectList
                            value={cardState?.projectTypeId || selectedProjectTypeId}
                            setValue={(e) => handleProjectTypeChange(id, e)}
                            disabled={cardState?.disabled || !!selectedProjectTypeId}
                            label={t('projM_projectTypeName')}
                            name={`items.${index}.projectTypeId`}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('projM_projectTypeName') + ' ' + t('input_required')}}
                        />
                    </div>
                    <div className="col-12 md:col-6">
                        <ProjectElementTypeSelectList
                            value={cardState?.projectElementTypeId || selectedElementTypeId}
                            setValue={(e) => handleElementTypeChange(id, e)}
                            disabled={cardState?.disabled || !!selectedProjectTypeId}
                            locale={user.language_id}
                            projectTypeId={cardState?.projectTypeId || ''}
                            label={t('elementTypeName')}
                            name={`items.${index}.projectElementTypeId`}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('project_element_type_id') + ' ' + t('input_required')}}
                        />
                    </div>
                    <div className="col-12 md:col-auto">
                        <Input
                            value={cardState?.inspectionTypeName || ''}
                            onChange={(e) => handleInspectionTypeNameChange(id, e)}
                            disabled={cardState?.disabled}
                            edit={true}
                            label={t('inspectionTypeName')}
                            name={`items.${index}.inspectionTypeName`}
                            type={'text'}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('inspectionTypeName') + ' ' + t('input_required')}}
                        />
                    </div>
                    <div className="col-12 md:col-6">
                        <Input
                            value={cardState?.cycleInMonth}
                            onChange={(e) => handleFieldChange(id, 'cycleInMonth', e.target.value)}
                            disabled={cardState?.disabled}
                            edit={true}
                            label={t('cycle_in_month')}
                            name={`items.${index}.cycleInMonth`}
                            type={'text'}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('cycle_in_month') + ' ' + t('input_required')}}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const addCardStyles: React.CSSProperties = {
        flex: '1 1 45%',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '250px',
        minHeight: cards.length === 0 ? '100px' : 'auto',
    };

    const cardContainerStyles: React.CSSProperties = {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1rem',
        padding: '0 1rem',
        paddingTop: '7px',
        overflowY: 'auto',
        height: 'calc(100% - 50px)'
    };

    return (
        <>
            <form id="projectForm" style={{height: '100%'}}>
                <div className="grid" style={cardContainerStyles}>
                    {/* Vorhandene Karten rendern */}
                    {cardStates.map((card, index) => renderCard(card, index))}

                    {/* Karte mit dem Icon */}
                    <div className="card" style={addCardStyles}>
                        <div className="text-2xl font-bold mb-3">{t('inspectionType')}</div>
                        <div style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                        }}>
                            {/* Icon für "Prüfarten laden" */}
                            {cards.length === 0 && (

                                <div id={'overlay_panel'}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginRight: '1rem',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faListCheck}
                                        style={{ fontSize: 34, cursor: 'pointer' }}
                                        onClick={loadOverlayPanel}
                                    />
                                    {isLoading && <ProgressSpinner className="absolute left-0 right-0 top-0 bottom-0 m-auto z-1" />}
                                    <OverlayPanel ref={op} className="overflow-auto" style={{width: 'auto', minWidth: '150px', maxHeight: '20vh'}} >
                                        <div>
                                            {renderProjectTypes()}
                                        </div>
                                    </OverlayPanel>
                                    <span>{t('load_inspectionTypes')}</span>
                                </div>
                            )}

                            {/* Icon für "Prüfart hinzufügen" */}
                            {cards.length >= 1 && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <FontAwesomeIcon
                                        className="mx-2 mb-1"
                                        icon={faCirclePlus}
                                        style={{fontSize: 30, cursor: 'pointer'}}
                                        onClick={_addNewManualCard}
                                    />
                                    <span>{t('add_inspectionType')}</span>
                                </div>
                            )}

                            {/* Icon für "Prüfart hinzufügen" inkl. OverlayPanel */}
                            {cards.length === 0 && (
                                <div id={'overlay_panel_manualCard'}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <FontAwesomeIcon
                                        className="mx-2 mb-1"
                                        icon={faCirclePlus}
                                        style={{fontSize: 30, cursor: 'pointer'}}
                                        onClick={loadOverlayPanelManualCard}
                                    />
                                    <OverlayPanel ref={op_manualCard} className="overflow-auto" style={{width: 'auto', minWidth: '125px', maxHeight: '20vh'}} >
                                        <div>
                                            {renderBothTypesManualCard()}
                                        </div>
                                    </OverlayPanel>
                                    <span>{t('add_inspectionTypes')}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default InspectionTypeEditForm;
