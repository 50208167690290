import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { showMessageOnError, showMessageOnSuccess } from '../global/CustomToast';
import Input from '../global/Input';
import { useCreateTranslationMutation, useUpdateTranslationMutation, useTranslateTextMutation } from '../../redux/rtk/injectedTranslationApi';
import { Dropdown } from 'primereact/dropdown';
import '../../style/LanguageManagement.scss';
import { faArrowRightArrowLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Tooltip} from 'primereact/tooltip';

type Props = {
    onFinished: (success: boolean) => void;
    language: any;
};

type Language = {
    name: string;
    code: string;
};

export const LanguageForm = (props: Props): JSX.Element => {
    const { t } = useTranslation(['common', 'users', 'input', 'project']);

    const [createTranslation] = useCreateTranslationMutation();
    const [updateTranslation] = useUpdateTranslationMutation();
    const [translateText] = useTranslateTextMutation();

    const defaultValues = {
        key: props.language ? props.language.key : '',
        de: props.language ? props.language.de : '',
        en: props.language ? props.language.en : '',
        es: props.language ? props.language.es : ''
    };

    const { control, formState: { errors }, handleSubmit, watch, setValue, clearErrors } = useForm({ defaultValues });

    const languages: Language[] = [
        { name: t('langM_language_de'), code: 'DE' },
        { name: t('langM_language_en'), code: 'EN' },
        { name: t('langM_language_es'), code: 'ES' },
    ];

    const [selectedLanguageFrom, setSelectedLanguageFrom] = useState<Language>(languages[0]);
    const [selectedLanguageTo, setSelectedLanguageTo] = useState<Language>(languages[1]);

    const [availableLanguagesFrom, setAvailableLanguagesFrom] = useState<Language[]>(languages.filter(lang => lang.code !== selectedLanguageTo.code));
    const [availableLanguagesTo, setAvailableLanguagesTo] = useState<Language[]>(languages.filter(lang => lang.code !== selectedLanguageFrom.code));

    const sourceText = watch(selectedLanguageFrom.code.toLowerCase() as keyof typeof defaultValues);

    useEffect(() => {
        setAvailableLanguagesFrom(languages.filter(lang => lang.code !== selectedLanguageTo?.code));
        setAvailableLanguagesTo(languages.filter(lang => lang.code !== selectedLanguageFrom?.code));
    }, [selectedLanguageFrom, selectedLanguageTo]);

    useEffect(() => {
        let isMounted = true;

        const fetchTranslations = async () => {
            if (sourceText && !props.language) {
                const sourceLangForAPI = selectedLanguageFrom.code;

                try {
                    for (const lang of languages) {
                        if (lang.code !== sourceLangForAPI) {
                            const response = await translateText({
                                text: sourceText,
                                targetLang: lang.code,
                                sourceLang: sourceLangForAPI
                            }).unwrap();

                            if (isMounted) {
                                setValue(lang.code.toLowerCase() as keyof typeof defaultValues, response);
                            }
                        }
                    }
                } catch (error) {
                    console.error('Error fetching translations:', error);
                }
            }
        };

        fetchTranslations();

        return () => {
            isMounted = false;
        };
    }, [sourceText, selectedLanguageFrom, translateText, setValue, props.language]);

    useEffect(() => {
        const fetchTranslationForNewTargetLang = async () => {
            if (sourceText && !props.language) {
                try {
                    const response = await translateText({
                        text: sourceText,
                        targetLang: selectedLanguageTo.code,
                        sourceLang: selectedLanguageFrom.code
                    }).unwrap();

                    setValue(selectedLanguageTo.code.toLowerCase() as keyof typeof defaultValues, response);
                } catch (error) {
                    console.error('Error fetching translation for new target language:', error);
                }
            }
        };

        fetchTranslationForNewTargetLang();
    }, [selectedLanguageTo, sourceText, selectedLanguageFrom.code, setValue, translateText, props.language]);

    useEffect(() => {
        if (props.language) {
            setValue(selectedLanguageFrom.code.toLowerCase() as keyof typeof defaultValues, props.language[selectedLanguageFrom.code.toLowerCase()]);
            setValue(selectedLanguageTo.code.toLowerCase() as keyof typeof defaultValues, props.language[selectedLanguageTo.code.toLowerCase()]);
        }
    }, [selectedLanguageFrom, selectedLanguageTo, props.language, setValue]);

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const saveData = (data: any) => {
        const mutation = props.language ? updateTranslation(data) : createTranslation(data);
        mutation.then((result: any) => {
            if (result.error) {
                showMessageOnError(t('error'), result.error);
            } else {
                showMessageOnSuccess(t('success'), props.language ?
                    t('trans_updated') :
                    t('trans_created')
                );
                props.onFinished(true);
            }
        });
    };

    const selectedLanguageTemplate = (option: Language | null, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img
                        alt={option.name}
                        src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
                        className={`mr-2 flag flag-${option.code.toLowerCase()}`}
                        style={{ width: '18px' }}
                    />
                    <div>{option.name}</div>
                </div>
            );
        }
        return null;
    };

    const languageOptionTemplate = (option: Language) => (
        <div className="flex align-items-center">
            <img
                alt={option.name}
                src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
                className={`mr-2 flag flag-${option.code.toLowerCase()}`}
                style={{ width: '18px' }}
            />
            <div>{option.name}</div>
        </div>
    );

    const handleLanguageFromChange = (e: any) => {
        setSelectedLanguageFrom(e.value);
        setAvailableLanguagesTo(languages.filter(lang => lang.code !== e.value.code));
        if (!props.language) {
            setValue(e.value.code.toLowerCase() as keyof typeof defaultValues, '');
        }
        clearErrors();
    };

    const handleLanguageToChange = (e: any) => {
        setSelectedLanguageTo(e.value);
        setAvailableLanguagesFrom(languages.filter(lang => lang.code !== e.value.code));
        if (props.language) {
            setValue(e.value.code.toLowerCase() as keyof typeof defaultValues, props.language[e.value.code.toLowerCase()]);
        } else {
            const fetchTranslationForNewTargetLang = async () => {
                if (sourceText) {
                    try {
                        const response = await translateText({
                            text: sourceText,
                            targetLang: e.value.code,
                            sourceLang: selectedLanguageFrom.code
                        }).unwrap();

                        setValue(e.value.code.toLowerCase() as keyof typeof defaultValues, response);
                    } catch (error) {
                        console.error('Error fetching translation for new target language:', error);
                    }
                }
            };

            fetchTranslationForNewTargetLang();
        }
        clearErrors();
    };

    const handleSwitchLanguages = () => {
        const tempLanguage = selectedLanguageFrom;
        const tempValueFrom = watch(selectedLanguageFrom.code.toLowerCase() as keyof typeof defaultValues);
        const tempValueTo = watch(selectedLanguageTo.code.toLowerCase() as keyof typeof defaultValues);

        setSelectedLanguageFrom(selectedLanguageTo);
        setSelectedLanguageTo(tempLanguage);

        if (props.language) {
            setValue(tempLanguage.code.toLowerCase() as keyof typeof defaultValues, tempValueTo);
            setValue(selectedLanguageTo.code.toLowerCase() as keyof typeof defaultValues, tempValueFrom);
        } else {
            const fetchTranslationsForToLanguage = async () => {
                const response = await translateText({
                    text: tempValueFrom,
                    targetLang: selectedLanguageTo.code,
                    sourceLang: tempLanguage.code
                }).unwrap();

                setValue(selectedLanguageTo.code.toLowerCase() as keyof typeof defaultValues, response);
            };

            const fetchTranslationsForFromLanguage = async () => {
                const response = await translateText({
                    text: tempValueTo,
                    targetLang: selectedLanguageFrom.code,
                    sourceLang: tempLanguage.code
                }).unwrap();

                setValue(selectedLanguageFrom.code.toLowerCase() as keyof typeof defaultValues, response);
            };

            fetchTranslationsForToLanguage();
            fetchTranslationsForFromLanguage();
        }

        setAvailableLanguagesFrom(languages.filter(lang => lang.code !== selectedLanguageTo?.code));
        setAvailableLanguagesTo(languages.filter(lang => lang.code !== tempLanguage?.code));
        clearErrors();
    };

    const getRequiredLanguageKey = (langCode: string) => {
        switch (langCode) {
            case 'DE':
                return 'langM_requires_de';
            case 'EN':
                return 'langM_requires_en';
            case 'ES':
                return 'langM_requires_es';
            default:
                return 'langM_requires_any';
        }
    };

    return (
        <form id="languageForm" onSubmit={handleSubmit(saveData)}>
            <div className={'card'}>
                {!props.language && (
                    <div className="flex align-items-center mb-3">
                        <FontAwesomeIcon className='mx-2' icon={faInfoCircle} />
                        <span> {t('langM_createTranslationInfo')} </span>
                    </div>
                )}
                <Input
                    edit={true} label={t('langM_attribs_key')} name={'key'} type={'text'}
                    disabled={!!props.language}
                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                    validationRules={{ required: t('langM_attribs_key') + ' ' + t('input_required') }}
                />
                <div className="flex align-items-center justify-content-between">
                    <Dropdown
                        value={selectedLanguageFrom}
                        onChange={handleLanguageFromChange}
                        options={availableLanguagesFrom}
                        optionLabel="name"
                        filter
                        valueTemplate={selectedLanguageTemplate}
                        itemTemplate={languageOptionTemplate}
                        className="w-full md:w-5"
                    />
                    <div className="flex align-items-center justify-content-center mx-2" >
                        <Tooltip target=".switchLangIcon" position="top" mouseTrack mouseTrackTop={10} />
                        <FontAwesomeIcon className="switchLangIcon cursor-pointer" data-pr-tooltip={t('langM_switchLanguage')} icon={faArrowRightArrowLeft} onClick={handleSwitchLanguages} />
                    </div>
                    <Dropdown
                        value={selectedLanguageTo}
                        onChange={handleLanguageToChange}
                        options={availableLanguagesTo}
                        optionLabel="name"
                        filter
                        valueTemplate={selectedLanguageTemplate}
                        itemTemplate={languageOptionTemplate}
                        className="w-full md:w-5"
                    />
                </div>
                <div className="flex align-items-center justify-content-between">
                    <div className="w-full md:w-5">
                        <Input
                            className="mt-2"
                            edit={true}
                            label={t(`langM_language_${selectedLanguageFrom.code.toLowerCase()}`)}
                            name={selectedLanguageFrom.code.toLowerCase()}
                            type={'text'}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{
                                required: `${t(getRequiredLanguageKey(selectedLanguageFrom.code))} ${t('input_required')}`
                            }}
                        />
                    </div>
                    <div className="w-full md:w-5">
                        <Input
                            className="mt-2"
                            edit={true}
                            label={t(`langM_language_${selectedLanguageTo.code.toLowerCase()}`)}
                            name={selectedLanguageTo.code.toLowerCase()}
                            type={'text'}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{ required: false }}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};
