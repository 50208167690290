import React, { useState, useEffect } from 'react';
import Input from '../global/Input';
import { useLazyGetElementObjectTypesQuery } from '../../redux/rtk/injectedProjectTypes';

type Props = {
    name: string,
    label: string,
    value?: string,
    setValue?: (e: any) => void,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
    tooltip?: string,
}

const ProjectElementFieldTypeObject = (props: Props): JSX.Element => {
    const [list, setList] = useState<{ value: string, label: string }[]>([]);
    const [trigger, { data }] = useLazyGetElementObjectTypesQuery();

    useEffect(() => {
        trigger();
    }, [trigger]);

    useEffect(() => {
        if (data) {
            const formattedData = data.map((item: { object_type: string }) => ({
                value: item.object_type,
                label: item.object_type,
            }));
            setList(formattedData);
        }
    }, [data]);

    return (
        <Input
            name={props.name}
            edit={true}
            disabled={props.disabled}
            label={props.label}
            value={props.value}
            dropdownOptions={list}
            scrollHeight={'350px'}
            type={'dropdown'}
            tooltip={props.tooltip}
            onChange={(e: any) => {
                console.log('Selected value:', e.value);
                if (props.setValue) props.setValue(e.value);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
            selectionMode={'checkbox'}
        />
    );
};

export default ProjectElementFieldTypeObject;
