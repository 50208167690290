import {baseApi} from './baseApi';

export const injectedProjectTypes = baseApi.injectEndpoints({
    addTagTypes: ['ProjectTypes', 'Project'],
    endpoints: (builder) => ({
        getProjectTypes: builder.query({
            query: () => {
                const url = 'getProjectTypes';

                return {url: url};
            },
            providesTags: ['ProjectTypes'],
        }),
        getProjectTypesWithElementTypes: builder.query({
            query: () => {
                const url = 'getProjectTypesWithElementTypes';

                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }),
        getAllElementTypesData: builder.query({
            query: (args) => {
                const {projectTypeId} = args;

                let url = 'getAllElementTypesData';

                if (projectTypeId) {
                    url += '?projectTypeId=' + projectTypeId;
                }

                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }),
        getAllElementFieldTypesData: builder.query({
            query: (args) => {
                const {elementTypeId} = args;

                let url = `getAllElementFieldTypesData`;

                if (elementTypeId) {
                    url += '?elementTypeId=' + elementTypeId;
                }

                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }),
        getElementTypesGlobal: builder.query({
            query: (args) => {
                const {locale, projectTypeId} = args;

                let url = `getElementTypesGlobal?locale=${locale || 'de'}`;

                if (projectTypeId) {
                    url += '&projectTypeId=' + projectTypeId;
                }
                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }),
        getAllElementTypes: builder.query({
            query: () => {
                const url = 'getAllElementTypes';

                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }),
        getAllElementTypesWithoutFields: builder.query({
            query: () => {
                const url = 'getAllElementTypesWithoutFields';

                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }),
        getProjectStyleTypes: builder.query({
            query: () => {
                const url = 'getProjectStyleTypes';

                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }),
        getElementDataTypes: builder.query({
            query: () => {
                const url = 'getElementDataTypes';

                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }),
        getElementObjectTypes: builder.query({
            query: () => {
                const url = 'getElementObjectTypes';

                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }),
        getElementSubtypes: builder.query({
            query: (args) => {
                const {element_type_id} = args;

                const url = `getElementSubtypes?&element_type_id=${element_type_id}`;

                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }),
        addProjectType: builder.mutation({
            query: (unit) => ({
                url: 'addProjectType',
                method: 'POST',
                body: unit,
            }),
            invalidatesTags: ['ProjectTypes']
        }),
        updateProjectType: builder.mutation({
            query: ({id, name, style_type}) => ({
                url: 'updateProjectType',
                method: 'PUT',
                body: {id, name, style_type},
            }),
            invalidatesTags: ['ProjectTypes']
        }),
        addElementType: builder.mutation({
            query: (unit) => ({
                url: 'addElementType',
                method: 'POST',
                body: unit,
            }),
            invalidatesTags: ['ProjectTypes']
        }),
        updateElementType: builder.mutation({
            query: (body) => ({
                url: 'updateElementType',
                method: 'PUT',
                body: body,
            }),
            invalidatesTags: ['ProjectTypes']
        }),
        addElementFieldType: builder.mutation({
            query: (unit) => ({
                url: 'addElementFieldType',
                method: 'POST',
                body: unit,
            }),
            invalidatesTags: ['ProjectTypes']
        }),
        updateElementFieldType: builder.mutation({
            query: (body) => ({
                url: 'updateElementFieldType',
                method: 'PUT',
                body: body,
            }),
            invalidatesTags: ['ProjectTypes']
        }),
    }),
});

export const {
    useGetProjectTypesQuery,
    useLazyGetProjectTypesQuery,
    useLazyGetProjectTypesWithElementTypesQuery,
    useLazyGetAllElementTypesDataQuery,
    useLazyGetAllElementFieldTypesDataQuery,
    useGetElementTypesGlobalQuery,
    useLazyGetElementTypesGlobalQuery,
    useLazyGetAllElementTypesQuery,
    useGetAllElementTypesWithoutFieldsQuery,
    useLazyGetAllElementTypesWithoutFieldsQuery,
    useLazyGetProjectStyleTypesQuery,
    useLazyGetElementDataTypesQuery,
    useLazyGetElementObjectTypesQuery,
    useLazyGetElementSubtypesQuery,
    useAddProjectTypeMutation,
    useUpdateProjectTypeMutation,
    useAddElementTypeMutation,
    useUpdateElementTypeMutation,
    useAddElementFieldTypeMutation,
    useUpdateElementFieldTypeMutation,
} = injectedProjectTypes;
